define("due-backoffice/templates/components/bo-header", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 98
              },
              "end": {
                "line": 6,
                "column": 166
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element9, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["company"], [], ["loc", [null, [6, 137], [6, 161]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 95
              },
              "end": {
                "line": 7,
                "column": 163
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element8, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["accounts"], [], ["loc", [null, [7, 133], [7, 158]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 110
              },
              "end": {
                "line": 8,
                "column": 193
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["keyword_adjustment"], [], ["loc", [null, [8, 153], [8, 188]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 104
              },
              "end": {
                "line": 9,
                "column": 168
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element6, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["survey"], [], ["loc", [null, [9, 140], [9, 163]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "due-backoffice/templates/components/bo-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "Companies");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("\n   ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "Comptes");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "Ajustements");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "Self surveys");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [1]);
          var element11 = dom.childAt(fragment, [3]);
          var element12 = dom.childAt(fragment, [5]);
          var element13 = dom.childAt(fragment, [7]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element10, 'class');
          morphs[1] = dom.createMorphAt(element10, 0, 0);
          morphs[2] = dom.createAttrMorph(element11, 'class');
          morphs[3] = dom.createMorphAt(element11, 0, 0);
          morphs[4] = dom.createAttrMorph(element12, 'class');
          morphs[5] = dom.createMorphAt(element12, 0, 0);
          morphs[6] = dom.createAttrMorph(element13, 'class');
          morphs[7] = dom.createMorphAt(element13, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [6, 37], [6, 47]]]], "companies"], [], ["loc", [null, [6, 33], [6, 60]]]], "active"], [], ["loc", [null, [6, 28], [6, 71]]]]]]], ["block", "link-to", ["companies.index"], [], 0, null, ["loc", [null, [6, 98], [6, 178]]]], ["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [7, 37], [7, 47]]]], "accounts"], [], ["loc", [null, [7, 33], [7, 59]]]], "active"], [], ["loc", [null, [7, 28], [7, 70]]]]]]], ["block", "link-to", ["accounts.index"], [], 1, null, ["loc", [null, [7, 95], [7, 175]]]], ["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [8, 37], [8, 47]]]], "keyword-adjustments"], [], ["loc", [null, [8, 33], [8, 70]]]], "active"], [], ["loc", [null, [8, 28], [8, 81]]]]]]], ["block", "link-to", ["keyword-adjustments"], [], 2, null, ["loc", [null, [8, 110], [8, 205]]]], ["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [9, 37], [9, 47]]]], "self-surveys"], [], ["loc", [null, [9, 33], [9, 63]]]], "active"], [], ["loc", [null, [9, 28], [9, 74]]]]]]], ["block", "link-to", ["self-surveys"], [], 3, null, ["loc", [null, [9, 104], [9, 180]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 98
              },
              "end": {
                "line": 12,
                "column": 162
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element4, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["data_tool"], [], ["loc", [null, [12, 131], [12, 157]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 13,
              "column": 4
            }
          },
          "moduleName": "due-backoffice/templates/components/bo-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "data-tool");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element5, 'class');
          morphs[1] = dom.createMorphAt(element5, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [12, 37], [12, 47]]]], "data-tool"], [], ["loc", [null, [12, 33], [12, 60]]]], "active"], [], ["loc", [null, [12, 28], [12, 71]]]]]]], ["block", "link-to", ["data-tool"], [], 0, null, ["loc", [null, [12, 98], [12, 174]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 104
              },
              "end": {
                "line": 15,
                "column": 174
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["tagging_tool"], [], ["loc", [null, [15, 140], [15, 169]]]]]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 114
              },
              "end": {
                "line": 16,
                "column": 194
              }
            },
            "moduleName": "due-backoffice/templates/components/bo-header.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["subexpr", "admin-icon", ["reporting-tagging"], [], ["loc", [null, [16, 155], [16, 189]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 4
            }
          },
          "moduleName": "due-backoffice/templates/components/bo-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "tagging-tool");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "data-content", "reporting-tagging");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var element3 = dom.childAt(fragment, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createAttrMorph(element3, 'class');
          morphs[3] = dom.createMorphAt(element3, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [15, 37], [15, 47]]]], "tagging-tool"], [], ["loc", [null, [15, 33], [15, 63]]]], "active"], [], ["loc", [null, [15, 28], [15, 74]]]]]]], ["block", "link-to", ["tagging-tool"], [], 0, null, ["loc", [null, [15, 104], [15, 186]]]], ["attribute", "class", ["concat", ["ui popable ", ["subexpr", "if", [["subexpr", "eq", [["get", "currentCat", ["loc", [null, [16, 37], [16, 47]]]], "reporting-tagging"], [], ["loc", [null, [16, 33], [16, 68]]]], "active"], [], ["loc", [null, [16, 28], [16, 79]]]]]]], ["block", "link-to", ["reporting-tagging"], [], 1, null, ["loc", [null, [16, 114], [16, 206]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 6
          }
        },
        "moduleName": "due-backoffice/templates/components/bo-header.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "main-topbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "bo-icon");
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "/assets/images/bo-icon.png");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("Backoffice");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "class", "ui popable-logout");
        dom.setAttribute(el2, "data-content", "Logout");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0]);
        var element15 = dom.childAt(element14, [5]);
        var element16 = dom.childAt(element14, [7]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element15, 1, 1);
        morphs[1] = dom.createMorphAt(element15, 2, 2);
        morphs[2] = dom.createMorphAt(element15, 3, 3);
        morphs[3] = dom.createElementMorph(element16);
        morphs[4] = dom.createMorphAt(element16, 0, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "currentUser.content.can_access_company", ["loc", [null, [5, 10], [5, 48]]]]], [], 0, null, ["loc", [null, [5, 4], [10, 11]]]], ["block", "if", [["get", "currentUser.content.can_access_data_tool", ["loc", [null, [11, 10], [11, 50]]]]], [], 1, null, ["loc", [null, [11, 4], [13, 11]]]], ["block", "if", [["get", "currentUser.content.can_access_tagging_tool", ["loc", [null, [14, 10], [14, 53]]]]], [], 2, null, ["loc", [null, [14, 4], [17, 11]]]], ["element", "action", ["invalidateSession"], [], ["loc", [null, [19, 31], [19, 61]]]], ["inline", "fa-icon", ["sign-out"], [], ["loc", [null, [19, 84], [19, 106]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});