define('due-backoffice/mixins/jsonapi-adapter', ['exports', 'due-backoffice/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_dataAdapterMixin.default, {
    authorizer: 'authorizer:oauth2',
    namespace: 'admin_api/v1',
    host: _environment.default.EmberENV.API_URL,
    coalesceFindRequests: true
  });
});