define('due-backoffice/models/data-access-request', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // Editables attrs
    request_type: (0, _attr.default)('string'),
    field_modified: (0, _attr.default)('string'),
    requested_at: (0, _attr.default)('date'),
    executed_at: (0, _attr.default)('date'),

    // RelationShips
    feedback: (0, _relationships.belongsTo)('feedback'),
    admin_user: (0, _relationships.belongsTo)('admin-user')
  });
});