define('due-backoffice/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'due-backoffice/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.EmberENV.API_URL + '/oauth/token',
    serverTokenRevocationEndpoint: _environment.default.EmberENV.API_URL + '/oauth/revoke',
    makeRequest: function makeRequest(url, data) {
      return this._super(url, data);
    }
  });
});