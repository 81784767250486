define('due-backoffice/models/rating', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // ReadOnly attrs
    picture: _emberData.default.attr('string', { readOnly: true }),

    // Editables attrs
    position: _emberData.default.attr('number'),
    score: _emberData.default.attr('number'),
    picture_raw_data: _emberData.default.attr(),

    // RelationShips
    rating_scale: _emberData.default.belongsTo('rating-scale')

    // Computeds

  });
});