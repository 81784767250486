define('due-backoffice/models/custom-stat-category', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // ReadOnly attrs

    // Editables attrs
    title: (0, _attr.default)(),
    position: (0, _attr.default)('number'),

    // RelationShips
    custom_stat: (0, _relationships.belongsTo)('custom-stat'),
    question_category: (0, _relationships.belongsTo)('question-category'),
    language: (0, _relationships.belongsTo)('language')

    // Computeds

  });
});