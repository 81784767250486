define('due-backoffice/routes/tagging-tool/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    startDate: moment().subtract(3, 'days').startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),

    model: function model() {
      return Ember.RSVP.hash({
        surveys: this.store.query('survey', { filter: { auto_tagging_enabled: true, zone: { from: 'all', startDate: this.get('startDate'), endDate: this.get('endDate') } } })
      });
    },


    // This method is used to set up the controller for the current route
    // => Sets the same startDate and endDate values in the controller as in the router
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('startDate', this.get('startDate'));
      controller.set('endDate', this.get('endDate'));
    }
  });
});