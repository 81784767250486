define('due-backoffice/components/ui-tab', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var oneWay = Ember.computed.oneWay;
  var debug = Ember.debug;
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    classNames: ['languages-tabs'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.defaultSelector();
    },


    notSelectedIndexes: Ember.computed.setDiff('existingIndexes', 'selectedIndexes'),

    defaultSelector: Ember.observer('selectedIndexes.[]', function () {
      var _this = this;

      Ember.run.schedule('afterRender', function () {
        if (_this.$('')) {
          var $active = _this.$('.item.active')[0];
          var $active_tab = _this.$('.tab.active')[0];
          var $first_item = void 0;
          if (!$active) {
            $first_item = _this.$('.item')[0];
            if ($first_item) {
              $first_item.classList += ' active';
            }
          }
          if (!$active_tab) {
            $first_item = _this.$('.tab')[0];
            if ($first_item) {
              $first_item.classList += ' active';
            }
          }
        }
      });
    }),

    actions: {
      selectTab: function selectTab(index) {
        this.$('.item').removeClass('active');
        this.$('.tab').removeClass('active');
        this.$('.item.' + index).addClass('active');
        this.$('.tab.' + index).addClass('active');
      },
      removeTab: function removeTab(index) {
        this.get('removeIndex')(index);
      },
      addIndex: function addIndex(index) {
        var _this2 = this;

        this.get('addIndex')(index);
        Ember.run.schedule('afterRender', function () {
          _this2.send('selectTab', index);
        });
      }
    }

  });
});