define('due-backoffice/models/custom-stat', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // ReadOnly attrs

    // Editables attrs
    name: (0, _attr.default)(),
    show_resolution_rates: (0, _attr.default)('boolean'),
    show_detractor_resolution_rates: (0, _attr.default)('boolean'),
    show_detractor_global: (0, _attr.default)('boolean'),
    show_passive_global: (0, _attr.default)('boolean'),
    show_promoter_global: (0, _attr.default)('boolean'),
    show_non_resolved_rates: (0, _attr.default)('boolean'),
    show_tagged_number: (0, _attr.default)('boolean'),
    show_non_tagged_rates: (0, _attr.default)('boolean'),
    show_rating_position: (0, _attr.default)('boolean'),
    hide_nps: (0, _attr.default)('boolean'),
    hide_closed_questions: (0, _attr.default)('boolean'),
    show_tags_positive: (0, _attr.default)('boolean'),
    show_tags_negative: (0, _attr.default)('boolean'),
    show_tags_neutral: (0, _attr.default)('boolean'),
    show_tags_without_feeling: (0, _attr.default)('boolean'),
    tags: (0, _attr.default)('array'),
    segments: (0, _attr.default)(),
    questions: (0, _attr.default)(),
    feedback_column_title: (0, _attr.default)(),
    nps_column_title: (0, _attr.default)(),
    average_recommendation_column_title: (0, _attr.default)(),
    average_recommendation_position_column_title: (0, _attr.default)(),

    // RelationShips
    surveys: (0, _relationships.hasMany)('surveys'),
    custom_stat_categories: (0, _relationships.hasMany)('custom-stat-categories'),
    company: (0, _relationships.belongsTo)('company')

    // Computeds

  });
});