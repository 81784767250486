define('due-backoffice/components/companies/segments/randomize-feedbacks-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = _companySegment.default.extend({

    store: Ember.inject.service(),
    title: 'Répartition des retours',
    childClassNames: "ui large list",
    DateBeginning: null,
    firstConfirmation: false,
    strConfirm: '',
    strToMatch: '',
    CataclysmStr: ['Fin du monde', 'Chernobyl', 'Armageddon', 'Apocalypse', 'Je suis coupable', 'Bob leponge'],

    dateBeginning: Ember.computed('DateBeginning', {
      get: function get() {
        return this.get('DateBeginning');
      },
      set: function set(_, value) {
        var temp = value ? new Date(value) : null;
        this.set('DateBeginning', temp);
        return value;
      }
    }),

    canRandomize: Ember.computed('firstConfirmation', 'strConfirm', 'DateBeginning', function () {
      var changedBelongsTo = this.get('model').changedAttributes();
      if (this.get('DateBeginning') && (!this.get('firstConfirmation') || this.get('firstConfirmation') && this.get('strToMatch') == this.get('strConfirm'))) {
        return;
      }
      return 'disabled';
    }),

    isSaving: Ember.computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    actions: {
      randomize: function randomize() {
        if (!this.get('firstConfirmation')) {
          var possibility = this.get('CataclysmStr');
          var index = Math.floor(Math.random() * Math.floor(possibility.length));
          this.set('strConfirm', '');
          this.set('strToMatch', possibility[index]);
          this.set('firstConfirmation', true);
          return;
        }
        this.get('store').unloadAll('RandomizeFeedback');
        var randomizer = this.get('store').createRecord('RandomizeFeedback');
        randomizer.date_beginning = this.get('DateBeginning');
        randomizer.company_id = this.get('model.id');
        randomizer.save();
        this.set('DateBeginning', null);
        this.set('firstConfirmation', false);
      }
    }

  });
});