define('due-backoffice/models/dashboard-language', ['exports', 'ember-data/model', 'ember-data/relationships'], function (exports, _model, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    company: (0, _relationships.belongsTo)('company'),
    language: (0, _relationships.belongsTo)('language')
  });
});