define('due-backoffice/components/keyword-adjustments/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    tagName: 'li',
    classNames: ['adjustment'],

    actions: {
      deleteAdjustment: function deleteAdjustment() {
        var _this = this;

        $('#delete-keyword-adjustment-' + this.get('adjustment.id')).modal({
          onApprove: function onApprove() {
            return _this.get('adjustment').destroyRecord().then(function () {
              return _this.get('checkAfterDelete')();
            });
          }
        }).modal('show');
      }
    }
  });
});