define('due-backoffice/components/companies/segments/subscription-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),
    new_target: 'subscription-plan.new',
    title: 'abonnement',

    didInsertElement: function didInsertElement() {
      this.initPlansDd();
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      $('#sub.ui.modal').remove();
    },


    canSavePlan: Ember.computed('model.subscription_plan.id', 'new_plan', function () {
      if (this.get('new_plan')) {
        return this.get('new_plan') !== this.get('model.subscription_plan.id');
      }
      return false;
    }),

    setPlanValuesDd: function setPlanValuesDd() {
      $(".plans-dd.ui.dropdown").dropdown('set selected', this.get('model.subscription_plan.id'));
    },


    unpaidBillings: Ember.computed('model.id', function () {
      return this.get('store').query('company-subscription-plan-billing', {
        filter: {
          company_id: this.get('model.id'),
          paid: false
        }
      });
    }),

    subscriptionName: Ember.computed('left', 'model.subscription_plan.name', 'model.subscription_plan.duty_free_amount', 'model.subscription_plan.taxes_rate', function () {
      switch (this.get('model.subscription_plan.name')) {
        case 'unicorn':
          return '<b>Licorne</b>';
        case 'trial':
          return "<b>Trial - </b>Fin de la periode d'essai : " + this.get('left');
        default:
          var taxes_rates = this.get('model.subscription_plan.taxes_rate');
          return '<b>' + this.get('model.subscription_plan.duty_free_amount') / 100.0 + ' \u20AC </b> (' + (taxes_rates ? taxes_rates + "%TVA" : "TTC") + ')';
      }
    }),

    initPlansDd: function initPlansDd() {
      var _this = this;

      this.get('store').findAll('subscription-plan', { reload: true }).then(function (plans) {
        plans = plans.filter(function (a) {
          return !['trial', 'unicorn'].includes(a.get('name'));
        });
        $("#plans-dd.ui.dropdown").dropdown({
          fullTextSearch: true,
          values: plans.map(function (plan) {
            var taxes_rates = plan.get('taxes_rate');
            return {
              value: plan.get('id'),
              name: plan.get('duty_free_amount') / 100.0 + ' \u20AC (' + (taxes_rates ? taxes_rates + "%TVA" : "TTC") + ')',
              selected: plan.get('id') == _this.get('model.subscription_plan.id')
            };
          }),
          onChange: function onChange(value, text, $selectedItem) {
            _this.set('new_plan', value);
          }
        });
        $(".plans-dd.ui.dropdown").removeClass('loading');
      });
    },


    left: Ember.computed('model.endOfTrial', function () {
      if (isNaN(this.get('model.endOfTrial'))) {
        return "N'a pas encore commencée";
      } else {
        return 'reste ' + this.get('model.endOfTrialPhrase') + ' entier(s)';
      }
    }),

    actions: {
      saveNewPlan: function saveNewPlan() {
        this.set('model.subscription_plan', this.get('store').peekRecord('subscription-plan', this.get('new_plan')));
        this.get('model').save();
      },
      resetTrial: function resetTrial() {
        var _this2 = this;

        this.set('modal_phrase', 'Voulez vous reset le trial de l\'entreprise "' + this.get('model.name') + '" \xE0 14 jours ?');
        this.set('modal_icon', 'history');
        $('#sub.ui.modal').modal({
          onApprove: function onApprove() {
            _this2.set('model.trial_start', moment().toDate());
            _this2.get('model').save().then(function () {
              return _this2.setPlanValuesDd();
            });
          }
        }).modal('show');
      },
      deleteBilling: function deleteBilling(billing) {
        this.set('modal_phrase', 'Supprimer definitivement la facture "' + billing.get('full_billing_number') + '" de ' + billing.get('amount') + ' \u20AC du ' + billing.get('created_at').toLocaleDateString('fr', { month: 'long', year: 'numeric' }) + ' ?');
        this.set('modal_icon', 'trash');
        $('#sub.ui.modal').modal({
          onApprove: function onApprove() {
            return billing.destroyRecord();
          }
        }).modal('show');
      },
      passToPlan: function passToPlan(planName, icon) {
        var _this3 = this;

        var licorn = this.get('store').query("subscription_plan", { filter: { name: planName } });
        this.set('modal_phrase', 'Voulez vous passer l\'entreprise "' + this.get('model.name') + '" en plan ' + planName + ' ?');
        this.set('modal_icon', icon);
        $('#sub.ui.modal').modal({
          onApprove: function onApprove() {
            licorn.then(function (plan) {
              _this3.set('model.subscription_plan', plan.get('firstObject'));
              _this3.get('model').save().then(function () {
                return _this3.setPlanValuesDd();
              });
            });
          }
        }).modal('show');
      }
    }
  });
});