define('due-backoffice/components/custom-stat/question-category-chooser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    is_active: false,

    didInsertElement: function didInsertElement() {
      var question = this.get('selectedQuestions').findBy('id', this.get('model.id'));
      this.set('question_choosed', !!question);
      this.set('question', question);
    },


    questionFields: Ember.computed('question', 'availableLanguages', function () {
      var _this = this;

      var availableLanguages = this.get('availableLanguages');
      var question = this.get('question');
      var questionFields = [];
      availableLanguages.forEach(function (availableLanguage) {
        var questionField = {
          'tag': availableLanguage.tag,
          'title': question !== undefined ? question[availableLanguage.tag] : '',
          'name': availableLanguage.name
        };
        questionFields.push(questionField);
        _this.set('model.' + availableLanguage.tag, questionField.title);
      });
      if (question) {
        this.set('model.position', question.position);
      }
      this.set('model.availableLanguages', availableLanguages);
      return questionFields;
    }),

    is_active_class: Ember.computed('is_active', function () {
      return this.get('is_active') ? 'active' : '';
    }),

    positionPlaceholder: Ember.computed('selectedQuestions.length', 'question_choosed', function () {
      if (this.get('question_choosed')) {
        return this.get('selectedQuestions').indexOf(this.get('model'));
      }
      var $elem = $('input[name=position-' + this.get('model.id') + ']')[0];
      if ($elem) {
        $elem.value = this.get('selectedQuestions.length');
      }
      return this.get('selectedQuestions.length');
    }),

    chooseQuestion: Ember.observer('question_choosed', function () {
      this.get('chooseAction')(this.get('model'), this.get('question_choosed'));
      if (this.get('model.position') === undefined) {
        this.set('model.position', this.get('positionPlaceholder'));
      }
    }),

    actions: {
      toogleActive: function toogleActive() {
        this.toggleProperty('is_active');
      },

      setTitle: function setTitle(availableLanguage) {
        this.set('model.' + availableLanguage.tag, availableLanguage.title);
      }
    }
  });
});