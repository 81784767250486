define('due-backoffice/models/company-creation', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend( /*EmberValidations.Mixin,*/{
    email: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    language_id: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string', { defaultValue: _moment.default.tz.guess() })
  });
});