define('due-backoffice/routes/companies/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      var _this = this;

      this.get('store').find('company_stat', model.id).then(function (company_stat) {
        return _this.controllerFor('companies.show').set('company_stat', company_stat);
      });
    }
  });
});