define('due-backoffice/routes/accounts/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        company: this.modelFor('companies'),
        existings_accounts: this.modelFor('companies').get('accounts'),
        languages: this.store.query('language', { filter: { dashboard_lang_ids: this.modelFor('companies').get('dashboard_languages').map(function (dl) {
              return dl.get('id');
            }).filter(function (dl) {
              return dl != null;
            }) } })
      });
    }
  });
});