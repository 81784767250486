define('due-backoffice/models/rating-scale', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // ReadOnly attrs

    // Editables attrs
    rating_scale_type: _emberData.default.attr('string'),
    scale: _emberData.default.attr('number'),
    is_nps: _emberData.default.attr('boolean'),
    show_nps_legend: _emberData.default.attr('boolean'),
    has_custom_html: _emberData.default.attr('boolean'),
    custom_html: _emberData.default.attr(),

    // RelationShips
    ratings: _emberData.default.hasMany('ratings')

    // Computeds

  });
});