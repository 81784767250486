define('due-backoffice/models/company-stat', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    // ReadOnly attrs
    live_accounts_count: (0, _attr.default)('number', { readOnly: true }),
    live_surveys_count: (0, _attr.default)('number', { readOnly: true }),
    live_company_integrations_count: (0, _attr.default)('number', { readOnly: true }),
    feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    completed_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    last_7_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    last_7_completed_feedbacks_count: (0, _attr.default)('number', { readOnly: true }),
    nps: (0, _attr.default)('number', { readOnly: true }),
    avg: (0, _attr.default)('number', { readOnly: true }),
    last_7_nps: (0, _attr.default)('number', { readOnly: true }),
    last_7_avg: (0, _attr.default)('number', { readOnly: true })
  });
});