define('due-backoffice/components/tag-transfert', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    actions: {
      saveName: function saveName() {
        this.get('saveName')();
      }
    }

  });
});