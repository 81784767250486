define("due-backoffice/helpers/range-times", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (params) {
    var range = [];
    for (var i = params[0]; i < params[1]; ++i) {
      range.push(i);
    }
    return range;
  });
});