define("due-backoffice/helpers/admin-icon", ["exports", "due-backoffice/constants/icons"], function (exports, _icons) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function () {
    var icon = _icons.default[(arguments[0][0] || "").underscore()];
    if (!icon && arguments[0][0]) {
      Ember.warn("admin-icon: you should add " + arguments[0][0] + " in app/constants/icons file");
    }
    return (icon || arguments[0][0]) + " " + (arguments[1] && arguments[1].icon === false ? 'fa-icon' : 'icon');
  });
});