define('due-backoffice/controllers/companies/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var cacheFor = Ember.cacheFor;
  var computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    companiesFetcher: Ember.inject.service(),

    companies: computed.alias('model'),
    page_count: computed.alias('companies.meta.page_count'),
    current_page: computed.alias('companiesFetcher.current_page'),

    actions: {
      updateCompaniesList: function updateCompaniesList(companies) {
        this.set('companies', companies);
      }
    }

  });
});