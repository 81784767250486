define('due-backoffice/components/companies/item-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    companiesFetcher: Ember.inject.service(),
    table_list: Ember.computed.alias('currentUser.content.company_index_is_table'),

    actions: {
      pageChanged: function pageChanged(new_page) {
        this.get('updateCompaniesList')(this.get('companiesFetcher').changePage(new_page));
      },
      companiesFiltered: function companiesFiltered(filters) {
        this.get('updateCompaniesList')(this.get('companiesFetcher').changeFilters(filters));
      }
    }
  });
});