define('due-backoffice/components/companies/segments/infos-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),
    title: 'Retours'
  });
});