define('due-backoffice/controllers/keyword-adjustments/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    current_page: Ember.computed('model.meta', {
      get: function get() {
        return this.get('model.meta').current_page;
      },
      set: function set(_, value) {
        this.searchKeywordAdjustments(value);
        return value;
      }
    }),

    total_pages: Ember.computed('model.meta', function () {
      return this.get('model.meta').page_count;
    }),

    triggerAdjustmentSearch: Ember.observer('searchAdjustment', function () {
      this.get('searchAdjustment');
      Ember.run.debounce(this, this.searchKeywordAdjustments, 1, 600);
    }),

    searchKeywordAdjustments: function searchKeywordAdjustments() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;

      this.set('isLoading', true);
      this.store.query('keywordAdjustment', { filter: { text: this.get('searchAdjustment') }, page: { number: page }
      }).then(function (adjustments) {
        _this.set('isLoading', false);
        _this.set('model', adjustments);
      });
    },


    actions: {
      checkAfterDelete: function checkAfterDelete() {
        this.searchKeywordAdjustments(1);
      }
    }

  });
});