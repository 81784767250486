define('due-backoffice/transforms/file', ['exports', 'ember-data', 'ember-getowner-polyfill', 'due-backoffice/config/environment'], function (exports, _emberData, _emberGetownerPolyfill, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty,
      copy = Ember.copy,
      assign = Ember.assign;
  var Transform = _emberData.default.Transform;
  exports.default = Transform.extend({
    /**
     * Deserialize file json to a file object
     *
     * @public
     */
    deserialize: function deserialize(serialized, attributeMeta) {
      var File = (0, _emberGetownerPolyfill.default)(this)._lookupFactory('object:file');

      return File.create(serialized, _environment.default.paperclip, assign(copy(attributeMeta), {
        isNew: isEmpty(serialized),
        isEmpty: isEmpty(serialized),
        attributes: Object.keys(serialized || {})
      }));
    },

    /**
     * Serialize a file object to json
     *
     * @public
     */
    serialize: function serialize(deserialized) {
      return deserialized.serialize();
    }
  });
});