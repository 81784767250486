define('due-backoffice/routes/custom-stats/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      this.get('store').findAll('language').then(function () {
        model.get('custom_stat_categories').then(function (qscs) {
          Ember.RSVP.all(qscs.mapBy('question_category')).then(function () {
            var questions = [];
            var languages = [];
            qscs.forEach(function (qsc) {
              var question = questions.findBy('id', qsc.get('question_category.id'));
              if (!question) {
                question = {
                  id: qsc.get('question_category.id'),
                  position: qsc.get('position')
                };
                questions.push(question);
              }
              languages.push(qsc.get('language.tag').toLocaleLowerCase());
              question[qsc.get('language.tag').toLocaleLowerCase()] = qsc.get('title');
            });
            model.set('selected_languages', languages.uniq());
            model.set('questions', questions);
          });
        });
      });
    }
  });
});