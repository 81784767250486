define("due-backoffice/utils/rotate", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (nums, k) {
    var n = nums.length;
    k %= n;
    reverse(nums, 0, n - 1);
    reverse(nums, 0, k - 1);
    reverse(nums, k, n - 1);
    return nums;
  };

  var reverse = function reverse(nums, start, end) {
    while (start < end) {
      var _ref = [nums[end], nums[start]];
      nums[start] = _ref[0];
      nums[end] = _ref[1];

      start++;
      end--;
    }
  };
});