define('due-backoffice/helpers/match', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function matchHelper(params) {
    var var1 = Ember.isArray(params[0]) ? params[0] : params[1];
    var var2 = Ember.isArray(params[0]) ? params[1] : params[0];
    if (!Ember.isArray(var1) || Ember.isArray(var2)) {
      return var1 === var2;
    } else {
      return var1.indexOf(var2) !== -1;
    }
  });
});