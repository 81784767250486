define('due-backoffice/components/info-tooltip', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: 'diduenjoy-info-circle',
    attributeBindings: ['data-content'],

    didInsertElement: function didInsertElement() {
      var message = this.get('message') || "";
      $(this.$()).tooltipster({
        content: message.toString(),
        contentAsHTML: true
      });
    },

    languageUpdate: Ember.observer('message', function () {
      $(this.$()).tooltipster('content', this.get('message').toString());
      $(this.$()).tooltipster('reposition');
    })

  });
});