define('due-backoffice/components/segment-picker/item-accordion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var debug = Ember.debug;
  var Component = Ember.Component,
      computed = Ember.computed,
      htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',

    languages: Ember.computed(function () {
      return this.get('store').findAll('language');
    }),

    legends: Ember.computed('model.segment_picker_legends.@each.text', 'languages.@each.tag', function () {
      this.get('languages');
      return htmlSafe((this.get('model.segment_picker_legends') || []).map(function (spl) {
        return spl.get('language.tag') + ': ' + spl.get('text');
      }).join(', '));
    }),

    options: Ember.computed('model.values', function () {
      return this.get('model.values');
    }),

    actions: {
      editSegmentPicker: function editSegmentPicker() {
        this.get('router').transitionTo('segment-pickers.edit', this.get('model.survey.id'), this.get('model.id'));
      },
      deleteSegmentPicker: function deleteSegmentPicker() {
        var _this = this;

        $('#delete-segment-picker-' + this.get('model.id')).modal({
          onApprove: function onApprove() {
            return _this.get('model').destroyRecord();
          }
        }).modal('show');
      }
    }

  });
});