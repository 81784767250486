define("due-backoffice/templates/components/companies/segments/-integrations-segment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.6",
            "loc": {
              "source": null,
              "start": {
                "line": 5,
                "column": 8
              },
              "end": {
                "line": 7,
                "column": 8
              }
            },
            "moduleName": "due-backoffice/templates/components/companies/segments/-integrations-segment.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createElement("i");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(element3, [0]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createAttrMorph(element4, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["floating ui ", ["subexpr", "if", [["get", "integration.is_active", ["loc", [null, [6, 39], [6, 60]]]], "green", "orange"], [], ["loc", [null, [6, 34], [6, 79]]]], " circular label"]]], ["attribute", "class", ["concat", ["ui alone ", ["subexpr", "admin-icon", [["get", "integration.icon", ["loc", [null, [6, 128], [6, 144]]]]], [], ["loc", [null, [6, 115], [6, 146]]]]]]]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 10,
              "column": 4
            }
          },
          "moduleName": "due-backoffice/templates/components/companies/segments/-integrations-segment.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "img-wrapper");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var element6 = dom.childAt(element5, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element5, 1, 1);
          morphs[1] = dom.createAttrMorph(element6, 'class');
          morphs[2] = dom.createAttrMorph(element6, 'src');
          return morphs;
        },
        statements: [["block", "if", [["get", "integration.completed", ["loc", [null, [5, 14], [5, 35]]]]], [], 0, null, ["loc", [null, [5, 8], [7, 15]]]], ["attribute", "class", ["concat", ["ui image ", ["subexpr", "unless", [["get", "integration.completed", ["loc", [null, [8, 38], [8, 59]]]], "disabled"], [], ["loc", [null, [8, 29], [8, 72]]]]]]], ["attribute", "src", ["get", "integration.logo_url", ["loc", [null, [8, 80], [8, 100]]]]]],
        locals: ["integration"],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 22,
              "column": 2
            }
          },
          "moduleName": "due-backoffice/templates/components/companies/segments/-integrations-segment.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui checkbox");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("input");
          dom.setAttribute(el3, "type", "checkbox");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          if (this.cachedFragment) {
            dom.repairClonedNode(dom.childAt(element1, [1]), [], true);
          }
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createElementMorph(element0);
          morphs[2] = dom.createAttrMorph(element2, 'checked');
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["ui compact segment ", ["subexpr", "get", [["get", "payedIntegrationsClasses", ["loc", [null, [16, 97], [16, 121]]]], ["subexpr", "concat", [["get", "index", ["loc", [null, [16, 130], [16, 135]]]]], [], ["loc", [null, [16, 122], [16, 136]]]]], [], ["loc", [null, [16, 91], [16, 138]]]]]]], ["element", "action", ["togglePayingIntegration", ["get", "integration.id", ["loc", [null, [16, 42], [16, 56]]]], ["get", "index", ["loc", [null, [16, 57], [16, 62]]]]], [], ["loc", [null, [16, 7], [16, 64]]]], ["attribute", "checked", ["subexpr", "get", [["get", "payedIntegrations", ["loc", [null, [18, 43], [18, 60]]]], ["get", "integration.id", ["loc", [null, [18, 61], [18, 75]]]]], [], ["loc", [null, [18, 37], [18, 77]]]]], ["content", "integration.name", ["loc", [null, [19, 13], [19, 33]]]]],
        locals: ["integration", "index"],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 6
          }
        },
        "moduleName": "due-backoffice/templates/components/companies/segments/-integrations-segment.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "installed-integrations");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "ui header");
        var el3 = dom.createTextNode("Actives (");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(") - Désactivées (");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(") - Non Complétées (");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(")");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui divider");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "paying-integrations");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        dom.setAttribute(el2, "class", "ui header");
        var el3 = dom.createTextNode("Intégrations payantes accessibles");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [0]);
        var element8 = dom.childAt(element7, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element8, 1, 1);
        morphs[1] = dom.createMorphAt(element8, 3, 3);
        morphs[2] = dom.createMorphAt(element8, 5, 5);
        morphs[3] = dom.createMorphAt(element7, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [4]), 3, 3);
        return morphs;
      },
      statements: [["content", "activeIntegrationsCount", ["loc", [null, [2, 33], [2, 60]]]], ["content", "notActiveIntegrationsCount", ["loc", [null, [2, 77], [2, 107]]]], ["content", "notCompletedIntegrationsCount", ["loc", [null, [2, 127], [2, 160]]]], ["block", "collection-iterator", [], ["items", ["subexpr", "@mut", [["get", "sortedIntegrations", ["loc", [null, [3, 33], [3, 51]]]]], [], []]], 0, null, ["loc", [null, [3, 4], [10, 28]]]], ["block", "each", [["get", "payingIntegrations", ["loc", [null, [15, 10], [15, 28]]]]], [], 1, null, ["loc", [null, [15, 2], [22, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});