define('due-backoffice/router', ['exports', 'due-backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('app', { path: '/' }, function () {
      // SALES
      // this.route('catalogue', {resetNamespace: true});
      // this.route('catalogue.category', {path: '/catalogue/category/:category_id', resetNamespace: true});
      // this.route('catalogue.product', {path: '/catalogue/product/:product_id', resetNamespace: true});

      // this.route('order', {resetNamespace: true});
      // this.route('order.invoice', {resetNamespace: true});

      // // MANAGER
      // this.route('products', { resetNamespace: true}, function() {
      //   this.route('new');
      //   this.route('products', { path: ':product_id', resetNamespace: true }, function() {
      //     this.route('edit');
      //     this.route('show');

      //     this.route('variants', { resetNamespace: true }, function() {
      //       this.route('new');
      //       this.route('variants', { path: ':variant_id', resetNamespace: true }, function() {
      //         this.route('edit');
      //         this.route('show');
      //       });
      //     });
      //   });
      // });

      this.route('companies', { resetNamespace: true }, function () {
        this.route('new');
        this.route('companies', { path: ':company_id', resetNamespace: true }, function () {
          this.route('show', function () {
            this.route('rating-scale', { resetNamespace: true }, function () {
              this.route('new');
              this.route('rating-scale', { path: ':rating_scale_id', resetNamespace: true }, function () {
                this.route('show');
              });
            });
          });
          this.route('edit');
          this.route('accounts', { resetNamespace: true }, function () {
            this.route('new');
            this.route('accounts', { path: ':account_id', resetNamespace: true }, function () {
              this.route('show');
            });
          });
          this.route('surveys', { resetNamespace: true }, function () {
            this.route('surveys', { path: ':survey_id', resetNamespace: true }, function () {
              this.route('show');
              this.route('rating-scale', { resetNamespace: true }, function () {
                this.route('new');
                this.route('rating-scale', { path: ':rating_scale_id', resetNamespace: true }, function () {
                  this.route('show');
                });
              });
              this.route('segment-pickers', { resetNamespace: true }, function () {
                this.route('new');
                this.route('segment-pickers', { path: ':segment_picker_id', resetNamespace: true }, function () {
                  this.route('edit');
                });
              });
            });
          });
          this.route('custom-stats', { resetNamespace: true }, function () {
            this.route('new');
            this.route('custom-stats', { path: ':custom_stat_id', resetNamespace: true }, function () {
              this.route('edit');
            });
          });
          this.route('subscription-plan', { resetNamespace: true }, function () {
            this.route('new');
          });
          this.route('company-notifications', { resetNamespace: true }, function () {
            this.route('new');
            this.route('company-notifications', { path: ':notification_id', resetNamespace: true }, function () {
              this.route('edit');
            });
          });
        });
      });

      this.route('accounts', { resetNamespace: true }, function () {
        // this.route('accounts', { path: ':account_id', resetNamespace: true }, function() {
        //     this.route('show');
        //   });
      });

      // this.route('locations', { resetNamespace: true}, function() {
      //   this.route('new');
      //   this.route('locations', { path: ':location_id', resetNamespace: true }, function() {
      //     this.route('show');
      //     this.route('edit');
      //   });
      // });

      // this.route('categories', { resetNamespace: true}, function() {
      //   this.route('new');
      //   this.route('categories', { path: ':category_id', resetNamespace: true }, function() {
      //     this.route('show');
      //     this.route('edit');
      //   });
      // });

      // this.route('fluxes', { resetNamespace: true}, function() {
      //   this.route('new');
      //   this.route('fluxes', { path: ':flux_id', resetNamespace: true }, function() {
      //     this.route('show');
      //     this.route('edit');
      //   });
      // });

      // this.route('users', { resetNamespace: true}, function() {
      //   this.route('new');
      //   this.route('users', { path: ':user_id', resetNamespace: true }, function() {
      //     this.route('show');
      //     this.route('edit');
      //   });
      // });
      this.route('keyword-adjustments', { resetNamespace: true }, function () {
        this.route('new');
      });

      this.route('data-tool', { resetNamespace: true }, function () {
        this.route('show', { path: ':feedback_id' });
      });

      this.route('unauthorized', { resetNamespace: true }, function () {});

      this.route('tagging-tool', { resetNamespace: true }, function () {});

      this.route('reporting-tagging', { resetNamespace: true }, function () {});

      this.route('self-surveys', { resetNamespace: true }, function () {
        this.route('new');
        this.route('edit', { path: ':self_survey_id' });
      });
    });
  });

  exports.default = Router;
});