define('due-backoffice/routes/company-notifications/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('company-notification', {
        name: null,
        notification_type: null,
        expires_at: null,
        not_limited_to_admin: true,
        text: {},
        company: this.modelFor('companies')
      });
    }
  });
});