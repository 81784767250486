define('due-backoffice/components/companies/search-engine', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    companiesFetcher: Ember.inject.service(),
    filters: computed.oneWay('companiesFetcher.filters'),
    searchStr: computed.oneWay('companiesFetcher.filters.search'),
    warningState: computed.oneWay('companiesFetcher.filters.warning_state'),
    testState: computed.oneWay('companiesFetcher.filters.is_test_company'),

    searchChanged: Ember.observer('searchStr', function () {
      Ember.run.debounce(this, 'search', this.get('searchStr'), 1200);
    }),

    search: function search(searchStr) {
      var filters = this.get('filters');
      Ember.set(filters, 'search', searchStr);
      this.get('companiesFiltered')(filters);
    },


    actions: {
      toggleWarningState: function toggleWarningState() {
        var filters = this.get('filters');
        Ember.set(filters, 'warning_state', !this.get('warningState'));
        this.get('companiesFiltered')(filters);
      },
      toggleTestState: function toggleTestState() {
        var filters = this.get('filters');
        Ember.set(filters, 'is_test_company', !this.get('testState'));
        this.get('companiesFiltered')(filters);
      }
    }
  });
});