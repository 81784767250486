define('due-backoffice/models/verbatim-tagging', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  var _Ember = Ember,
      computed = _Ember.computed;
  exports.default = Model.extend({
    comment: attr('string', { readOnly: true }),
    verbatim_translation: attr('string', { readOnly: true }),
    answer_completed_at: attr('date', { readOnly: true }),
    score: attr('', { readOnly: true }),
    tagging_tags: attr(''),
    company: belongsTo('company'),
    account: belongsTo('Survey'),

    prettyCompletedAt: computed('answer_completed_at', function () {
      return (0, _moment.default)(this.get('answer_completed_at')).format('LLL');
    }).readOnly()
  });
});