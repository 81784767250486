define('due-backoffice/components/rating-scale/new-form', ['exports', 'due-backoffice/constants/rating_scales_scores'], function (exports, _rating_scales_scores) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    rating_scale: Ember.computed.alias('model.scale'),
    rating_scale_add_one: Ember.computed('model.scale', 'model.is_nps', function () {
      return this.get('model.scale') + (this.get('model.is_nps') ? 0 : 1);
    }),

    show_legend: Ember.computed('model.show_nps_legend', {
      get: function get() {
        return this.get('model.show_nps_legend');
      },
      set: function set(_, value) {
        this.set('model.show_nps_legend', value);
        return value;
      }
    }),

    is_nps: Ember.computed('model.is_nps', {
      get: function get() {
        return this.get('model.is_nps');
      },
      set: function set(_, value) {
        this.set('model.is_nps', value);
        return value;
      }
    }),

    has_custom_html: Ember.computed('model.has_custom_html', {
      get: function get() {
        return this.get('model.has_custom_html');
      },
      set: function set(_, value) {
        this.set('model.has_custom_html', value);
        return value;
      }
    }),

    allInputFill: Ember.computed('model.ratings.@each.picture_raw_data', 'rating_scale', 'model.is_nps', 'model.show_nps_legend', function () {
      var ratings = this.get('model.ratings');
      var rating_scale = this.get('rating.scale');
      var i = this.get('model.is_nps') ? 0 : 1;
      var target = this.get('rating_scale') - 1 + i;
      for (i; i <= target; i++) {
        var rating = ratings.findBy('position', i);
        if (!rating || !rating.get('picture_raw_data') && !rating.get('picture')) {
          return 'disabled';
        }
      }
    }),

    canEdit: Ember.computed('edit', function () {
      if (this.get('edit')) {
        return 'disabled';
      }
      return '';
    }),

    actions: {
      changeScale: function changeScale(scale) {
        this.set('rating_scale', scale);
        return true;
      },
      inputClicked: function inputClicked(index) {
        this.$('#file' + index).parent().find('input:file').click();
      },
      inputTest: function inputTest(index) {
        var ratings = this.get('model.ratings') || [];
        var reader = new FileReader();
        var rating = null;
        if (!ratings.findBy('position', index)) {
          rating = this.get('store').createRecord('rating', {
            score: this.get('rating_scale_scores.' + this.get('rating_scale') + '.' + index),
            position: index,
            rating_scale: this.get('model')
          });
          this.get('model.ratings').addObject(rating);
        } else {
          rating = ratings.findBy('position', index);
        }
        reader.addEventListener("load", function () {
          rating.set('picture_raw_data', reader.result);
        });
        reader.readAsDataURL(this.$('#file' + index)[0].files[0]);
        this.$('#file' + index).parent().find('input:text')[0].value = this.$('#file' + index)[0].files[0].name;
      },
      toggleIsNps: function toggleIsNps() {
        $('#is-nps-cb').checkbox(this.toggleProperty('model.is_nps') ? 'check' : 'uncheck');
      },
      toggleShowNPSLegend: function toggleShowNPSLegend() {
        $('#show-nps-legend').checkbox(this.toggleProperty('model.show_nps_legend') ? 'check' : 'uncheck');
      },
      backToSurvey: function backToSurvey() {
        this.get('router').transitionTo('surveys.show', this.get('model.survey.id'));
      },
      saveRatingScale: function saveRatingScale() {
        var _this = this;

        var ratings = this.get('model.ratings');
        ratings = ratings.slice(0, this.get('rating_scale'));
        this.set('model.ratings', ratings);
        this.get('model').save().then(function () {
          Ember.RSVP.all(_this.get('model.ratings').map(function (r) {
            return r.save();
          })).then(function () {
            _this.set('isLoading', '');
            history.back();
          });
        });
        this.set('isLoading', 'loading');
      },
      backToCompany: function backToCompany() {
        history.back();
      }
    },

    rating_scale_scores: Ember.computed('model.is_nps', function () {
      return this.get('model.is_nps') ? _rating_scales_scores.default.nps_scores : _rating_scales_scores.default.normal_scores;
    })

  });
});