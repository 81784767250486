define('due-backoffice/models/randomize-feedback', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    date_beginning: (0, _attr.default)(),
    company_id: (0, _attr.default)('string')
  });
});