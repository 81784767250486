define('due-backoffice/controllers/reporting-tagging/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service,
      debounce = Ember.run.debounce,
      RSVP = Ember.RSVP,
      computed = Ember.computed,
      observer = Ember.observer;
  exports.default = Controller.extend({
    session: service(),

    surveys: [],
    languages: [],
    user: null,
    startDate: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').endOf('day').format('YYYY-MM-DD'),
    urlExport: null,
    stat_filter: 'from tool',

    actualView: 'total',

    dataSorting: ['untagged_count:desc'],
    sortedSurveys: computed.sort('model.surveys', 'dataSorting'),

    dateOptions: [{ key: 'custom', text: "Custom date range" }, { key: 'lastWeek', text: 'Last 7 days' }],
    selectedDateOption: { key: 'lastWeek', text: 'Last 7 days' },
    isCustomDateRange: computed('selectedDateOption', 'selectedDateOption.key', function () {
      return this.get('selectedDateOption.key') === 'custom';
    }),

    selectAllSurveys: false,
    selectedSurveys: [],

    selectAllSurveysChanged: observer('selectAllSurveys', function () {
      this.fetchReport();
    }),

    startDateComputed: computed('startDate', {
      get: function get() {
        return this.get('startDate');
      },
      set: function set(_, value) {
        var oldStartDate = this.get('startDate');
        this.set('startDate', value);
        if (value !== oldStartDate) this.fetchReport();
        return value;
      }
    }),

    endDateComputed: computed('endDate', {
      get: function get() {
        return this.get('endDate');
      },
      set: function set(_, value) {
        var oldEndDate = this.get('endDate');
        this.set('endDate', value);
        if (value !== oldEndDate) this.fetchReport();
        return value;
      }
    }),

    canSearch: function canSearch() {
      return this.get('sortedSurveys').length > 0;
    },

    _fetchReport: function _fetchReport() {
      var _this = this;

      if (!this.canSearch()) {
        return false;
      }

      var filters = {};

      if (this.get('selectAllSurveys') === false) {
        filters.surveys = this.get('selectedSurveys').mapBy('id');
      }
      if (this.get('user.id')) {
        filters.tagger = this.get('user.id');
      }
      if (this.get('startDate')) {
        filters.start_date = this.get('startDate');
      }
      if (this.get('endDate')) {
        filters.end_date = this.get('endDate');
      }
      if (this.get('languages').length > 0) {
        filters.languages = this.get('languages').mapBy('id');
      }
      this.set('stats', null);
      this.set('export', null);
      this.set('statLoading', true);
      this.get('store').queryRecord('reporting-tagging', { filter: filters }).then(function (stats) {
        _this.set('stats', stats);
        _this.set('statLoading', false);
      });
      filters.stat = this.get('stat_filter');
      this.set('urlExport', null);
      this.set('exportLoading', true);
      this.get('store').queryRecord('reporting-tagging-export-request', { filter: filters }).then(function (stats) {
        _this.set('export', stats);
        _this.set('exportLoading', false);
        var url = _this.store.adapterFor('reporting-tagging-export').buildURL('reporting-tagging-export', null, null, 'queryRecord', null);
        url += '?api_key=' + _this.get('session.session.authenticated.access_token') + '&';
        url += stats.get('export_url').substr(1);
        _this.set('urlExport', url);
      });
    },

    fetchReport: function fetchReport() {
      debounce(this, this._fetchReport, 1200);
    },

    _searchUsers: function _searchUsers(name, resolve, reject) {
      this.get('store').query('admin-user', {
        filter: { name: name }
      }).then(function (users) {
        return resolve(users);
      }, reject);
    },


    actions: {
      selectDate: function selectDate(dateOption) {
        this.set('selectedDateOption', dateOption);
        this.set('startDateComputed', moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'));
        this.set('endDateComputed', moment().add(1, 'days').endOf('day').format('YYYY-MM-DD'));
      },
      clearSurveysSelection: function clearSurveysSelection() {
        this.set('surveys', []);
      },
      searchSurveys: function searchSurveys(term) {
        var regTerm = new RegExp(term.toLowerCase().split('').join('.*').replace(/[+?^${}()|[\]\\]/g, '\\$&'));
        return this.get('dataSorted').filter(function (survey) {
          return survey.get('search_value').toLowerCase().match(regTerm);
        });
      },
      searchUsers: function searchUsers(name) {
        var _this2 = this;

        return new RSVP.Promise(function (resolve, reject) {
          debounce(_this2, _this2._searchUsers, name, resolve, reject, 600);
        });
      },
      selectSurveys: function selectSurveys(surveys) {
        this.set('selectedSurveys', surveys);
        this.fetchReport();
      },
      selectLanguage: function selectLanguage(languages) {
        this.set('languages', languages);
        this.fetchReport();
      },
      selectUser: function selectUser(user) {
        this.set('user', user);
        this.fetchReport();
      },
      selectStat: function selectStat(stat) {
        this.set('stat_filter', stat);
        this.fetchReport();
      },
      changeView: function changeView(view) {
        this.set('actualView', view);
      }
    }
  });
});