define('due-backoffice/controllers/tagging-tool/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      RSVP = Ember.RSVP,
      computed = Ember.computed;
  exports.default = Controller.extend({
    verbatimTagging: [],
    tags: [],
    loading: false,
    zoneOptions: ['all', 'worldwide', 'china'],
    zoneFilter: 'all',
    search_term: '',

    dataSorting: ['untagged_count:desc'],
    dataSorted: Ember.computed.sort('model.surveys', 'dataSorting'),

    _searchSurvey: function _searchSurvey(term, resolve, reject) {
      this.store.query('survey', {
        filter: {
          search: term,
          auto_tagging_enabled: true,
          zone: { from: this.get('zoneFilter'), startDate: this.get('startDate'), endDate: this.get('endDate') }
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (surveys) {
        return resolve(surveys);
      }, reject);
    },


    startDateComputed: computed('startDate', {
      get: function get() {
        return moment.utc(this.get('startDate')).local().format('YYYY-MM-DD');
      },
      set: function set(_, value) {
        this.set('startDate', moment(value).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'));
        this.send('reFetchSurveys');
        return value;
      }
    }),

    endDateComputed: computed('endDate', {
      get: function get() {
        return moment.utc(this.get('endDate')).local().format('YYYY-MM-DD');
      },
      set: function set(_, value) {
        this.set('endDate', moment(value).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss'));
        this.send('reFetchSurveys');
        return value;
      }
    }),

    actions: {
      searchSurveys: function searchSurveys(term) {
        var _this = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this, _this._searchSurvey, term, resolve, reject, 600);
        });
      },
      reFetchSurveys: function reFetchSurveys(option) {
        var _this2 = this;

        this.set('model.surveys', []);
        this.set('dropdown_is_loading', true);
        this.set('zoneFilter', option);
        this.set('survey', null);
        this.set('verbatimTagging', []);
        this.set('tags', []);
        this._searchSurvey('', function (surveys) {
          _this2.set('dropdown_is_loading', false);
          _this2.set('model.surveys', surveys);
        });
      },
      selectSurvey: function selectSurvey(survey) {
        var _this3 = this;

        this.set('survey', survey);
        this.set('loading', true);
        this.set('verbatimTagging', []);
        this.set('tags', []);

        RSVP.all([this.get('store').query('tag', { filter: { survey: survey.id } }).then(function (tags) {
          return _this3.set('tags', tags);
        }), this.get('store').query('verbatim-tagging', {
          filter: {
            tagging: true,
            survey_id: survey.id,
            zone: this.get('zoneFilter'),
            completed_at: survey.get('tagging_start_date'),
            startDate: this.get('startDate'),
            endDate: this.get('endDate')
          },
          page: { number: 1, size: 200 }
        }).then(function (verbatimTagging) {
          return _this3.set('verbatimTagging', verbatimTagging);
        })]).then(function () {
          return _this3.set('loading', false);
        });
      }
    }
  });
});