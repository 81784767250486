define('due-backoffice/components/data-tool/feedback-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',
    classNames: ['feedback-line'],

    selectedFeedbackCompletedAtDate: computed('feedback.answer_completed_at', function () {
      if (this.get('feedback.answer_completed_at')) {
        return '' + moment(this.get('feedback.answer_completed_at')).format('L LT');
      }
      return '-';
    }).readOnly(),

    selectedFeedbackDispatchedAtDate: computed('feedback.dispatch_sent_at', function () {
      if (this.get('feedback.dispatch_sent_at')) {
        return '' + moment(this.get('feedback.dispatch_sent_at')).format('L LT');
      }
      return '-';
    }).readOnly(),

    selectedFeedbackCreatedAtDate: computed('feedback.created_at', function () {
      if (this.get('feedback.created_at')) {
        return '' + moment(this.get('feedback.created_at')).format('L LT');
      }
      return '-';
    }).readOnly()
  });
});