define('due-backoffice/models/question-category', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // ReadOnly attrs
    title: (0, _attr.default)({ read_only: true }),
    // Editables attrs
    questions: (0, _attr.default)(), // array
    surveys: (0, _attr.default)(), // array
    position: (0, _attr.default)('number')

    // RelationShips

    // Computeds

  });
});