define('due-backoffice/components/segment-values-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var oneWay = Ember.computed.oneWay;
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    classNames: ['segments-dropdown'],

    multiple: false,
    new_required: false,
    select_required: true,
    first_label: "Valeurs",

    actions: {
      removeAll: function removeAll() {
        this.set('selection', []);
      },
      removeValue: function removeValue(value) {
        this.get('selection').removeObject(value);
      },
      addValue: function addValue() {
        var value = this.get('new_value').trim();
        if (value) {
          if (value.includes("\n")) {
            if (this.get('multiple')) {
              this.set('selection', [].concat(_toConsumableArray(this.get('selection') || []), _toConsumableArray(value.split("\n"))).uniq());
            } else {
              this.set('selection', [value.split("\n")[0]]);
            }
          } else {
            if (this.get('multiple')) {
              this.set('selection', [].concat(_toConsumableArray(this.get('selection') || []), [value]).uniq());
            } else {
              this.set('selection', [value]);
            }
          }
        }
        this.set('new_value', '');
        this.$('.values-textarea').focus();
      }
    }

  });
});