define('due-backoffice/components/companies/segments/company-segment', ['exports', 'due-backoffice/templates/components/companies/segments/company-segment', 'due-backoffice/constants/icons'], function (exports, _companySegment, _icons) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    status: 'parent',
    layout: _companySegment.default,
    classNames: ['ui', 'segment', 'company-segment'],
    classNameBindings: ['childClassName'],
    childClassNames: "ui list",

    to_define: ['title', 'model'],

    init: function init() {
      this._super.apply(this, arguments);
      var caller = Object.getPrototypeOf(this)._debugContainerKey.split('/')[2];
      this.set('elementId', 'company-' + caller);
      if (!this.get('icon')) {
        this.set('icon', caller.split('-segment')[0].underscore());
      }
      if (!_icons.default[this.get('icon')]) {
        Ember.warn('company-segment: you should add ' + this.get('icon') + ' in app/constants/icons file');
      }
      var to_define = this.getProperties(this.get('to_define'));
      var index = 0;
      if ((index = Object.values(to_define).indexOf(undefined)) !== -1 || (index = Object.values(to_define).indexOf(null)) !== -1) {
        throw new Error('Error during ' + caller + ' initiation (in parent), child need to define ' + this.get('to_define')[index]);
      }
      this.set('partial', 'components/companies/segments/' + caller);
    }
  });
});