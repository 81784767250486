define('due-backoffice/routes/accounts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('currentUser.content.can_access_company')) {
        return true;
      }
      this.transitionTo('tagging-tool');
    },

    model: function model() {
      return {
        accounts: this.get('controller.model.accounts') || this.store.query('account', { page: { number: 1, size: 20 } }),
        admins: this.store.query('adminUser', { page: { number: 1, size: 20 } })
      };
    }
  });
});