define('due-backoffice/components/companies/segments/custom-stats-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({

    childClassNames: "ui large list",
    new_target: 'custom-stats.new',

    custom_stats: Ember.computed.filterBy('model.custom_stats', 'isNew', false),

    title: Ember.computed('model.customStatCount', function () {
      return 'Custom Stats (' + this.get('model.customStatCount') + ')';
    }),

    show_custom_stats: Ember.computed('model.show_custom_stats', {
      get: function get() {
        return this.get('model.show_custom_stats');
      },
      set: function set(_, value) {
        this.set('model.show_custom_stats', value);
        this.get('model').save();
        return value;
      }
    })

  });
});