define('due-backoffice/controllers/companies/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed,
      alias = Ember.computed.alias;
  exports.default = Controller.extend({

    error: [],
    languages: alias('model.languages'),
    selectedLanguage: computed('languages', function () {
      return this.get('languages.firstObject');
    }),

    actions: {
      updateLanguage: function updateLanguage(language) {
        this.set('selectedLanguage', language);
      },

      saveAccount: function saveAccount() {
        var _this = this;

        var company_creation = this.model.company_creation;
        company_creation.set('language_id', this.get('selectedLanguage.id'));
        company_creation.save().then(function (comp) {
          _this.transitionToRoute('accounts');
        }, function (err) {
          var err_array = [];
          err.errors.forEach(function (e) {
            err_array.push((e.title !== undefined ? e.title : e.source.pointer) + ': ' + e.detail);
          });
          _this.set('error', err_array);
        });
      }
    }
  });
});