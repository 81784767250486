define('due-backoffice/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    name: attr('string'),
    color: attr('string'),
    created_at: attr('date'),
    feedback_count: attr('number'),
    translations: attr(),
    company: belongsTo('company'),
    account: belongsTo('Account'),
    language: belongsTo('language'),
    surveys: hasMany('surveys'),
    not_assignable: attr('boolean'),
    due_exclusive: attr('boolean'),
    feeling_enabled: attr('boolean'),
    kind: attr()
  });
});