define('due-backoffice/routes/keyword-adjustments/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('currentUser.content.can_access_company')) {
        return true;
      }
      this.transitionTo('tagging-tool');
    },

    model: function model() {
      return this.store.query('keyword-adjustment', { page: { size: 10 } });
    }
  });
});