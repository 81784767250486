define('due-backoffice/components/companies/segments/accounts-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),

    saveAccounts: function saveAccounts() {
      this.get('model').save();
    },

    max_accounts_limit: Ember.computed('model.max_account', {
      get: function get() {
        return this.get('model.max_account');
      },
      set: function set(_, value) {
        if (parseInt(value, 10) >= 0) {
          this.set('model.max_account', value);
          this.set('debounceTimer', Ember.run.debounce(this, this.get('saveAccounts'), 1000));
        }
        return value;
      }
    }),

    new_target: 'accounts.new',

    title: Ember.computed('accounts.length', 'model.max_account', function () {
      if (this.get('model.max_account') !== 0 && this.get('accounts.length')) {
        return 'Comptes (' + this.get('accounts.length') + '/' + this.get('model.max_account') + ')';
      }
      return 'Comptes (' + this.get('accounts.length') + ')';
    }),

    accounts: Ember.computed.alias('model.accounts')

  });
});