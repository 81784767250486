define('due-backoffice/models/answer-set-segment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    feedback: (0, _relationships.belongsTo)('feedback'),
    segment: (0, _relationships.belongsTo)('segment'),
    value: (0, _attr.default)('string'),
    position: computed.alias('segment.position'),
    isHidden: computed('segment.name', function () {
      if (this.get('segment.name')) {
        return _.startsWith(this.get('segment.name'), '.');
      }
    })
  });
});