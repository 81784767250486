define('due-backoffice/components/companies/show/show-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',

    actions: {
      toggleFavourite: function toggleFavourite() {
        this.toggleProperty('model.is_favourite');
        this.get('model').save();
      }
    }

  });
});