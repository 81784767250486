define('due-backoffice/instance-initializers/i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'i18n',
    initialize: function initialize(app) {
      var calculateLocale = function calculateLocale() {
        var locale = app.lookup('cookie:main').getCookie('locale') || (window.navigator.languages ? window.navigator.languages[0] : null);
        locale = locale || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
        if (locale.indexOf('-') !== -1) {
          locale = locale.split('-')[0];
        }
        if (locale.indexOf('_') !== -1) {
          locale = locale.split('_')[0];
        }
        if (![/*'fr', */'en' /*, 'es', 'nl', 'th', 'ko', 'ja', 'zh', 'zht'*/].includes(locale)) {
          locale = 'en';
        }
        return locale;
      };
      app.lookup('service:i18n').set('locale', calculateLocale());
    }
  };
});