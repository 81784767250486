define('due-backoffice/helpers/short-number', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (number) {
    number = number[0];
    if (number < 1000) {
      return number;
    } else if (number < 100000) {
      return Math.round(number / 100) / 10 + 'k';
    } else if (number < 100000000) {
      return Math.round(number / 100000) / 10 + 'M';
    } else if (number < 100000000000) {
      return Math.round(number / 100000000) / 10 + 'B';
    } else if (number < 100000000000000) {
      return Math.round(number / 100000000000) / 10 + 'T';
    } else if (number >= 100000000000000) {
      return 'way too much';
    } else {
      return 'NaN';
    }
  });
});