define('due-backoffice/models/company-segment-value', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    feedback: _emberData.default.belongsTo('Feedback', { async: true }),
    value: _emberData.default.attr('string'),
    companies_segment_id: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string'),
    segment: _emberData.default.belongsTo('Segment', { async: true }),

    isHidden: computed('segment.name', function () {
      if (this.get('segment.name')) {
        return _.startsWith(this.get('segment.name'), '.');
      }
    }),

    position: computed.alias('segment.position')
  });
});