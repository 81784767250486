define('due-backoffice/routes/app', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Route.reopen({
    activate: function activate() {
      var result = this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      return result;
    }
  });

  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {});
});