define('due-backoffice/authorizers/rating', ['exports', 'ember-simple-auth/authorizers/oauth2-bearer'], function (exports, _oauth2Bearer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2Bearer.default.extend({
    currentUser: Ember.inject.service(),

    authorize: function authorize(data, callback) {
      this.get('currentUser.content.app_api_key');
      var _data = _.clone(data);
      _data.access_token = this.get('currentUser.content.back_office_api_key');
      return this._super(_data, callback);
    }
  });
});