define('due-backoffice/components/companies/segments/tags-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({

    store: Ember.inject.service(),
    title: 'tags',
    childClassNames: "ui large list",
    tag: null,
    new_tag: null,
    root_cause: null,
    new_root_cause: null,
    action_taken: null,
    new_action_taken: null,
    csv_upload: null,
    csvError: computed.alias('model.csvError'),
    csvSaved: computed.alias('model.csvSaved'),
    csvIsUploading: computed.alias('model.IsUploading'),

    canSaveName: Ember.computed('tag', 'new_tag', 'root_cause', 'new_root_cause', 'action_taken', 'new_action_taken', function () {
      return {
        tag: this.get('tag') !== null && this.get('new_tag') !== null && this.get('tag.name') !== this.get('new_tag.name') ? '' : 'disabled',
        root_cause: this.get('root_cause') !== null && this.get('new_root_cause') !== null && this.get('root_cause.name') !== this.get('new_root_cause.name') ? '' : 'disabled',
        action_taken: this.get('action_taken') !== null && this.get('new_action_taken') !== null && this.get('action_taken.name') !== this.get('new_action_taken.name') ? '' : 'disabled'
      };
    }),

    init: function init() {
      this._super();
      var csv = this.get('store').createRecord('CreateTagCsvUpload');
      this.set('csv_upload', csv);
    },
    initCsv: function initCsv() {
      var csv = this.get('csvToUpload');
      if (!csv) {
        return RSVP.reject('empty_file');
      }
      var match = csv.match('^data:(.*?);base64,(.*)$');
      this.set('csv_upload.content', match[2]);
      return this.sendCSV();
    },
    sendCSV: function sendCSV() {
      var _this = this;

      this.set('csvError', null);
      this.set('csvSaved', null);
      this.set('csvIsUploading', true);
      this.set('csv_upload.type', 'base64/');
      return this.get('csv_upload').save().then(function (response) {
        _this.set('csv_upload', response);
        _this.set('csvSaved', true);
        return response;
      }).catch(function (response) {
        _this.set('csvError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>"));
        if (_this.get('csvToUpload')) {
          var input = $('#tag-csv-input')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      }).finally(function () {
        _this.set('csvIsUploading', false);
      });
    },


    isSaving: computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    handleError: function handleError(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('csvError', errorText);
      this.set('csvIsUploading', false);
    },


    actions: {
      saveName: function saveName(kind) {
        var _this2 = this;

        var tag_name_change = this.get('store').createRecord('tag-name-change', {
          company_id: this.get('model.id'),
          old_tag_name: this.get(kind + '.name'),
          new_tag_name: this.get('new_' + kind + '.name')
        });
        tag_name_change.save().then(function (_record) {
          _this2.set(kind, null);
          _this2.set('new_' + kind, null);
        });
      },
      handleLoad: function handleLoad(deferred) {
        var _this3 = this;

        this.initCsv().then(function () {
          deferred.resolve();
        }).catch(function (error) {
          deferred.reject(error);
        });
        deferred.promise.catch(function (error) {
          _this3.handleError(error);
        });
      },
      saveModify: function saveModify() {
        this.get('store').unloadAll('CreateTag');
        var modifier = this.get('store').createRecord('CreateTag');
        modifier.uploaded_csv_data_id = this.get('csv_upload.uploaded_csv_data_id');
        modifier.company_id = this.get('model.id');
        modifier.save();
        this.get('csv_upload').unloadRecord();
        var csv = this.get('store').createRecord('CreateTagCsvUpload');
        this.set('csv_upload', csv);
      }
    }
  });
});