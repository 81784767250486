define('due-backoffice/components/subscription-plan/new-form', ['exports', 'due-backoffice/models/segment'], function (exports, _segment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    taxes: "HT",

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.initForm();
    },
    willDestroyElement: function willDestroyElement() {
      $('#new-plan-modal.ui.modal').remove();
    },
    initForm: function initForm() {
      var _this = this;

      var fields = {
        c: {
          identifier: "prix_ht",
          rules: [{
            type: 'empty',
            prompt: "Merci d'indiquer un montant en euro"
          }]
        }
      };
      $('.subscription.ui.form').form({
        onSuccess: function onSuccess() {
          _this.$('#new-plan-modal.ui.modal').modal({
            onApprove: function onApprove() {
              return _this.saveAndCoToCompany();
            }
          }).modal('show');
          return false;
        },
        fields: fields
      });
      this.tvaAddRule();
    },
    saveAndCoToCompany: function saveAndCoToCompany() {
      var _this2 = this;

      this.get('store').findAll('subscription_plan', { reload: true }).then(function (sps) {
        var name = _this2.get('subscriptionName');
        var plan = sps.findBy('name', name);
        if (plan) {
          _this2.set('model.company.subscription_plan', plan);
          _this2.get('model.company').save().then(function () {
            _this2.backToCompany(plan);
          });
        } else {
          _this2.set('model.name', name);
          _this2.set('model.duty_free_amount', _this2.get('dutyFreeAmount'));
          _this2.set('model.amount', _this2.get('subscriptionValue'));
          _this2.get('model').save().then(function (new_plan) {
            _this2.backToCompany(new_plan);
          });
        }
      });
    },
    tvaAddRule: function tvaAddRule() {
      $('.subscription.ui.form').form('add fields', {
        tva_rate: {
          identifier: "tva_rate",
          depends: "taxes",
          rules: [{
            type: 'empty',
            prompt: "Merci d'indiquer un taux de TVA"
          }]
        }
      });
    },


    validateRate: Ember.observer('taxes', function () {
      if (this.get('taxes') === "TTC") {
        this.set('model.taxes_rate', 0);
        $('.subscription.ui.form').form('remove field', 'tva_rate');
      } else {
        this.tvaAddRule();
      }
    }),

    subscriptionName: Ember.computed('taxes', 'dutyFreeAmount', 'model.taxes_rate', function () {
      var taxes = this.get('taxes');
      return '' + (parseFloat(this.get('dutyFreeAmount')) || "...") + taxes + (taxes === "HT" ? this.get('model.taxes_rate') + 'TVA' : '');
    }),

    subscriptionValue: Ember.computed('taxes', 'model.taxes_rate', 'dutyFreeAmount', function () {
      var result = 0;
      if (!this.get('dutyFreeAmount')) {
        result = 0;
      } else if (this.get('taxes') === "HT") {
        result = Math.round(this.get('dutyFreeAmount') * (1 + this.get('model.taxes_rate') / 100.0) * 100) / 100.0;
      } else {
        result = this.get('dutyFreeAmount') || 0;
      }
      return parseFloat(result).toFixed(2);
    }),

    backToCompany: function backToCompany() {
      var _this3 = this;

      var plan = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      if (this.get('model.isNew')) {
        this.get('model').deleteRecord();
      }
      if (plan) {
        this.set('model.company.subscription_plan', plan);
        this.get('model.company').save().then(function () {
          _this3.get('router').transitionTo('companies.show', _this3.get('model.company.id'));
        });
      } else {
        this.get('router').transitionTo('companies.show', this.get('model.company.id'));
      }
    },


    actions: {
      backToCompany: function backToCompany() {
        this.backToCompany();
      },
      setTaxes: function setTaxes(value) {
        this.set('taxes', value);
      }
    }
  });
});