define('due-backoffice/components/default-color-picker', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      on = Ember.on;
  exports.default = Ember.Component.extend({
    tagName: '',
    isChrome: computed(function () {
      return (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && navigator.platform.match('MacIntel')
      );
    }),

    defaultValueSetter: on("init", observer('defaultValue', function () {
      if (!this.get('target')) {
        var defaultValue = this.get('defaultValue');
        this.set('target', defaultValue);
        this.attrs.target.update(defaultValue);
      }
    }))
  });
});