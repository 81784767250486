define('due-backoffice/models/custom-setting', ['exports', 'ember-data-model-fragments', 'ember-data'], function (exports, _emberDataModelFragments, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _emberDataModelFragments.default.Fragment.extend({
    short_code: attr('string'),
    service_plan: attr('string'),
    token: attr('string')
  });
});