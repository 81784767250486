define('due-backoffice/routes/segment-pickers/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    deactivate: function deactivate() {
      if (this.get('controller.model.id')) {
        this.get('controller.model').rollbackAttributes();
      }
    }
  });
});