define('due-backoffice/components/due-paginator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    pageChanged: null,

    classNames: ['due-paginator', 'ui segment basic center aligned'],

    pendingClass: computed('modelIsPending', function () {
      if (this.get('modelIsPending')) {
        return 'disabled';
      }
      return '';
    }),

    pages: computed('total_pages', 'current_page', function () {
      if (this.get('modelIsPending')) {
        return this.get('_pages');
      }
      var total_pages = this.get('total_pages');
      var current_page = this.get('current_page');
      this.set('_total_pages', total_pages);
      if (total_pages === 0) {
        return;
      }
      var start_page = _.max([1, current_page - 1]);
      var end_page = _.min([current_page + 1, total_pages]);
      var pages = [];
      if (total_pages <= 4) {
        for (var i = 1; i <= total_pages; i++) {
          pages.push({ class: i === current_page ? "active" : '', number: i });
        }
      } else {
        if (start_page !== 1) {
          pages.push({ class: 'disabled', number: '...' });
        }
        if (total_pages - start_page < 3) {
          for (var _i = total_pages - 3; _i < start_page; _i++) {
            pages.push({ class: _i === current_page ? "active" : '', number: _i });
          }
        }
        for (var _i2 = start_page; _i2 <= end_page; _i2++) {
          pages.push({ class: _i2 === current_page ? "active" : '', number: _i2 });
        }
        if (end_page <= 3) {
          for (var _i3 = end_page + 1; _i3 <= 4; _i3++) {
            pages.push({ class: _i3 === current_page ? "active" : '', number: _i3 });
          }
        }
        if (current_page + 1 < total_pages) {
          pages.push({ class: 'disabled', number: '...' });
        }
      }
      this.set('_pages', pages);
      return pages;
    }),

    inputValueChanged: Ember.observer('inputValue', function () {
      Ember.run.debounce(this, this._setNewInput, parseInt(this.get('inputValue')), 600);
    }),

    _setNewInput: function _setNewInput(value) {
      if (isNaN(value)) {
        return;
      }
      if (value < 1) {
        this.set('current_page', 1);
      } else {
        this.set('current_page', _.min([this.get('total_pages'), value]));
      }
    },


    actions: {
      changePageNumber: function changePageNumber(page_number) {
        this.set('_current_page', page_number);
        if (this.get('pageChanged') !== null) {
          this.get('pageChanged')(page_number);
        } else {
          if (page_number >= 1) {
            this.set('current_page', page_number);
          }
        }
      },
      nextPage: function nextPage() {
        this.send('changePageNumber', _.min([this.get('current_page') + 1, this.get('total_pages')]));
      },
      previousPage: function previousPage() {
        this.send('changePageNumber', _.max([this.get('current_page') - 1, 1]));
      },
      lastPage: function lastPage() {
        this.send('changePageNumber', this.get('total_pages'));
      },
      firstPage: function firstPage() {
        this.send('changePageNumber', 1);
      }
    }
  });
});