define('due-backoffice/components/custom-stat/new-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      setDiff = _Ember$computed.setDiff,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    title_duplicate: false,
    classNames: ['custom-stat', 'new-form'],
    selectedLanguages: alias('model.selected_languages'),
    dashboardLanguages: null,
    searchTagValue: '',

    didInsertElement: function didInsertElement() {
      var _this = this;

      var ordered_segments = this.get('model.segments');
      this.loadSurveyDp();
      this._super.apply(this, arguments);
      if (ordered_segments.get('length')) {
        this.get('store').query('segment', { filter: { name: this.get('model.segments') } }).then(function (segments) {
          return _this.set('_segments', segments.toArray().sort(function (a, b) {
            return ordered_segments.indexOf(a.get('name')) - ordered_segments.indexOf(b.get('name'));
          }));
        });
      }
      if (this.get('model.company.id')) {
        this.get('store').query('dashboard_language', { filter: { company_id: this.get('model.company.id') } }).then(function (dashboardLanguages) {
          return _this.set('dashboardLanguages', dashboardLanguages);
        });
        this.get('store').query('tag', { filter: { company_id: this.get('model.company.id') } }).then(function (tags) {
          return _this.set('allTags', tags);
        });
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.initForm();
    },
    willDestroyElement: function willDestroyElement() {
      $('#new-custom-stat-modal.ui.modal').remove();
    },


    availableLanguages: computed('dashboardLanguages', 'selectedLanguages', function () {
      var dashboardLanguages = this.get('dashboardLanguages') || [];
      var selectedLanguages = this.get('selectedLanguages') || [];

      return dashboardLanguages.map(function (dashboardLanguage) {
        return {
          'tag': dashboardLanguage.get('language.tag').toLowerCase(),
          'name': dashboardLanguage.get('language.name'),
          'selected': selectedLanguages.includes(dashboardLanguage.get('language.tag').toLowerCase())
        };
      });
    }),

    initForm: function initForm() {
      var _this2 = this;

      var fields = {
        nom: {
          identifier: "nom",
          rules: [{
            type: 'empty',
            prompt: "Merci d'indiquer un nom pour cette custom stat"
          }]
        },
        dueOrderedSegments: {
          identifier: "dueOrderedSegments",
          rules: [{
            type: 'minCount[1]',
            prompt: "Merci d'indiquer au moins un segment pour cette custom stat"
          }]
        },
        atLeastOneLanguage: {
          identifier: "atLeastOneLanguage",
          rules: [{
            type: 'checked',
            prompt: "Merci d'indiquer au moins un language pour cette custom stat"
          }]
        }
      };
      var availableLanguages = this.get('availableLanguages');
      this.get('questionGroups').then(function (qgs) {
        setTimeout(function () {
          qgs.forEach(function (qg, index) {
            var depends = 'questionChoosed-' + qg.get('id');
            availableLanguages.forEach(function (availableLanguage) {
              var identifier = 'title_' + availableLanguage.tag + '_' + qg.get('id');
              fields[identifier] = {
                identifier: identifier,
                depends: depends,
                rules: [{
                  type: 'empty',
                  prompt: 'Merci de remplir le ' + availableLanguage.tag + ' pour la categorie de question ' + (index + 1)
                }]
              };
            });
            var identifier = 'position-' + qg.get('id');
            fields[identifier] = {
              identifier: identifier,
              depends: depends,
              rules: [{
                type: 'empty',
                prompt: 'Merci d\'indiquer une position pour la categorie de question ' + (index + 1)
              }]
            };
          });
          $('.ui.form').form({
            onSuccess: function onSuccess() {
              $('#new-custom-stat-modal.ui.modal').modal({
                onApprove: function onApprove() {
                  return _this2.submitCustomStat();
                }
              }).modal('show');
              return false;
            },
            fields: fields
          });
        }, 0);
      });
    },
    submitCustomStat: function submitCustomStat() {
      var _this3 = this;

      var availableLanguages = this.get('availableLanguages');
      this.set('title_duplicate', false);
      availableLanguages.forEach(function (availableLanguage) {
        if (!_this3.get('choosedLanguages')[availableLanguage.tag]) {
          _this3.get('model.questions').forEach(function (question) {
            return delete question[availableLanguage.tag];
          });
        }
        var titles = _this3.get('model.questions').map(function (question) {
          return question[availableLanguage.tag];
        });
        if (titles.filter(function (item, index) {
          return titles.indexOf(item) != index;
        }).filter(function (item) {
          return item !== undefined;
        }).length > 0) {
          _this3.set('title_duplicate', true);
          return;
        }
      });
      this.set('model.segments', this.get('_segments').mapBy('name'));
      if (!this.get('title_duplicate')) {
        this.get('model').save().then(function () {
          _this3.backToCompany();
        });
      }
    },
    backToCompany: function backToCompany() {
      if (this.get('model.isNew')) {
        this.get('model').deleteRecord();
      }
      this.get('router').transitionTo('companies.show', this.get('model.company.id'));
    },


    choosedLanguages: computed('availableLanguages.@each.selected', function () {
      var availableLanguages = this.get('availableLanguages') || [];
      var choosedLanguages = {};
      availableLanguages.forEach(function (availableLanguage) {
        choosedLanguages[availableLanguage.tag] = availableLanguage.selected;
      });
      return choosedLanguages;
    }),

    filteredQuestionGroups: computed('questionGroups', 'model.surveys.[]', function () {
      var _this4 = this;

      var surveys = (this.get('model.surveys') || []).mapBy('id');
      if (surveys.get('length') == 0) {
        return this.get('questionGroups');
      } else {
        this.get('questionGroups').filter(function (qg) {
          return _.intersection(qg.get('surveys'), surveys).length == 0;
        }).forEach(function (qg) {
          _this4.get('model.questions').removeObject(qg);
        });
        return this.get('questionGroups').filter(function (qg) {
          return _.intersection(qg.get('surveys'), surveys).length > 0;
        });
      }
    }),

    questionGroups: computed(function () {
      return this.get('store').query('question-category', { filter: { company_id: this.get('model.company.id') } });
    }),

    loadSurveyDp: function loadSurveyDp() {
      var _this5 = this;

      this.$('#surveydd').dropdown({
        fullTextSearch: true,
        placeholder: 'Surveys'
      });
      this.get('store').query('survey', { filter: { company_id: this.get('model.company.id'), status: 'live' } }).then(function (surveys) {
        var values = surveys.map(function (s) {
          return {
            value: s.get('name'),
            name: s.get('name'),
            selected: !!_this5.get('model.surveys').findBy('id', s.get('id'))
          };
        });
        _this5.$('#surveydd').dropdown('change values', values).dropdown({
          onChange: function onChange(value, text, $selectedItem) {
            var currents = (_this5.get('model.surveys') || []).toArray();
            var survey = _this5.get('store').peekAll('survey').findBy('name', text);
            if (value[0]) {
              currents.push(survey);
              _this5.set('model.surveys', currents);
            } else {
              _this5.set('model.surveys', currents.reject(function (a) {
                return a.get('id') === survey.get('id');
              }));
            }
          }
        });
        _this5.$('.due-surveys .dropdown').removeClass('loading');
      });
    },


    showResolutionRates: computed('model.show_resolution_rates', {
      get: function get() {
        return this.get('model.show_resolution_rates');
      },
      set: function set(_, value) {
        this.set('model.show_resolution_rates', value);
        this.get('model').save();
        return value;
      }
    }),

    showDetractorResolutionRates: computed('model.show_detractor_resolution_rates', {
      get: function get() {
        return this.get('model.show_detractor_resolution_rates');
      },
      set: function set(_, value) {
        this.set('model.show_detractor_resolution_rates', value);
        this.get('model').save();
        return value;
      }
    }),

    showRatingPosition: computed('model.show_rating_position', {
      get: function get() {
        return this.get('model.show_rating_position');
      },
      set: function set(_, value) {
        this.set('model.show_rating_position', value);
        this.get('model').save();
        return value;
      }
    }),

    showDetractorGlobal: computed('model.show_detractor_global', {
      get: function get() {
        return this.get('model.show_detractor_global');
      },
      set: function set(_, value) {
        this.set('model.show_detractor_global', value);
        this.get('model').save();
        return value;
      }
    }),

    hideNps: computed('model.hide_nps', {
      get: function get() {
        return this.get('model.hide_nps');
      },
      set: function set(_, value) {
        this.set('model.hide_nps', value);
        this.get('model').save();
        return value;
      }
    }),

    hideClosedQuestions: computed('model.hide_closed_questions', {
      get: function get() {
        return this.get('model.hide_closed_questions');
      },
      set: function set(_, value) {
        this.set('model.hide_closed_questions', value);
        this.get('model').save();
        return value;
      }
    }),

    showPassiveGlobal: computed('model.show_passive_global', {
      get: function get() {
        return this.get('model.show_passive_global');
      },
      set: function set(_, value) {
        this.set('model.show_passive_global', value);
        this.get('model').save();
        return value;
      }
    }),

    showPromoterGlobal: computed('model.show_promoter_global', {
      get: function get() {
        return this.get('model.show_promoter_global');
      },
      set: function set(_, value) {
        this.set('model.show_promoter_global', value);
        this.get('model').save();
        return value;
      }
    }),

    showNonResolvedRates: computed('model.show_non_resolved_rates', {
      get: function get() {
        return this.get('model.show_non_resolved_rates');
      },
      set: function set(_, value) {
        this.set('model.show_non_resolved_rates', value);
        this.get('model').save();
        return value;
      }
    }),

    showTaggedNumber: computed('model.show_tagged_number', {
      get: function get() {
        return this.get('model.show_tagged_number');
      },
      set: function set(_, value) {
        this.set('model.show_tagged_number', value);
        this.get('model').save();
        return value;
      }
    }),

    showNonTaggedRates: computed('model.show_non_tagged_rates', {
      get: function get() {
        return this.get('model.show_non_tagged_rates');
      },
      set: function set(_, value) {
        this.set('model.show_non_tagged_rates', value);
        this.get('model').save();
        return value;
      }
    }),

    showTagsPositive: computed('model.show_tags_positive', {
      get: function get() {
        return this.get('model.show_tags_positive');
      },
      set: function set(_, value) {
        this.set('model.show_tags_positive', value);
        this.get('model').save();
        return value;
      }
    }),

    showTagsNeutral: computed('model.show_tags_neutral', {
      get: function get() {
        return this.get('model.show_tags_neutral');
      },
      set: function set(_, value) {
        this.set('model.show_tags_neutral', value);
        this.get('model').save();
        return value;
      }
    }),

    showTagsNegative: computed('model.show_tags_negative', {
      get: function get() {
        return this.get('model.show_tags_negative');
      },
      set: function set(_, value) {
        this.set('model.show_tags_negative', value);
        this.get('model').save();
        return value;
      }
    }),

    showTagsWithoutFeeling: computed('model.show_tags_without_feeling', {
      get: function get() {
        return this.get('model.show_tags_without_feeling');
      },
      set: function set(_, value) {
        this.set('model.show_tags_without_feeling', value);
        this.get('model').save();
        return value;
      }
    }),

    tags: computed('allTags', 'searchTagValue', function () {
      var searchValue = this.get('searchTagValue').toLowerCase();
      var tags = searchValue.length === 0 ? this.get('allTags') || [] : this.get('allTags').filter(function (t) {
        return t.get('name').toLowerCase().includes(searchValue);
      });
      return tags.map(function (t) {
        return t.get('name');
      });
    }),

    actions: {
      searchTag: function searchTag(term) {
        this.set('searchTagValue', term);
      },
      selectTag: function selectTag(selectedTags) {
        this.set('model.tags', selectedTags);
      },
      backToCompany: function backToCompany() {
        this.backToCompany();
      },
      chooseQuestion: function chooseQuestion(question, choosed) {
        if (choosed) {
          var dup_question = this.get('model.questions').findBy('id', question.id);
          if (dup_question) {
            this.get('model.questions').removeObject(dup_question);
          }
          this.get('model.questions').pushObject(question);
        } else {
          this.get('model.questions').removeObject(question);
        }
      },
      selectLanguage: function selectLanguage(language) {
        var selectedLanguages = this.get('selectedLanguages') || [];
        this.set('choosedLanguages.' + language.tag, !this.get('choosedLanguages.' + language.tag));
        if (selectedLanguages.includes(language.tag)) {
          selectedLanguages.splice(selectedLanguages.indexOf(language.tag), 1);
        } else {
          selectedLanguages.push(language.tag);
        }
        this.set('selectedLanguages', selectedLanguages);
        this.notifyPropertyChange('selectedLanguages');
      }
    }

  });
});