define('due-backoffice/routes/rating-scale/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.createRecord('rating-scale', {
        survey: this.modelFor('surveys'),
        rating_scale_type: "custom",
        scale: 5,
        is_nps: false
      });
    }
  });
});