define('due-backoffice/models/segment-picker-legend', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // ReadOnly attrs

    // Editables attrs
    text: _emberData.default.attr('string'),

    // RelationShips
    language: _emberData.default.belongsTo('language'),
    segment_picker: _emberData.default.belongsTo('segment-picker')

    // Computeds

  });
});