define('due-backoffice/components/companies/segments/custom-email-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = _companySegment.default.extend({

    title: 'Personnalisation du DNS',
    childClassNames: "ui large list",

    custom_sending_local: Ember.computed('model.custom_sending_local', {
      get: function get() {
        return this.get('model.custom_sending_local');
      },
      set: function set(_, value) {
        this.set('model.custom_sending_local', value);
        return value;
      }
    }),

    custom_sending_domain: Ember.computed('model.custom_sending_domain', {
      get: function get() {
        return this.get('model.custom_sending_domain');
      },
      set: function set(_, value) {
        this.set('model.custom_sending_domain', value);
        return value;
      }
    }),

    custom_bounce_domain: Ember.computed('model.custom_bounce_domain', {
      get: function get() {
        return this.get('model.custom_bounce_domain');
      },
      set: function set(_, value) {
        this.set('model.custom_bounce_domain', value);
        return value;
      }
    }),

    canSaveEmail: Ember.computed('model.hasDirtyAttributes', 'custom_sending_domain', 'custom_sending_local', function () {
      var changedBelongsTo = this.get('model').changedAttributes();
      if ((changedBelongsTo.custom_sending_domain || changedBelongsTo.custom_sending_local) && isEmpty(this.get('model.custom_sending_domain')) == isEmpty(this.get('model.custom_sending_local'))) {
        return;
      }
      return 'disabled';
    }),

    canSaveBounceDomain: Ember.computed('model.hasDirtyAttributes', 'custom_bounce_domain', function () {
      var changedBelongsTo = this.get('model').changedAttributes();
      if (changedBelongsTo.custom_bounce_domain) {
        return;
      }
      return 'disabled';
    }),

    isSaving: Ember.computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    actions: {
      saveEmail: function saveEmail() {
        var _this = this;

        this.get('model').save().then(function (a) {
          return _this.notifyPropertyChange('custom_sending_domain');
        });
      },
      saveBounceDomain: function saveBounceDomain() {
        var _this2 = this;

        this.get('model').save().then(function (a) {
          return _this2.notifyPropertyChange('custom_bounce_domain');
        });
      }
    }

  });
});