define('due-backoffice/routes/reporting-tagging/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        surveys: this.store.query('survey', { filter: { auto_tagging_enabled: true } }),
        users: this.store.findAll('admin-user'),
        languages: this.store.findAll('language'),
        tags: this.store.findAll('tag')
      });
    }
  });
});