define('due-backoffice/models/segment', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    name: (0, _attr.default)('string'),
    position: (0, _attr.default)('number'),
    company_segment_values: (0, _relationships.hasMany)('company-segment-value')
  }).reopenClass({
    sort: function sort(a, b) {
      if (a.get('position') === b.get('position')) {
        return a.get('name') < b.get('name');
      } else {
        return a.get('position') < b.get('position');
      }
    }
  });
});