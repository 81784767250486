define('due-backoffice/components/companies/segments/mapping-table-segment', ['exports', 'due-backoffice/config/environment', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _environment, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    /* Services */
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    mappingConverter: Ember.inject.service(),
    i18n: Ember.inject.service(),

    /* Private Props */
    SHEET_NAME: 'Mapping Table',
    selectedSurveys: [],
    loading: true,
    xlsxError: '',

    saveAccounts: function saveAccounts() {
      this.get('model').save();
    },

    accounts: Ember.computed.alias('model.accounts'),
    surveys: Ember.computed.alias('model.surveys'),
    title: 'Mapping Table',

    init: function init() {
      var _this = this;

      this.get('store').query('company-mapping-table', { filter: { company_id: this.get('model.id') } }).then(function (tables) {
        if (tables.toArray().length > 0) {
          _this.set('mappingTable', tables.get('firstObject'));
        }
        _this.set('loading', false);
      });
      this._super.apply(this, arguments);
    },


    getLastUpdated: Ember.computed('mappingTable', function () {
      var lastUpdate = this.get('mappingTable.updated_at');
      return lastUpdate ? moment(lastUpdate).format('YYYY/MM/DD') : '-';
    }),

    getFilename: Ember.computed('mappingTable.filename', function () {
      var name = this.get('mappingTable.filename') || this.get('filename') || '-';
      return name.length > 20 ? '...' + name.slice(name.length - 20, name.length) : name;
    }),

    getUserName: Ember.computed('mappingTable.admin_user', function () {
      var admin_user = this.get('mappingTable.admin_user');
      return !admin_user ? '-' : admin_user.get('first_name') + '  ' + admin_user.get('last_name');
    }),

    noMappingTableUploaded: Ember.computed('mappingTable', function () {
      return this.get('mappingTable') === undefined;
    }),

    displayErrorMsg: Ember.computed('xlsxError', function () {
      return this.get('xlsxError').length > 0;
    }),

    _converterJsonToXlsx: function _converterJsonToXlsx() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.get('mappingConverter').json_xlsx(_this2.get('mappingTable.content'), _this2.get('mappingTable.filename'), _this2.get('SHEET_NAME')).then(function (response) {
          resolve(response.get('content'));
        }).catch(function (err) {
          return reject(err);
        });
      });
    },
    _converterXlsxToJson: function _converterXlsxToJson() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.get('mappingConverter').xlsx_json(_this3.get('xlsxToUpload'), _this3.get('filename'), true).then(function (response) {
          resolve(response.get('content'));
        }).catch(function (err) {
          return reject(err);
        });
      });
    },
    _download: function _download(path, filename) {
      var url = _environment.default.EmberENV.DEFAULT_SCHEME + '://' + _environment.default.EmberENV.API_HOST + '/admin_api/v1/mapping-converters?';
      url += 'api_key=' + this.get('session.session.authenticated.access_token') + '&path=' + path + '&filename=' + filename;
      window.open(url, '_blank');
      this.set('loading', false);
    },
    _saveMappingTable: function _saveMappingTable(json) {
      var _this4 = this;

      var mappingTable = this.get('mappingTable') || this.get('store').createRecord('CompanyMappingTable');
      mappingTable.set('content', json);
      mappingTable.set('company_id', this.get('model.id'));
      mappingTable.set('filename', this.get('filename') || this.get('mappingTable.filename'));
      return mappingTable.save().then(function (mappingTable) {
        _this4.set('mappingTable', mappingTable);
        _this4.set('loading', false);
        _this4.notifyPropertyChange('mappingTable');
      });
    },
    _handleError: function _handleError(error) {
      var i18n = this.get('i18n');
      var msg = '';
      if (error.errors) {
        msg = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (msg.match(/.*DOCTYPE html.*/)) {
          msg = i18n ? i18n.t('errors.unkown_csv_error') : error;
        }
      } else {
        msg = i18n ? i18n.t('errors.' + error) : error;
      }
      this.set('loading', false);
      this.set('xlsxError', msg);
    },
    _handleChanged: function _handleChanged(newTable) {
      var _this5 = this;

      var saveThis = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (_this5.get('model.managed_segments') === true) {
          _this5.get('store').query('companies-segment', { filter: { names: newTable.headers, company_id: _this5.get('model.id') } }).then(function (existingSegs) {
            if (existingSegs.toArray().length !== newTable.headers.length) {
              return reject(newTable.headers.filter(function (header) {
                return existingSegs.find(function (existingSeg) {
                  return existingSeg.get('name') === header;
                }) === undefined;
              }));
            }
            resolve(saveThis._buildHeaderDiff(newTable, saveThis.get('mappingTable')));
          });
        } else {
          resolve(saveThis._buildHeaderDiff(newTable, saveThis.get('mappingTable')));
        }
      });
    },
    _buildHeaderDiff: function _buildHeaderDiff(newTable, oldTable) {
      if (!oldTable) return null;
      var oldHeaders = oldTable.get('content.headers');
      var newHeaders = newTable.headers;
      return {
        newTabHeaders: newHeaders.filter(function (newHeader) {
          return !oldHeaders.includes(newHeader);
        }),
        oldTabHeaders: oldHeaders.filter(function (oldHeader) {
          return !newHeaders.includes(oldHeader);
        })
      };
    },
    _managedSegmentError: function _managedSegmentError(missingSegments) {
      alert('\u26A0\uFE0F SEGMENTS MANQUANTS\n\n' + missingSegments.map(function (seg) {
        return '\u25C9 ' + seg + '\n';
      }).join('') + '\nCette soci\xE9t\xE9 est en segment manag\xE9 veuillez cr\xE9er les segments avant d\'importer votre table.');
    },
    _changelog: function _changelog(newHeaders, oldHeaders) {
      alert('CHANGELOG\n\n' + newHeaders.map(function (header) {
        return '\uD83C\uDD95' + header + '\n';
      }).join('') + '\n' + oldHeaders.map(function (header) {
        return '\uD83D\uDDD1\uFE0F' + header + '\n';
      }));
    },


    actions: {
      import: function _import(loaderPromise) {
        var _this6 = this;

        this.set('loading', true);
        this.set('xlsxError', '');
        loaderPromise.promise.catch(function (err) {
          return _this6._uploadFailed(err);
        });
        this._converterXlsxToJson(loaderPromise).then(function (newTable) {
          loaderPromise.resolve();
          _this6._handleChanged(newTable).then(function (header) {
            if (header) _this6._changelog(header.newTabHeaders, header.oldTabHeaders);
            _this6._saveMappingTable(newTable);
          }).catch(function (missingSegments) {
            return _this6._managedSegmentError(missingSegments);
          }).finally(function () {
            return _this6.set('loading', false);
          });
        }).catch(function (err) {
          return _this6._handleError(err);
        });
      },
      export: function _export() {
        var _this7 = this;

        this.set('loading', true);
        this.set('xlsxError', '');
        this._converterJsonToXlsx().then(function (body) {
          return _this7._download(body.path, body.filename);
        }).catch(function (err) {
          return _this7._handleError(err);
        });
      },
      setFilename: function setFilename(filename) {
        this.set('filename', filename);
      },
      copyId: function copyId() {
        var mappingTable = this.get('mappingTable');
        if (mappingTable) {
          navigator.clipboard.writeText(this.get('mappingTable.id'));
          alert('Id copied !');
        }
      }
    }
  });
});