define('due-backoffice/models/company-integration', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // ReadOnly attrs
    name: attr('string', { readOnly: true }),
    logo_url: attr('string', { readOnly: true }),
    is_active: attr('boolean', { readOnly: true }),
    completed: attr('boolean', { readOnly: true }),

    // Editables attrs

    // RelationShips
    company: belongsTo('company'),

    // Computeds
    icon: Ember.computed('is_active', function () {
      return this.get('is_active') ? 'active_integration' : 'inactive_integration';
    })

  });
});