define('due-backoffice/models/language', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // ReadOnly attrs

    // Editables attrs
    tag: (0, _attr.default)(),
    name: (0, _attr.default)()

    // RelationShips

    // Computeds

  });
});