define('due-backoffice/components/bo-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    classNames: ['bo-header'],
    session: Ember.inject.service('session'),
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),

    currentCat: Ember.on('init', Ember.computed('routing.router.currentRouteName', function () {
      var route = this.get('routing.router.currentRouteName');
      return route.split('.')[0];
    })),

    didInsertElement: function didInsertElement() {
      $(".ui.popable").popup({
        delay: {
          show: 500
        }
      });
      $(".ui.popable-logout").popup({
        delay: {
          show: 0
        }
      });
    },


    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }

  });
});