define('due-backoffice/components/segment-picker/new-form', ['exports', 'due-backoffice/models/segment', 'due-backoffice/utils/rotate'], function (exports, _segment, _rotate) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    selected_languages: [],
    values: [],
    client_consent: false,
    false_client_consent: false,
    close_ended_question: true,
    optional_segment: false,
    macro_position: 'beginning',
    position_options: ['beginning', 'end'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setEditValues();
    },
    setEditValues: function setEditValues() {
      var _this = this;

      if (this.get('model.id')) {
        this.set('global_load', true);
        Ember.RSVP.all([this.get('model.segment_picker_legends'), this.get('model.segment_picker_options'), this.get('model.segment'), this.get('model.survey'), this.get('languages'), this.get('model.close_ended_question'), this.get('model.optional_segment'), this.get('macro_position')]).then(function () {
          _this.set('values', _this.get('model.values'));
          _this.set('close_ended_question', _this.get('model.close_ended_question'));
          _this.set('optional_segment', _this.get('model.optional_segment'));
          _this.set('macro_position', _this.get('model.macro_position'));
          _this.set('client_consent', _this.get('model.macro_position') == 'submit');
          _this.set('false_client_consent', _this.get('client_consent') && _this.get('values')[0] == 'yes');
          $("#position_select").val(_this.get('macro_position'));
          _this.set('selected_languages', _this.get('model.segment_picker_legends').mapBy('language.tag'));
          var languagesTexts = _this.languagesObserver();
          _this.get('model.segment_picker_legends').forEach(function (legend) {
            languagesTexts[legend.get('language.tag')] = legend.get('text');
          });
          _this.set('global_load', false);
        });
      }
    },


    alreadyExistingsPositions: Ember.computed('model.survey.segment_pickers.@each.position', function () {
      var current_id = this.get('model.id');
      return this.get('model.survey.segment_pickers').filter(function (sp) {
        return sp.id !== current_id;
      }).mapBy('position').join(',');
    }),

    segments: Ember.computed(function () {
      return this.get('store').findAll('segment');
    }),

    languages: Ember.computed(function () {
      var _this2 = this;

      var languages = this.get('store').findAll('language');
      languages.then(function () {
        if (!_this2.get('selected_languages.length')) {
          _this2.set('selected_languages', _this2.get('model.survey.languages').toArray().mapBy('tag'));
        }
      });
      return languages;
    }),

    languagesObserver: Ember.observer('selected_languages.[]', function () {
      var newLanguagesText = {};
      var currentLanguagesText = this.get('languagesTexts');
      this.get('selected_languages').forEach(function (language) {
        newLanguagesText[language] = currentLanguagesText ? currentLanguagesText[language] || "" : "";
      });
      return this.set('languagesTexts', newLanguagesText);
    }),

    selectedLanguagesTags: Ember.computed('languagesTexts', function () {
      var keys = Object.keys(this.get('languagesTexts') || {});
      this.setDisabledSave(keys.join(','));
      return keys;
    }),

    existingLanguagesTags: Ember.computed('languages.@each.tag', function () {
      return this.get('languages').mapBy('tag');
    }),

    setDisabledSave: function setDisabledSave(languages) {
      this.set('disabledSave', Ember.computed('languagesTexts.{' + languages + '}', 'model.segment.id', 'values.length', 'model.position', 'close_ended_question', 'client_consent', 'false_client_consent', 'optional_segment', function () {
        var texts = this.get('languagesTexts');
        var languages = this.get('selectedLanguagesTags').map(function (tag) {
          return texts[tag].length === 0;
        });
        var position = this.get('model.position');
        if (this.get('client_consent')) {
          if (languages.includes(true) || !this.get('model.segment.id')) {
            return 'disabled';
          }
        } else {
          if (languages.includes(true) || !this.get('model.segment.id') || !this.get('values.length') && this.get('close_ended_question') || position === null || position === undefined || position === '' || this.get('alreadyExistingsPositions').split(',').includes(position.toString())) {
            return 'disabled';
          }
        }
      }));
    },


    actions: {
      selectionChanged: function selectionChanged(values) {
        var _this3 = this;

        return this.get('segments').then(function (ss) {
          _this3.set('model.segment', ss.findBy('id', values[0]));
        });
      },
      addLanguageTab: function addLanguageTab(language) {
        this.get('selected_languages').addObject(language);
      },
      removeLanguageTab: function removeLanguageTab(language) {
        var cant_remove = this.get('model.survey.languages').toArray().mapBy('tag');
        if (!cant_remove.includes(language)) {
          this.get('selected_languages').removeObject(language);
        } else {
          return false;
        }
      },
      saveForm: function saveForm() {
        var _this4 = this;

        var store = this.get('store');
        var languages_texts = this.get('languagesTexts');
        var languages = this.get('languages');
        this.set('model.close_ended_question', this.get('close_ended_question'));
        this.set('model.optional_segment', this.get('optional_segment'));
        this.set('model.macro_position', this.get('client_consent') ? "submit" : this.get('macro_position'));
        this.set('model.values', this.get('client_consent') ? (0, _rotate.default)(['no', 'yes'], +this.get('false_client_consent')) : this.get('values'));
        this.get('model').save().then(function (sp) {
          Ember.RSVP.all([].concat(_toConsumableArray(_this4.get('model.segment_picker_options').map(function (spo) {
            return spo.destroyRecord();
          })), _toConsumableArray(_this4.get('model.segment_picker_legends').map(function (legend) {
            return legend.destroyRecord();
          })))).then(function () {
            Ember.RSVP.all([].concat(_toConsumableArray(_this4.get('selectedLanguagesTags').map(function (lang_tag) {
              return store.createRecord('segment-picker-legend', {
                segment_picker: sp,
                text: languages_texts[lang_tag],
                language: languages.findBy('tag', lang_tag)
              }).save();
            })))).then(function () {
              _this4.get('router').transitionTo('surveys.show', _this4.get('model.survey.id'));
            });
          });
        });
      },
      backToSurvey: function backToSurvey() {
        this.get('router').transitionTo('surveys.show', this.get('model.survey.id'));
      }
    }
  });
});