define('due-backoffice/services/mapping-converter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    store: service(),

    xlsx_json: function xlsx_json(file, filename) {
      var downcase = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      if (!file) {
        return RSVP.reject('No file');
      }
      var content = file.match('^data:(.*?);base64,(.*)$');
      var converter = this.get('store').createRecord('MappingConverter', {
        content: content[2],
        filename: filename,
        format: 'xlsx_json',
        downcase: downcase,
        type: 'base64/'
      });

      return converter.save();
    },
    json_xlsx: function json_xlsx(content, filename) {
      var sheetName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "DUE Mapping";

      var converter = this.get('store').createRecord('MappingConverter', {
        content: content,
        filename: filename,
        format: 'json_xlsx',
        sheet: sheetName,
        type: 'json'
      });

      return converter.save();
    }
  });
});