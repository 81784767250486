define('due-backoffice/components/companies/segments/segments-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({

    store: Ember.inject.service(),
    title: 'segments',
    childClassNames: "ui large list",
    segment: null,
    new_name_segment: '',
    segment_to_delete: '',
    displayed_segments: [],
    segment_csv_upload: null,
    segmentCsvError: null,
    segmentCsvSaved: null,
    type: 'Retour - ID',
    //for deleting a list of segments
    csv_upload: null,
    csvError: computed.alias('model.csvError'),
    csvSaved: computed.alias('model.csvSaved'),
    csvIsUploading: computed.alias('model.IsUploading'),
    typeForList: 'Segment - Name',
    firstConfirmation: false,
    segmentNames: [],
    strConfirm: '',
    strToMatch: '',
    CataclysmStr: ['Fin du monde', 'Chernobyl', 'Armageddon', 'Apocalypse', 'Je suis coupable', 'Bob leponge'],

    canSaveName: Ember.computed('segment', 'new_name_segment', function () {
      if (this.get('segment') !== null && !isEmpty(this.get('new_name_segment')) && this.get('segment.name') != this.get('new_name_segment')) {
        return;
      }
      return 'disabled';
    }),

    canDelete: Ember.computed('segment_to_delete', function () {
      if (!isEmpty(this.get('segment_to_delete'))) {
        return;
      }
      return 'disabled';
    }),

    isSaving: Ember.computed('model.isSaving', function () {
      if (this.get('segment.isSaving')) {
        return 'loading';
      }
    }),

    canModify: Ember.computed('segment_csv_upload.headers', function () {
      if (this.get('segment_csv_upload.headers')) {
        return;
      }
      return 'disabled';
    }),

    segmentsNames: Ember.computed('segment_csv_upload.headers', function () {
      var headers = this.get('segment_csv_upload.headers');
      var segment_names = "";
      if (headers) {
        headers.shift();
        return headers.join(', ');
      }
      return "";
    }),

    managedSegments: Ember.computed('model.managed_segments', {
      get: function get() {
        return this.get('model.managed_segments');
      },
      set: function set(_, value) {
        if (!value) {
          this.set('model.managed_segments', value);
          this.get('model').save();
        } else {
          this.showWarning(value);
        }
      }
    }),

    showWarning: function showWarning(value) {
      var _this = this;

      $('#show-warning').modal({
        onApprove: function onApprove() {
          _this.set('model.managed_segments', value);
          _this.get('model').save();
        },
        onDeny: function onDeny() {
          _this.set('model.managed_segments', false);
          _this.notifyPropertyChange('model.managed_segments');
        }
      }).modal('show');
    },
    init: function init() {
      this.get('store').unloadAll('ModifySegmentCsvUpload');
      var csv = this.get('store').createRecord('ModifySegmentCsvUpload');
      this.set('segment_csv_upload', csv);
      var csvList = this.get('store').createRecord('CsvUpload');
      this.set('csv_upload', csvList);
      this._super.apply(this, arguments);
    },
    sendContactListForList: function sendContactListForList() {
      var csv = this.get('csvToUpload');
      if (!csv) {
        return RSVP.reject('empty_file');
      }
      var match = csv.match('^data:(.*?);base64,(.*)$');
      this.set('content_type', 'base64');
      this.set('csv_upload.content', match[2]);
      return this.sendCSVForList();
    },
    sendCSVForList: function sendCSVForList() {
      var _this2 = this;

      this.set('firstConfirmation', false);
      this.set('csvError', null);
      this.set('csvSaved', null);
      this.set('csvIsUploading', true);
      this.set('csv_upload.type', this.get('content_type') + '/' + this.get('typeForList'));
      return this.get('csv_upload').save().then(function (response) {
        _this2.set('csv_upload', response);
        _this2.set('csvSaved', true);
        return response;
      }).catch(function (response) {
        _this2.set('csvError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>")); // TODO: i18n
        if (_this2.get('csvToUpload')) {
          var input = $('#upload-csv-list')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      }).finally(function () {
        _this2.set('csvIsUploading', false);
      });
    },


    canSaveRemove: Ember.computed('firstConfirmation', 'strConfirm', function () {
      if (this.get('csv_upload.body') && (!this.get('firstConfirmation') || this.get('firstConfirmation') && this.get('strToMatch') == this.get('strConfirm'))) {
        return;
      }
      return 'disabled';
    }),

    handleErrorForList: function handleErrorForList(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('csvError', errorText);
      this.set('csvIsUploading', false);
    },
    sendContactList: function sendContactList() {
      var csv = this.get('csvToUpload');
      if (!csv) {
        return RSVP.reject('empty_file');
      }
      var match = csv.match('^data:(.*?);base64,(.*)$');
      this.set('content_type', 'base64');
      this.set('segment_csv_upload.content', match[2]);
      return this.sendCSV();
    },
    sendCSV: function sendCSV() {
      var _this3 = this;

      this.set('firstConfirmation', false);
      this.set('segmentCsvError', null);
      this.set('segmentCsvSaved', null);
      this.set('csvIsUploading', true);
      this.set('segment_csv_upload.type', this.get('content_type') + '/' + this.get('type'));
      return this.get('segment_csv_upload').save().then(function (response) {
        _this3.set('segment_csv_upload', response);
        _this3.set('segmentCsvSaved', true);
        return response;
      }).catch(function (response) {
        _this3.set('segmentCsvError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>")); // TODO: i18n
        if (_this3.get('csvToUpload')) {
          var input = $('#upload-csv-segment')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      }).finally(function () {
        _this3.set('csvIsUploading', false);
      });
    },
    handleErrorSegment: function handleErrorSegment(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('segmentCsvError', errorText);
      this.set('csvIsUploading', false);
    },


    actions: {
      saveName: function saveName() {
        var _this4 = this;

        var segment_name_change = this.get('store').createRecord('segment-name-change', {
          company_id: this.get('model.id'),
          old_segment_name: this.get('segment.name'),
          new_segment_name: this.get('new_name_segment')
        });
        segment_name_change.save().then(function (_record) {
          _this4.set('new_name_segment', '');
          _this4.set('segment', null);
        });
      },
      deleteSegment: function deleteSegment() {
        var _this5 = this;

        var segment_delete = this.get('store').createRecord('segment-delete', {
          company_id: this.get('model.id'),
          segment_name: this.get('segment_to_delete')
        });
        segment_delete.save().then(function (_record) {
          _this5.set('segment_to_delete', '');
        });
      },
      handleLoadSegment: function handleLoadSegment(deferred) {
        var _this6 = this;

        this.sendContactList().then(function () {
          deferred.resolve();
        }).catch(function (error) {
          deferred.reject(error);
        });
        deferred.promise.catch(function (error) {
          _this6.handleErrorSegment(error);
        });
      },
      saveModify: function saveModify() {
        this.get('store').unloadAll('ModifySegment');
        var modifier = this.get('store').createRecord('ModifySegment');
        modifier.uploaded_csv_data_id = this.get('segment_csv_upload.uploaded_csv_data_id');
        modifier.company_id = this.get('model.id');
        modifier.save();
        this.get('segment_csv_upload').unloadRecord();
        var csv = this.get('store').createRecord('ModifySegmentCsvUpload');
        this.set('segment_csv_upload', csv);
      },
      handleLoadForList: function handleLoadForList(deferred) {
        var _this7 = this;

        this.sendContactListForList().then(function () {
          deferred.resolve();
        }).catch(function (error) {
          deferred.reject(error);
        });
        deferred.promise.catch(function (error) {
          _this7.handleErrorForList(error);
        });
      },
      saveRemove: function saveRemove() {
        if (!this.get('firstConfirmation')) {
          var columns = this.get('csv_upload.body');
          var header = this.get('csv_upload.headers')[0];
          var feedback_ids = [];
          if (columns) {
            columns.forEach(function (one) {
              feedback_ids.push(one[header]);
            });
            this.set('segmentNames', feedback_ids);
          }
          var possibility = this.get('CataclysmStr');
          var index = Math.floor(Math.random() * Math.floor(possibility.length));
          this.set('strConfirm', '');
          this.set('strToMatch', possibility[index]);
          this.set('firstConfirmation', true);
          return;
        }
        this.get('store').unloadAll('RemoveSegmentList');
        var remover = this.get('store').createRecord('RemoveSegmentList');
        remover.names = this.get('segmentNames');
        remover.company_id = this.get('model.id');
        remover.save();
        this.get('csv_upload').unloadRecord();
        var csv = this.get('store').createRecord('CsvUpload');
        this.set('csv_upload', csv);
      }
    }
  });
});