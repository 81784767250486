define('due-backoffice/routes/segment-pickers/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var survey = this.modelFor('surveys');
      return this.store.createRecord('segment-picker', {
        survey: survey
      });
    },
    deactivate: function deactivate() {
      if (!this.get('controller.model.id')) {
        this.get('controller.model').destroyRecord();
      }
    }
  });
});