define('due-backoffice/components/reporting-tagging/total', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      RSVP = Ember.RSVP,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagged_feedback_percentage: computed('stats.tagged_feedback_count', function () {
      var taggedFeedbackCount = this.get('stats.tagged_feedback_count');
      var feedbackTotal = this.get('stats.feedback_total');
      return taggedFeedbackCount && feedbackTotal ? (taggedFeedbackCount / feedbackTotal * 100).toFixed(2) : 0;
    }),

    tagged_feedback_from_tool_percentage: computed('stats.tagged_from_tool_feedback_count', function () {
      var taggedFromToolFeedbackCount = this.get('stats.tagged_from_tool_feedback_count');
      var feedbackTotal = this.get('stats.feedback_total');
      return taggedFromToolFeedbackCount && feedbackTotal ? (taggedFromToolFeedbackCount / feedbackTotal * 100).toFixed(2) : 0;
    }),

    not_tagged_feedback_percentage: computed('stats.not_tagged_feedback_count', function () {
      var notTaggedFeedbackCount = this.get('stats.not_tagged_feedback_count');
      var feedbackTotal = this.get('stats.feedback_total');
      return notTaggedFeedbackCount && feedbackTotal ? (notTaggedFeedbackCount / feedbackTotal * 100).toFixed(2) : 0;
    }),

    not_tagged_feedback_sla_percentage: computed('stats.not_tagged_feedback_sla_count', function () {
      var notTaggedFeedbackSlaCount = this.get('stats.not_tagged_feedback_sla_count');
      var feedbackTotal = this.get('stats.feedback_total');
      return notTaggedFeedbackSlaCount && feedbackTotal ? (notTaggedFeedbackSlaCount / feedbackTotal * 100).toFixed(2) : 0;
    }),

    wellAssignedPercentage: computed('stats.well_assigned', function () {
      var wellAssignedCount = this.get('stats.well_assigned');
      var feedbackTagCount = this.get('stats.tagged_from_tool_feedback_count');
      return wellAssignedCount && feedbackTagCount ? (wellAssignedCount / feedbackTagCount * 100).toFixed(2) : 0;
    }),

    wrongAssignedPercentage: computed('stats.wrong_assigned', function () {
      var wrongAssignedCount = this.get('stats.wrong_assigned');
      var feedbackTagCount = this.get('stats.tagged_from_tool_feedback_count');
      return wrongAssignedCount && feedbackTagCount ? (wrongAssignedCount / feedbackTagCount * 100).toFixed(2) : 0;
    }),

    missedAssignedPercentage: computed('stats.missed_assigned', function () {
      var missedAssignedCount = this.get('stats.missed_assigned');
      var feedbackTagCount = this.get('stats.tagged_from_tool_feedback_count');
      return missedAssignedCount && feedbackTagCount ? (missedAssignedCount / feedbackTagCount * 100).toFixed(2) : 0;
    }),

    _changeView: function _changeView(view) {
      this.get('changeViewAction')(view);
    },


    actions: {
      changeView: function changeView(view) {
        this._changeView(view);
      }
    }
  });
});