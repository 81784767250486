define('due-backoffice/components/companies/segments/custom-smileys-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),

    new_target: 'rating-scale.new',
    title: 'Échelle de réponse personnalisée',

    canSaveRatings: computed('model.hasDirtyAttributes', function () {
      var changedBelongsTo = this.get('model').changedBelongsTo();
      if (changedBelongsTo.default_closed_questions_rating_scale || changedBelongsTo.default_rating_scale) {
        return;
      }
      return 'disabled';
    }),

    needSaveRatings: computed('canSaveRatings', function () {
      return this.get('canSaveRatings') === 'disabled' ? '' : 'disabled';
    }),

    isSaving: computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    actions: {
      saveRatings: function saveRatings() {
        this.get('model').save();
      }
    }

  });
});