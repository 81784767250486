define('due-backoffice/components/companies/segments/options-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment', 'due-sms-counter'], function (exports, _companySegment, _dueSmsCounter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({

    store: Ember.inject.service(),
    title: 'options',
    childClassNames: "ui large list",
    resetCacheLoading: false,

    init: function init() {
      var _this = this;

      this.get('store').query('template', { filter: { company_id: this.get('model.id'), sending_type: 'sms' } }).then(function (templates) {
        return _this.set('templates', templates);
      });
      this._super.apply(this, arguments);
    },


    show_custom_stats: Ember.computed('model.show_custom_stats', {
      get: function get() {
        return this.get('model.show_custom_stats');
      },
      set: function set(_, value) {
        this.set('model.show_custom_stats', value);
        this.get('model').save();
      }
    }),

    show_answer_details: Ember.computed('model.show_answer_details', {
      get: function get() {
        return this.get('model.show_answer_details');
      },
      set: function set(_, value) {
        this.set('model.show_answer_details', value);
        this.get('model').save();
      }
    }),

    show_verbatim: Ember.computed('model.show_verbatim', {
      get: function get() {
        return this.get('model.show_verbatim');
      },
      set: function set(_, value) {
        this.set('model.show_verbatim', value);
        this.get('model').save();
      }
    }),

    show_analysis: Ember.computed('model.show_analysis', {
      get: function get() {
        return this.get('model.show_analysis');
      },
      set: function set(_, value) {
        this.set('model.show_analysis', value);
        this.get('model').save();
      }
    }),

    show_keywords_adjustment: Ember.computed('model.show_keywords_adjustment', {
      get: function get() {
        return this.get('model.show_keywords_adjustment');
      },
      set: function set(_, value) {
        this.set('model.show_keywords_adjustment', value);
        this.get('model').save();
      }
    }),

    show_public_reviews: Ember.computed('model.show_public_reviews', {
      get: function get() {
        return this.get('model.show_public_reviews');
      },
      set: function set(_, value) {
        this.set('model.show_public_reviews', value);
        this.get('model').save();
      }
    }),

    show_due_digests: Ember.computed('model.show_due_digests', {
      get: function get() {
        return this.get('model.show_due_digests');
      },
      set: function set(_, value) {
        this.set('model.show_due_digests', value);
        this.get('model').save();
      }
    }),

    anonymize_alerts: Ember.computed('model.anonymize_alerts', {
      get: function get() {
        return this.get('model.anonymize_alerts');
      },
      set: function set(_, value) {
        this.set('model.anonymize_alerts', value);
        this.get('model').save();
      }
    }),

    use_survey_v2: Ember.computed('model.enable_new_survey_dashboard', {
      get: function get() {
        return this.get('model.enable_new_survey_dashboard');
      },
      set: function set(_, value) {
        this.set('model.enable_new_survey_dashboard', value);
        this.get('model').save();
      }
    }),

    use_ch_report: Ember.computed('model.ch_report_enabled', {
      get: function get() {
        return this.get('model.ch_report_enabled');
      },
      set: function set(_, value) {
        this.set('model.ch_report_enabled', value);
        this.get('model').save();
      }
    }),

    hide_email_in_export: Ember.computed('model.hide_email_in_export', {
      get: function get() {
        return this.get('model.hide_email_in_export');
      },
      set: function set(_, value) {
        this.set('model.hide_email_in_export', value);
        this.get('model').save();
      }
    }),

    use_survey_v2_display: Ember.computed('model.enable_new_survey_respondant', {
      get: function get() {
        return this.get('model.enable_new_survey_respondant');
      },
      set: function set(_, value) {
        this.set('model.enable_new_survey_respondant', value);
        this.get('model').save();
      }
    }),

    use_short_url_sms: Ember.computed('model.short_url_sms', {
      get: function get() {
        return this.get('model.short_url_sms');
      },
      set: function set(_, value) {
        if (value === false || this._enableShortUrlSms()) {
          this.set('model.short_url_sms', value);
          this.get('model').save();
        }
      }
    }),

    max_sms_per_feedback: Ember.computed('model.max_sms_per_feedback', {
      get: function get() {
        return this.get('model.max_sms_per_feedback');
      },
      set: function set(_, value) {
        if (parseInt(value) >= 0) {
          this.set('model.max_sms_per_feedback', value);
          this.get('model').save();
        }
      }
    }),

    max_digests_limit: Ember.computed('model.max_digests_limit', {
      get: function get() {
        return this.get('model.max_digests_limit');
      },
      set: function set(_, value) {
        if (parseInt(value) >= 1) {
          this.set('model.max_digests_limit', value);
          this.get('model').save();
        }
      }
    }),

    not_powered_by_due: Ember.computed('model.not_powered_by_due', {
      get: function get() {
        return this.get('model.not_powered_by_due');
      },
      set: function set(_, value) {
        this.set('model.not_powered_by_due', value);
        this.get('model').save();
      }
    }),

    is_test_company: Ember.computed('model.is_test_company', {
      get: function get() {
        return this.get('model.is_test_company');
      },
      set: function set(_, value) {
        this.set('model.is_test_company', value);
        this.get('model').save();
      }
    }),

    is_blocked: Ember.computed('model.is_blocked', {
      get: function get() {
        return this.get('model.is_blocked');
      },
      set: function set(_, value) {
        if (!value) {
          this.set('model.is_blocked', value);
          this.get('model').save();
        } else {
          this.blockAccess(value);
        }
      }
    }),

    _enableShortUrlSms: function _enableShortUrlSms() {
      var _this2 = this;

      var too_long_languages = [],
          enable = true;
      this.get('templates').forEach(function (template) {
        Object.entries(template.get('text_contents')).forEach(function (tag_text) {
          var _SmsCounter$calcCharC = _dueSmsCounter.default.calcCharCount(tag_text[1]),
              char_count = _SmsCounter$calcCharC.char_count;

          if (char_count > 160 * _this2.get('max_sms_per_feedback') - 66) {
            too_long_languages.push({ survey_name: template.get('name'), tag: tag_text[0] });
            enable = false;
          }
        });
      });
      if (too_long_languages.length > 0) {
        this._showShortUrlSmsErrorAlert(too_long_languages);
      }
      return enable;
    },
    _showShortUrlSmsErrorAlert: function _showShortUrlSmsErrorAlert(errors_list) {
      var error_text = '⚠️ SMS template text too long for:\n';

      errors_list.forEach(function (error) {
        error_text += error.survey_name + ': ' + error.tag + '\n';
      });
      alert(error_text);
    },
    blockAccess: function blockAccess(value) {
      var _this3 = this;

      $('#block-access').modal({
        onApprove: function onApprove() {
          _this3.set('model.is_blocked', value);
          _this3.get('model').save();
        }
      }).modal('show');
    },


    actions: {
      resetCache: function resetCache() {
        var _this4 = this;

        this.set('modal_phrase', 'Voulez vous supprimer le cache de l\'entreprise "' + this.get('model.name') + '" ?');
        this.set('modal_icon', 'history');
        $('#sub_options.ui.modal').modal({
          onApprove: function onApprove() {
            _this4.set('resetCacheLoading', true);
            _this4.get('store').unloadAll('ResetCache');
            var reset_cache = _this4.get('store').createRecord('ResetCache');
            reset_cache.company_id = _this4.get('model.id');
            reset_cache.save().then(function () {
              return _this4.set('resetCacheLoading', false);
            });
          }
        }).modal('show');
      }
    }
  });
});