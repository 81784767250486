define('due-backoffice/models/account', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    // ReadOnly attrs
    firstname: (0, _attr.default)({ readOnly: true }),
    lastname: (0, _attr.default)({ readOnly: true }),
    email: (0, _attr.default)('string'),
    company_name: (0, _attr.default)({ readOnly: true }),
    profile: (0, _attr.default)('string'),
    zone: (0, _attr.default)({ readOnly: true }),

    // Editables attrs
    deleted: (0, _attr.default)('boolean'),
    anonymize_emails: (0, _attr.default)('boolean'),
    want_digest: (0, _attr.default)('boolean'),
    custom_stats_digest_subscriptions: (0, _attr.default)(),

    // RelationShips
    company: (0, _relationships.belongsTo)('company'),
    language: (0, _relationships.belongsTo)('Language'),

    // Computeds
    _company_id: Ember.computed('company', function () {
      return this.belongsTo('company').id();
    }),

    profile_icon: Ember.computed('profile', 'deleted', function () {
      if (this.get('deleted')) {
        return 'deleted_account';
      } else if (this.get('profile')) {
        return this.get('profile') + '_account';
      }
    })

  });
});