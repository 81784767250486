define('due-backoffice/services/due-api-query', ['exports', 'due-backoffice/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    currentUser: service('current-user'),

    post: function post(ressource, body, callback) {
      fetch(_environment.default.EmberENV.WWW_API_BO_URL + '/' + ressource, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + this.get('currentUser.content.back_office_api_key'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }).then(function (response) {
        return response.json().then(function (body) {
          return callback(body);
        });
      });
    }
  });
});