define('due-backoffice/models/self-survey', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    survey: (0, _relationships.belongsTo)('survey', { async: true }),
    name: _emberData.default.attr('string'),
    start_date: _emberData.default.attr('date'),
    end_date: _emberData.default.attr('date'),
    users: _emberData.default.attr() // array
  });
});