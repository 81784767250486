define('due-backoffice/routes/application', ['exports', 'moment', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _moment, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    translationsFetcher: Ember.inject.service(),
    session: Ember.inject.service('session'),

    redirect: function redirect(_, transition) {
      if (transition.targetName == "app.index") {
        this.transitionTo('companies');
      } else {
        this._super.apply(this, arguments);
      }
    },

    beforeModel: function beforeModel() {
      var _this = this,
          _arguments = arguments;

      this.get('currentUser').loadCurrentUser().then(function () {
        _moment.default.locale('fr');
        _this.get('translationsFetcher').fetch();
        _this._super.apply(_this, _arguments);
      });
    },


    sessionInvalidated: function sessionInvalidated() {
      this.store.unloadAll();
      window.location.replace('/login');
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var _this2 = this,
          _arguments2 = arguments;

      return this.get('currentUser').loadCurrentUser().then(function () {
        _this2._super.apply(_this2, _arguments2);
        _this2.transitionTo('companies.index');
      }).catch(function () {
        return _this2.get('session').invalidate();
      });
    },


    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      }
    }
  });
});