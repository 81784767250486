define('due-backoffice/controllers/self-surveys/new', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    emails: [],

    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },


    actions: {
      createSelfSurvey: function createSelfSurvey() {
        var _this = this;

        this.store.find('survey', this.get('surveyID')).then(function (survey) {
          var new_ss = _this.store.createRecord('self-survey', {
            name: _this.get('ssTitle'),
            survey: survey,
            start_date: (0, _moment.default)(_this.get('startDate')).startOf('day').toDate(),
            end_date: (0, _moment.default)(_this.get('endDate')).endOf('day').toDate(),
            users: _this.get('emails')
          });
          new_ss.save().then(function (ss) {
            _this.transitionToRoute('self-surveys');
          }, function (a) {
            _this.set('error', 'failure creating a new self-survey');
          });
        }, function (a) {
          _this.set('error', 'failure getting survey (wrong surveyID ?)');
        });
      },
      checkEmail: function checkEmail(email) {
        if (!this.validateEmail(email)) {
          return "invalid_format";
        }
        return null;
      }
    }
  });
});