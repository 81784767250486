define('due-backoffice/components/monitoring-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    classNames: ['monitoring'],

    title: 'monitoring',

    childClassNames: 'ui large list',

    monitoring_problem_lvl: Ember.computed('model.monitoring_problem_lvl', {
      get: function get() {
        return this.get('model.monitoring_problem_lvl');
      },
      set: function set(_, value) {
        if (parseInt(value) >= 0) {
          this.set('model.monitoring_problem_lvl', value);
          this.get('model').save();
          return value;
        }
      }
    }),

    monitoring_warning_lvl: Ember.computed('model.monitoring_warning_lvl', {
      get: function get() {
        return this.get('model.monitoring_warning_lvl');
      },
      set: function set(_, value) {
        if (parseInt(value) >= 0) {
          this.set('model.monitoring_warning_lvl', value);
          this.get('model').save();
          return value;
        }
      }
    }),

    monitoring_excess_lvl: Ember.computed('model.monitoring_excess_lvl', {
      get: function get() {
        return this.get('model.monitoring_excess_lvl');
      },
      set: function set(_, value) {
        if (parseInt(value) >= 0) {
          this.set('model.monitoring_excess_lvl', value);
          this.get('model').save();
          return value;
        }
      }
    }),

    monitoring_days_window: Ember.computed('model.monitoring_days_window', {
      get: function get() {
        return this.get('model.monitoring_days_window');
      },
      set: function set(_, value) {
        this.set('model.monitoring_days_window', value);
        this.get('model').save();
        return value;
      }
    }),

    actions: {
      setObject: function setObject(value) {
        this.set('model.monitoring_object', this.get('model.monitoring_object') == value ? null : value);
        this.get('model').save();
      }
    }
  });
});