define('due-backoffice/components/data-tool/feedback-segment-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      observer = Ember.observer,
      on = Ember.on;
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    store: Ember.inject.service(),
    editing: false,
    tempValue: '',

    actions: {
      startEdit: function startEdit() {
        this.set('tempValue', this.get('feedbackSegment.value'));
        this.set('editing', true);
      },
      cancelEdit: function cancelEdit() {
        this.set('tempValue', '');
        this.set('editing', false);
      },
      anonymize: function anonymize(feedbackSegment) {
        feedbackSegment.set('value', 'anonymized');
        feedbackSegment.save();
        var field = "segment: " + feedbackSegment.get('segment.name');
        this.get('store').createRecord('data-access-request', {
          feedback: this.get('feedback'),
          request_type: 'anonymize',
          field_modified: field
        }).save();
      },
      editSegment: function editSegment() {
        var feedbackSegment = this.get('feedbackSegment');
        feedbackSegment.set('value', this.get('tempValue'));
        feedbackSegment.save();
        var field = "segment: " + feedbackSegment.get('segment.name');
        this.get('store').createRecord('data-access-request', {
          feedback: this.get('feedback'),
          request_type: 'update',
          field_modified: field
        }).save();
        this.set('tempValue', '');
        this.set('editing', false);
      }
    }
  });
});