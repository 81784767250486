define('due-backoffice/controllers/accounts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    adminUsersVisible: false,
    usersVisible: true,

    newAccessCompany: false,
    newAccessDataTool: false,
    newAccessTaggingTool: false,
    newAccessAdmin: false,
    newAccessWorkers: false,

    // ADMINS
    current_page_admins: Ember.computed('model.admins.meta', {
      get: function get() {
        var meta = this.get('model.admins.meta');
        return meta ? meta.current_page : 0;
      },
      set: function set(_, value) {
        this.searchAdmins(value);
        return value;
      }
    }),

    total_pages_admins: Ember.computed('model.admins.meta', function () {
      var meta = this.get('model.admins.meta');
      return meta ? meta.page_count : 0;
    }),

    triggerAdminSearch: Ember.observer('searchAdmin', function () {
      this.get('searchAdmin');
      this.searchAdmins(1);
    }),

    searchAdmins: function searchAdmins() {
      var _this = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      this.set('searchPromise', new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.debounce(_this, _this._searchAdmins, _this.get('searchAdmin'), resolve, reject, page || _this.get('current_page'), 600);
      }).then(function (admins) {
        _this.set('isLoadingAdmin', false);
        _this.set('model.admins', admins);
      }));
    },
    _searchAdmins: function _searchAdmins(term, resolve, reject, page) {
      this.set('isLoadingAdmin', true);
      var a = this.store.query('adminUser', {
        filter: {
          name: term
        },
        page: {
          number: page,
          size: 20
        }
      }).then(function (admins) {
        return resolve(admins);
      }, reject);
    },


    // ACCOUNTS
    current_page: Ember.computed('model.accounts.meta', {
      get: function get() {
        var meta = this.get('model.accounts.meta');
        return meta ? meta.current_page : 0;
      },
      set: function set(_, value) {
        this.searchAccounts(value);
        return value;
      }
    }),

    total_pages: Ember.computed('model.accounts.meta', function () {
      var meta = this.get('model.accounts.meta');
      return meta ? meta.page_count : 0;
    }),

    triggerAccountSearch: Ember.observer('searchAccount', function () {
      this.get('searchAccount');
      this.searchAccounts(1);
    }),

    searchAccounts: function searchAccounts() {
      var _this2 = this;

      var page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      this.set('searchPromise', new Ember.RSVP.Promise(function (resolve, reject) {
        Ember.run.debounce(_this2, _this2._searchAccounts, _this2.get('searchAccount'), resolve, reject, page || _this2.get('current_page'), 600);
      }).then(function (accounts) {
        _this2.set('isLoadingAccounts', false);
        _this2.set('model.accounts', accounts);
      }));
    },
    _searchAccounts: function _searchAccounts(term, resolve, reject, page) {
      this.set('isLoadingAccounts', true);
      var a = this.store.query('account', {
        filter: {
          name: term
        },
        page: {
          number: page,
          size: 20
        }
      }).then(function (accounts) {
        return resolve(accounts);
      }, reject);
    },
    validateInfos: function validateInfos() {
      var _this3 = this;

      if (this.get('newPassword') === this.get('newPasswordConfirmation') && this.get('newPassword.length') > 0) {
        this.get('store').createRecord('adminUser', {
          email: this.get('newEmail'),
          first_name: this.get('newFirstname'),
          last_name: this.get('newLastname'),
          password: this.get('newPassword'),
          password_confirmation: this.get('newPasswordConfirmation'),
          can_access_company: this.get('newAccessCompany'),
          can_access_data_tool: this.get('newAccessDataTool'),
          can_access_tagging_tool: this.get('newAccessTaggingTool'),
          can_add_admin_users: this.get('newAccessAdmin')
        }).save().then(function () {
          return _this3.toggleModal('hide');
        });
        this.set('passwordError', false);
      } else {
        this.set('passwordError', true);
      }
    },
    toggleModal: function toggleModal(action) {
      $('#new-admin-user-modal.ui.modal').modal(action);
    },


    actions: {
      toggleUsers: function toggleUsers() {
        this.toggleProperty('usersVisible');
        this.toggleProperty('adminUsersVisible');
      },
      toggleAdminUsers: function toggleAdminUsers() {
        this.toggleProperty('adminUsersVisible');
        this.toggleProperty('usersVisible');
      },
      openNewAdminModal: function openNewAdminModal() {
        this.toggleModal('show');
      },
      closeModal: function closeModal() {
        this.toggleModal('hide');
      },
      createAdminUser: function createAdminUser() {
        this.validateInfos();
      }
    }
  });
});