define('due-backoffice/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend({

    password: '',
    identification: '',
    minError: false,
    lastError: null,

    session: Ember.inject.service(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        var promise = this.get('session').authenticate('authenticator:oauth2', identification, password);
        this.set('promise', promise);
        return promise.catch(function (e) {
          return _this.set('minError', false) && _this.set('lastError', e);
        });
      }
    }
  });
});