define('due-backoffice/models/company-subscription-plan-billing', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var underscore = Ember.String.underscore;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    // ReadOnly attrs
    created_at: attr('date', { readOnly: true }),
    paid_at: attr('date', { readOnly: true }),
    full_billing_number: attr('string', { readOnly: true }),
    amount: attr('number', { readOnly: true }),
    object: attr('string', { readOnly: true })

    // Editables attrs

    // RelationShips

    // Computeds

  });
});