define("due-backoffice/templates/components/companies/segments/-custom-email-segment", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 32,
            "column": 0
          }
        },
        "moduleName": "due-backoffice/templates/components/companies/segments/-custom-email-segment.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "item");
        var el2 = dom.createTextNode("Adresse Email personnalisée");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "item");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui basic segment");
        var el4 = dom.createTextNode("\n      Attention ! Bien vérifier que l'adresse email a été validée dans Sparkpost rubrique  'Settings -> Sending Domains' :\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("img");
        dom.setAttribute(el4, "class", "ui image img-sparkpost");
        dom.setAttribute(el4, "src", "/assets/images/screenshot-email-sparkpost.png");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui labeled input");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ui basic label");
        var el5 = dom.createTextNode("@");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "checkmark icon");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Sauvegarder\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui divider");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "item");
        var el2 = dom.createTextNode("DNS de bounce personnalisé");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "item");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui labeled input bounce-domain-input");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "checkmark icon");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Sauvegarder\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var element1 = dom.childAt(element0, [3]);
        var element2 = dom.childAt(element0, [5]);
        var element3 = dom.childAt(fragment, [8, 1]);
        var element4 = dom.childAt(element3, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 5, 5);
        morphs[2] = dom.createAttrMorph(element2, 'class');
        morphs[3] = dom.createElementMorph(element2);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[5] = dom.createAttrMorph(element4, 'class');
        morphs[6] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "string", "name", "custom_sending_local", "value", ["subexpr", "@mut", [["get", "custom_sending_local", ["loc", [null, [9, 62], [9, 82]]]]], [], []], "placeholder", "contact"], ["loc", [null, [9, 6], [9, 106]]]], ["inline", "input", [], ["type", "string", "name", "custom_sending_domain", "value", ["subexpr", "@mut", [["get", "custom_sending_domain", ["loc", [null, [11, 63], [11, 84]]]]], [], []], "placeholder", "diduenjoy.com"], ["loc", [null, [11, 6], [11, 114]]]], ["attribute", "class", ["concat", ["ui button ", ["get", "isSaving", ["loc", [null, [13, 54], [13, 62]]]], " ", ["get", "canSaveEmail", ["loc", [null, [13, 67], [13, 79]]]], " labeled icon green"]]], ["element", "action", ["saveEmail"], [], ["loc", [null, [13, 12], [13, 34]]]], ["inline", "input", [], ["type", "string", "name", "custom_bounce_domain", "value", ["subexpr", "@mut", [["get", "custom_bounce_domain", ["loc", [null, [24, 62], [24, 82]]]]], [], []], "placeholder", "bounces@bounces-due.domain.com"], ["loc", [null, [24, 6], [24, 129]]]], ["attribute", "class", ["concat", ["ui button ", ["get", "isSaving", ["loc", [null, [26, 61], [26, 69]]]], " ", ["get", "canSaveBounceDomain", ["loc", [null, [26, 74], [26, 93]]]], " labeled icon green"]]], ["element", "action", ["saveBounceDomain"], [], ["loc", [null, [26, 12], [26, 41]]]]],
      locals: [],
      templates: []
    };
  }());
});