define('due-backoffice/routes/data-tool/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    beforeModel: function beforeModel(transition) {
      if (this.get('currentUser.content.can_access_data_tool')) {
        return true;
      }
      this.transitionTo('tagging-tool');
    },

    model: function model() {}
  });
});