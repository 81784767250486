define('due-backoffice/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    session: service('session'),
    store: service(),

    loadCurrentUser: function loadCurrentUser() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        if (_this.get('session.isAuthenticated')) {
          return _this.get('store').find('AdminUser', 'me').then(function (user) {
            _this.set('content', user);
            resolve();
          }, reject);
        } else {
          resolve();
        }
      });
    }
  });
});