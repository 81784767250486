define('due-backoffice/components/companies/segments/integrations-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),

    integrations: Ember.computed.alias('model.company_integrations'),
    title: Ember.computed('integrations.length', function () {
      return 'Int\xE9grations (' + this.get('integrations.length') + ')';
    }),

    notActiveIntegrationsCount: Ember.computed('integrations.@each.completed', function () {
      if (this.get('integrations.firstObject.isLoading')) {
        return '?';
      }
      return this.get('integrations').filterBy('completed', true).filterBy('is_active', false).length;
    }),

    activeIntegrationsCount: Ember.computed('integrations.@each.is_active', function () {
      if (this.get('integrations.firstObject.isLoading')) {
        return '?';
      }
      return this.get('integrations').filterBy('completed', true).filterBy('is_active', true).length;
    }),

    notCompletedIntegrationsCount: Ember.computed('integrations.@each.is_active', function () {
      if (this.get('integrations.firstObject.isLoading')) {
        return '?';
      }
      return this.get('integrations').filterBy('completed', false).length;
    }),

    payingIntegrations: Ember.computed(function () {
      return this.get('store').query('integration', { filter: { free_access: false } });
    }),

    sortedIntegrations: Ember.computed('integrations.@each.completed', function () {
      return this.get('integrations').sortBy('is_active').sortBy('completed').reverse();
    }),

    payedIntegrations: Ember.computed('payed_integrations.@each.integration_id', 'payingIntegrations.@each.name', function () {
      if (!this.get('payed_integrations')) {
        var payingIntegrations = this.get('payingIntegrations').toArray() || [];
        if (payingIntegrations.length > 1) {
          this.set('payedIntegrationsClasses', Ember.A(Array(payingIntegrations.length).fill('loading')));
        }
        return;
      }
      var result = {};
      var classes = Ember.A();
      var payed_integrations = this.get('payed_integrations').mapBy('integration_id');
      this.get('payingIntegrations').forEach(function (it) {
        var it_id = it.get('id');
        result[it_id] = payed_integrations.includes(it_id);
        classes.push(result[it_id] ? 'blue' : 'not-payed');
      });
      this.set('payedIntegrationsClasses', classes);
      return result;
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('store').query('company-integration-accessor', { filter: { company_id: this.get('model.id') } }).then(function (records) {
        return _this.set('payed_integrations', records.toArray());
      });
    },


    actions: {
      togglePayingIntegration: function togglePayingIntegration(integration_id, index) {
        var payed_integration = this.get('payed_integrations').findBy('integration_id', arguments[0]);
        var payed_integrations = this.get('payed_integrations');
        if (payed_integration) {
          payed_integration.destroyRecord().then(function () {
            return payed_integrations.removeObject(payed_integration);
          });
          this.get('payedIntegrationsClasses').set('' + index, 'loading');
        } else {
          var record = this.get('store').createRecord('company-integration-accessor', {
            company_id: this.get('model.id'),
            integration_id: integration_id
          });
          record.save().then(function (_record) {
            payed_integrations.addObject(_record);
          });
          this.get('payedIntegrationsClasses').set('' + index, 'loading');
        }
      }
    }
  });
});