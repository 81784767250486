define('due-backoffice/components/data-tool/show-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'tr',
    store: Ember.inject.service(),
    classNames: ['show-line'],
    editing: false,
    tempValue: null,

    displayValue: computed('attribute', 'attribute.value', 'attribute.value.email', 'attribute.value.score', 'attribute.value.name', function () {
      if (this.get('attribute.type') == 'belongsTo') {
        var attributeValue = this.get('attribute.value');
        switch (this.get('attribute.name')) {
          case 'client':
            if (attributeValue.get('email')) {
              return attributeValue.get('email');
            } else {
              return "Anonyme";
            }
            break;
          case 'rating':
            return attributeValue ? attributeValue.get('score') : 'N/A';
          case 'survey':
            var version = this.get('feedback.is_v2') ? 'V2' : 'V1';
            return attributeValue.get('name') + (' (' + version + ')');
          default:
            return attributeValue.get('name');
        }
      }
      if (this.get('attribute.type') == 'date') {
        return this.get('attribute.value') ? '' + moment(this.get('attribute.value')).format('L LT') : '-';
      } else {
        return this.get('attribute.value');
      }
    }),

    imageSources: computed('attribute.type', 'attribute.value.[]', 'attribute.value.@each.picture', function () {
      if (this.get('attribute.type') == 'hasMany' && this.get('attribute.name') == 'ratings') {
        return this.get('attribute.value').map(function (rating) {
          return { picture: rating.get('picture'), score: rating.get('score') };
        });
      }
    }),

    actions: {
      startEdit: function startEdit() {
        if (this.get('attribute.name') == 'client') {
          this.set('tempValue', this.get('attribute.value.email'));
        } else if (this.get('attribute.name') == 'rating') {
          this.set('tempValue', this.get('attribute.value'));
        }
        this.set('editing', true);
      },
      cancelEdit: function cancelEdit() {
        this.set('tempValue', '');
        this.set('editing', false);
      },
      anonymize: function anonymize() {
        var _this = this;

        this.get('store').createRecord('client').save().then(function (client) {
          _this.set('feedback.client', client);
          _this.get('feedback').save();
        });
        this.get('store').createRecord('data-access-request', {
          feedback: this.get('feedback'),
          request_type: 'anonymize',
          field_modified: 'attribute: client_email'
        }).save();
      },
      editAttribute: function editAttribute() {
        var _this2 = this;

        var field = null;
        if (this.get('attribute.name') == 'client') {
          var email = this.get('tempValue');
          var existingClient = this.get('store').query("client", { filter: { email: email } });
          existingClient.then(function (clients) {
            var client = clients.get('firstObject');
            if (client) {
              _this2.set('feedback.client', client);
              _this2.get('feedback').save();
            } else {
              _this2.set('feedback.client.email', email);
              _this2.get('feedback.client').get('content').save();
            }
          });
          this.set('attribute.value.email', this.get('tempValue'));
          field = "attribute: client_email";
        } else if (this.get('attribute.name') == 'rating') {
          this.set('feedback.rating', this.get('tempValue'));
          this.set('attribute.value', this.get('tempValue'));
          this.get('feedback').save();
          field = "attribute: rating";
        }
        // common to all attributes
        this.get('store').createRecord('data-access-request', {
          feedback: this.get('feedback'),
          request_type: 'update',
          field_modified: field
        }).save();
        this.set('tempValue', '');
        this.set('editing', false);
      },
      setRatingToNull: function setRatingToNull() {
        this.set('tempValue', null);
      }
    }
  });
});