define('due-backoffice/controllers/accounts/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    frequencies: ['daily', 'weekly', 'monthly', 'quarterly', 'yearly'],

    anonymize_emails: Ember.computed('model.anonymize_emails', {
      get: function get() {
        return this.get('model.anonymize_emails');
      },
      set: function set(_, value) {
        this.set('model.anonymize_emails', value);
        this.get('model').save();
        return value;
      }
    }),

    want_digest: Ember.computed('model.want_digest', {
      get: function get() {
        return this.get('model.want_digest');
      },
      set: function set(_, value) {
        this.set('model.want_digest', value);
        this.get('model').save();
        return value;
      }
    }),

    custom_stats_digest_subscriptions: Ember.computed('model.custom_stats_digest_subscriptions', {
      get: function get() {
        return this.get('model.custom_stats_digest_subscriptions');
      },
      set: function set(_, value) {
        this.set('model.custom_stats_digest_subscriptions', value);
        this.get('model').save();
        return value;
      }
    }),

    actions: {
      toggleStatus: function toggleStatus() {
        this.get('model').toggleProperty('deleted');
        this.get('model').save();
      }
    }
  });
});