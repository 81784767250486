define('due-backoffice/services/companies-fetcher', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP,
      Service = Ember.Service;
  exports.default = Service.extend({
    store: service(),

    current_page: 1,
    filters: {
      search: '',
      warning_state: false,
      is_test_company: false
    },

    fetch: function fetch() {
      return this.get('store').query('company', {
        filter: this.get('filters'),
        page: {
          number: this.get('current_page'),
          size: 20
        }
      });
    },
    changePage: function changePage(page_number) {
      Ember.set(this, 'current_page', page_number);
      return this.fetch();
    },
    changeFilters: function changeFilters(filters) {
      Ember.set(this, 'current_page', 1);
      Ember.set(this, 'filters', filters);
      return this.fetch();
    }
  });
});