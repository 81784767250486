define('due-backoffice/models/reporting-tagging-export-request', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    lines: (0, _attr.default)(),
    export_url: (0, _attr.default)()
  });
});