define('due-backoffice/components/due-segments-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    multiple: false,
    selection: [],

    segments: Ember.computed('company.id', function () {
      return this.get('store').query('segment', { filter: { company_id: this.get('company.id') } });
    }),

    findOrCreateSegment: function findOrCreateSegment(segment) {
      var _this = this;

      var store = this.get('store');
      store.query('segment', { filter: { name: segment.get('name') } }).then(function (new_segment) {
        new_segment = new_segment.get('firstObject');
        if (!new_segment) {
          segment.save().then(function () {
            return _this.set('dropdown_is_loading', false);
          });
        } else {
          segment.set('id', new_segment.id);
          _this.set('dropdown_is_loading', false);
        }
      });
    },
    createAndSelectSegment: function createAndSelectSegment(segment) {
      var _this2 = this;

      this.findOrCreateSegment(segment, function (new_segment) {
        if (_this2.get('multiple')) _this2.get('selection').push(new_segment);else _this2.set('selection', new_segment);
        _this2.set('dropdown_is_loading', false);
      });
    },


    actions: {
      onSearch: function onSearch(search_text) {
        var old_text = this.get('old') || "";
        var segments = this.get('segments').toArray();
        if (old_text === search_text) {
          return true;
        }
        if (segments.findBy('name', search_text)) {
          return true;
        }
        segments.filterBy('id', null).map(function (s) {
          return s.deleteRecord();
        });
        segments = segments.filterBy('id');
        this.set('old', search_text);
        segments.push(this.get('store').createRecord('segment', { name: search_text }));
        this.set('segments', segments);
        return true;
      },
      onKeyDown: function onKeyDown(_, event) {
        if (event.key === "Enter") {
          var segment_to_save = this.get('segments').findBy('id', null);
          if (segment_to_save) {
            this.set('dropdown_is_loading', true);
            this.createAndSelectSegment(segment_to_save);
          }
        }
        return true;
      }
    }

  });
});