define('due-backoffice/components/collection-list-display-switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),

    classNames: 'ui icon buttons collection-list-display-switch',

    testt: 'email',

    actions: {
      setTableList: function setTableList(is_table) {
        this.get('currentUser').set('content.' + this.get('current_user_attribute'), is_table);
        this.get('currentUser.content').save();
      }
    }

  });
});