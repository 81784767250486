define('due-backoffice/components/companies/segments/notifications-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({

    title: 'Notifications',
    new_target: 'company-notifications.new',
    childClassNames: 'ui large list',
    company_notifications: Ember.computed.alias('model.company_notifications'),

    actions: {
      deleteNotification: function deleteNotification(notification) {
        notification.destroyRecord();
      }
    }
  });
});