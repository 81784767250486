define('due-backoffice/routes/subscription-plan/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      this.store.findAll('language');
      return this.store.createRecord('subscription-plan', {
        company: this.modelFor('companies'),
        custom: true,
        taxes_rate: 20.0
      });
    }
  });
});