define('due-backoffice/components/companies/segments/remove-feedback-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  var RSVP = Ember.RSVP;
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({

    store: Ember.inject.service(),
    title: 'Suppression de Feedbacks',
    childClassNames: "ui large list",
    csv_upload: null,
    csvError: computed.alias('model.csvError'),
    csvSaved: computed.alias('model.csvSaved'),
    csvIsUploading: computed.alias('model.IsUploading'),
    type: 'Retour - ID',
    firstConfirmation: false,
    feedbackIds: [],
    strConfirm: '',
    strToMatch: '',
    CataclysmStr: ['Fin du monde', 'Chernobyl', 'Armageddon', 'Apocalypse', 'Je suis coupable', 'Bob leponge'],

    init: function init() {
      this._super();
      var csv = this.get('store').createRecord('CsvUpload');
      this.set('csv_upload', csv);
    },
    sendContactList: function sendContactList() {
      var csv = this.get('csvToUpload');
      if (!csv) {
        return RSVP.reject('empty_file');
      }
      var match = csv.match('^data:(.*?);base64,(.*)$');
      this.set('content_type', 'base64');
      this.set('csv_upload.content', match[2]);
      return this.sendCSV();
    },
    sendCSV: function sendCSV() {
      var _this = this;

      this.set('firstConfirmation', false);
      this.set('csvError', null);
      this.set('csvSaved', null);
      this.set('csvIsUploading', true);
      this.set('csv_upload.type', this.get('content_type') + '/' + this.get('type'));
      return this.get('csv_upload').save().then(function (response) {
        _this.set('csv_upload', response);
        _this.set('csvSaved', true);
        return response;
      }).catch(function (response) {
        _this.set('csvError', response.errors.map(function (error) {
          return '' + (error.meta && error.meta.line ? "error line " + error.meta.line + " : " : "") + (error.detail || error.title);
        }).join("<br>")); // TODO: i18n
        if (_this.get('csvToUpload')) {
          var input = $('#upload-csv-input')[0];
          input.attributes.src.value = "";
          input.firstChild.firstElementChild.value = "";
        }
        throw response;
      }).finally(function () {
        _this.set('csvIsUploading', false);
      });
    },


    isSaving: computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    canSaveRemove: Ember.computed('firstConfirmation', 'strConfirm', function () {
      if (this.get('csv_upload.body') && (!this.get('firstConfirmation') || this.get('firstConfirmation') && this.get('strToMatch') == this.get('strConfirm'))) {
        return;
      }
      return 'disabled';
    }),

    handleError: function handleError(error) {
      var errorText;
      if (error.errors) {
        errorText = error.errors.map(function (error) {
          return error.title + ': ' + error.detail;
        }).join('<br>');
        if (errorText.match(/.*DOCTYPE html.*/)) {
          errorText = this.get('i18n').t('errors.unkown_csv_error');
        }
      } else {
        errorText = this.get('i18n').t('errors.' + error);
      }
      this.set('csvError', errorText);
      this.set('csvIsUploading', false);
    },


    actions: {
      handleLoad: function handleLoad(deferred) {
        var _this2 = this;

        this.sendContactList().then(function () {
          deferred.resolve();
        }).catch(function (error) {
          deferred.reject(error);
        });
        deferred.promise.catch(function (error) {
          _this2.handleError(error);
        });
      },
      saveRemove: function saveRemove() {
        if (!this.get('firstConfirmation')) {
          var columns = this.get('csv_upload.body');
          var header = this.get('csv_upload.headers')[0];
          var feedback_ids = [];
          if (columns) {
            columns.forEach(function (one) {
              feedback_ids.push(one[header]);
            });
            this.set('feedbackIds', feedback_ids);
          }
          var possibility = this.get('CataclysmStr');
          var index = Math.floor(Math.random() * Math.floor(possibility.length));
          this.set('strConfirm', '');
          this.set('strToMatch', possibility[index]);
          this.set('firstConfirmation', true);
          return;
        }
        this.get('store').unloadAll('RemoveFeedback');
        var remover = this.get('store').createRecord('RemoveFeedback');
        remover.ids = this.get('feedbackIds');
        remover.company_id = this.get('model.id');
        remover.save();
        this.get('csv_upload').unloadRecord();
        var csv = this.get('store').createRecord('CsvUpload');
        this.set('csv_upload', csv);
      }
    }
  });
});