define('due-backoffice/models/company-mapping-table', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    // attributes
    content: (0, _attr.default)(),
    filename: (0, _attr.default)('string'),
    company_id: (0, _attr.default)('string'),
    updated_at: (0, _attr.default)('date', { readOnly: true }),

    // relationships
    surveys: (0, _relationships.hasMany)('surveys'),
    company: (0, _relationships.belongsTo)('company'),
    admin_user: (0, _relationships.belongsTo)('admin-user')

  });
});