define('due-backoffice/components/companies/segments/sso-saml-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),

    title: "SAML configuration",

    saml_enabled: Ember.computed('model.saml_enabled', {
      get: function get() {
        return this.get('model.saml_enabled');
      },
      set: function set(_, value) {
        this.set('model.saml_enabled', value);
        this.get('model').save();
      }
    }),

    saml_sso_url: Ember.computed('model.saml_sso_url', {
      get: function get() {
        return this.get('model.saml_sso_url');
      },
      set: function set(_, value) {
        this.set('model.saml_sso_url', value);
        return value;
      }
    }),

    saml_metadata_url: Ember.computed('model.saml_metadata_url', {
      get: function get() {
        return this.get('model.saml_metadata_url');
      },
      set: function set(_, value) {
        this.set('model.saml_metadata_url', value);
        return value;
      }
    }),

    saml_certificate: Ember.computed('model.saml_certificate', {
      get: function get() {
        return this.get('model.saml_certificate');
      },
      set: function set(_, value) {
        this.set('model.saml_certificate', value);
        return value;
      }
    }),

    isSaving: Ember.computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    actions: {
      saveSAML: function saveSAML() {
        var _this = this;

        this.get('model').save().then(function (a) {
          return _this.notifyPropertyChange('model');
        });
      }
    }

  });
});