define('due-backoffice/models/admin-user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // ReadOnly attrs
    back_office_api_key: attr({ readOnly: true }),
    revoked: attr({ readOnly: true }),

    // Editables attrs
    first_name: attr('string'),
    last_name: attr('string'),
    email: attr('string'),
    password: attr('string'),
    password_confirmation: attr('string'),

    company_index_is_table: attr('boolean'),
    can_access_company: attr('boolean'),
    can_access_data_tool: attr('boolean'),
    can_access_tagging_tool: attr('boolean'),
    can_add_admin_users: attr('boolean'),

    // Computeds
    search_value: Ember.computed('first_name', 'last_name', 'email', function () {
      return this.get('first_name') + " " + this.get('last_name') + " " + this.get('email');
    })

  });
});