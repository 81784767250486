define('due-backoffice/controllers/self-surveys/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var mapBy = Ember.computed.mapBy,
      Controller = Ember.Controller,
      run = Ember.run;
  exports.default = Controller.extend({
    actions: {
      deleteSelfSurvey: function deleteSelfSurvey(ss) {
        ss.deleteRecord();
        ss.save();
      }
    }
  });
});