define("due-backoffice/components/timezone-picker", ["exports", "due-backoffice/utils/group-by"], function (exports, _groupBy) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service;
  exports.default = Component.extend({
    classNames: ["due-timezone-picker"],
    store: service(),

    timezones: computed(function () {
      return this.get("store").findAll("timezone");
    }),

    selectedTimezone: computed("timezones", "timezoneId", function () {
      var _this = this;

      if (!this.get("timezoneId")) {
        return null;
      }
      return this.get("timezones").then(function (timezones) {
        return timezones.findBy("id", _this.get("timezoneId"));
      });
    }),

    selectedTimezones: computed("timezones", "timezoneIds.[]", function () {
      var selectedTimezonesIds = this.get("timezoneIds");
      if (!selectedTimezonesIds) {
        return [];
      }

      return this.get("timezones").then(function (timezones) {
        return timezones.filter(function (timezone) {
          return selectedTimezonesIds.indexOf(timezone.id) > -1;
        });
      });
    }),

    groupedTimezones: computed("timezones", function () {
      return this.get("timezones").then(function (timezones) {
        return (0, _groupBy.default)(timezones, "current_utc_offset").map(function (group) {
          return {
            groupName: "GMT " + group.group,
            options: group.content.sort(function (a, b) {
              return a.name > b.name;
            })
          };
        });
      });
    }),

    actions: {
      updateTimezone: function updateTimezone(timezone) {
        this.set("timezoneId", timezone.id);
      },
      updateTimezones: function updateTimezones(timezones) {
        this.set("timezoneIds", timezones.mapBy("id"));
      }
    }
  });
});