define('due-backoffice/models/client', ['exports', 'ember-data', 'ember-data/relationships'], function (exports, _emberData, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    phone_number: _emberData.default.attr('string'),
    blacklisted: _emberData.default.attr('boolean'),
    company_subscriptions: (0, _relationships.hasMany)('company-subscription')
  });
});