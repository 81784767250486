define('due-backoffice/initializers/cookie', ['exports', 'due-backoffice/lib/cookie'], function (exports, _cookie) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'cookie',
    initialize: function initialize() {
      var app = arguments[1] || arguments[0];
      app.register('cookie:main', _cookie.default);
    }
  };
});