define('due-backoffice/initializers/setup-current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {

    initialize: function initialize(application) {
      application.inject('route', 'currentUser', 'service:current-user');
      application.inject('controller', 'currentUser', 'service:current-user');
      application.inject('component', 'currentUser', 'service:current-user');
      application.inject('view', 'currentUser', 'service:current-user');
    }

  };
});