define('due-backoffice/models/company-integration-accessor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    // ReadOnly attrs

    // Editables attrs
    integration_id: attr('string'),
    company_id: attr('string')
    // RelationShips

    // Computeds

  });
});