define('due-backoffice/utils/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (groupBy, key) {
    var dependentKey = 'content.@each.' + groupBy;

    if (!key) {
      return Ember.computed(dependentKey, function () {
        var result = [];

        this.get('content').forEach(function (item) {
          var hasGroup = !!result.findBy('group', get(item, groupBy));

          if (!hasGroup) {
            result.pushObject(Ember.Object.create({
              group: get(item, groupBy),
              content: []
            }));
          }

          result.findBy('group', get(item, groupBy)).get('content').pushObject(item);
        });

        return result;
      });
    } else {
      var result = [];
      groupBy.forEach(function (item) {
        var hasGroup = !!result.findBy('group', Ember.get(item, key));

        if (!hasGroup) {
          result.pushObject(Ember.Object.create({
            group: Ember.get(item, key),
            content: []
          }));
        }

        result.findBy('group', Ember.get(item, key)).get('content').pushObject(item);
      });

      return result;
    }
  };

  var get = Ember.get;
});