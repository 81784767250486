define("due-backoffice/controllers/application", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      observer = Ember.observer;
  exports.default = Controller.extend({

    breadCrumb: "companies",
    breadCrumbPath: "companies.index",
    classNames: ['application-wrapper'],

    currentPathChange: observer('currentPath', function () {
      Ember.$('body').attr('data-view-path', this.get('currentPath'));
    })

  });
});