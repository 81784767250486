define('due-backoffice/components/survey/custom-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  var mapBy = computed.mapBy;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    languageSort: ['tag'],
    languagesTags: mapBy('languages', 'tag'),
    customTextsTags: mapBy('model.custom_html', 'tag'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('store').findAll('language', { reload: true }).then(function (languages) {
        _this.set('languages', languages);
        if (!_this.get('model.custom_html')) {
          _this.set('model.custom_html', []);
          _this.addLanguage('EN');
        }
      });
    },
    addLanguage: function addLanguage(language) {
      this.get('model.custom_html').addObject({ tag: language, content: '' });
    },
    removeLanguage: function removeLanguage(language) {
      var to_be_removed = this.get('model.custom_html').find(function (obj) {
        return obj.tag == language;
      });
      this.get('model.custom_html').removeObject(to_be_removed);
    },


    actions: {
      addLanguage: function addLanguage(language) {
        this.set('displayLanguageList', false);
        this.addLanguage(language);
      },
      removeLanguage: function removeLanguage(language) {
        this.removeLanguage(language);
      }
    }
  });
});