define('due-backoffice/components/reporting-tagging/line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      RSVP = Ember.RSVP,
      computed = Ember.computed;
  exports.default = Component.extend({
    tagName: 'tr',

    comment: computed('line.feedback_comment', function () {
      if (this.get('line.feedback_comment') && this.get('line.feedback_comment').length > 30) {
        return this.get('line.feedback_comment').substring(0, 27) + '...';
      }
      return this.get('line.feedback_comment');
    }),

    en_translation: computed('line.en_translation', function () {
      if (this.get('line.en_translation') && this.get('line.en_translation').length > 30) {
        return this.get('line.en_translation').substring(0, 27) + '...';
      }
      return this.get('line.en_translation');
    }),

    fr_translation: computed('line.fr_translation', function () {
      if (this.get('line.fr_translation') && this.get('line.fr_translation').length > 30) {
        return this.get('line.fr_translation').substring(0, 27) + '...';
      }
      return this.get('line.fr_translation');
    }),

    tagging_tool_tags: computed('tags', 'line.tagging_tool_tags', function () {
      var _this = this;

      return this.get('tags').filter(function (tag) {
        return _this.get('line.tagging_tool_tags').includes(tag.get('id'));
      });
    }),

    partner_added_tags: computed('tags', 'line.partner_added_tags', function () {
      var _this2 = this;

      return this.get('tags').filter(function (tag) {
        return _this2.get('line.partner_added_tags').includes(tag.get('id'));
      });
    }),

    partner_removed_tags: computed('tags', 'line.partner_removed_tags', function () {
      var _this3 = this;

      return this.get('tags').filter(function (tag) {
        return _this3.get('line.partner_removed_tags').includes(tag.get('id'));
      });
    }),

    private_tags: computed('tags', 'line.private_tags', function () {
      var _this4 = this;

      return this.get('tags').filter(function (tag) {
        return _this4.get('line.private_tags').includes(tag.get('id'));
      });
    })
  });
});