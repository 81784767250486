define('due-backoffice/components/click-zone', ['exports', 'due-backoffice/mixins/click-zone'], function (exports, _clickZone) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_clickZone.default, {
    actions: {
      itemClicked: function itemClicked() {
        var act = this.get('click-inside');
        if (act) {
          act();
        }
      },
      outsideClicked: function outsideClicked() {
        var act = this.get('click-outside');
        if (act) {
          act();
        }
      }
    }
  });
});