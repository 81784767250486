define('due-backoffice/controllers/unauthorized/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      run = Ember.run,
      RSVP = Ember.RSVP;
  exports.default = Controller.extend({});
});