define('due-backoffice/components/companies/segments/custom-sms-tag-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({

    title: 'Personnalisation Envoi SMS (Chine)',
    childClassNames: "ui large list",
    custom_settings: Ember.computed.alias('model.custom_settings'),

    custom_sms_short_code: Ember.computed('custom_settings', {
      get: function get() {
        return this.get('custom_settings.short_code');
      },
      set: function set(_, value) {
        this.set('custom_settings.short_code', value);
        return value;
      }
    }),

    custom_sms_service_plan: Ember.computed('custom_settings', {
      get: function get() {
        return this.get('custom_settings.service_plan');
      },
      set: function set(_, value) {
        this.set('custom_settings.service_plan', value);
        return value;
      }
    }),

    custom_sms_token: Ember.computed('custom_settings', {
      get: function get() {
        return this.get('custom_settings.token');
      },
      set: function set(_, value) {
        this.set('custom_settings.token', value);
        return value;
      }
    }),

    canSaveSms: Ember.computed('model.hasDirtyAttributes', 'custom_sms_short_code', 'custom_sms_service_plan', 'custom_sms_token', function () {
      var changedBelongsTo = this.get('model').changedAttributes();
      if (changedBelongsTo.custom_settings && this.get('custom_sms_short_code') && this.get('custom_sms_service_plan') && this.get('custom_sms_token')) {
        return;
      }
      return 'disabled';
    }),

    isSaving: Ember.computed('model.isSaving', function () {
      if (this.get('model.isSaving')) {
        return 'loading';
      }
    }),

    actions: {
      saveCustomSms: function saveCustomSms() {
        var _this = this;

        this.get('model').save().then(function (a) {
          return _this.notifyPropertyChange('custom_settings');
        });
      }
    }

  });
});