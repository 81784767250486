define('due-backoffice/components/companies/show/show-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: 'ul',
    classNames: ['show-sidebar'],

    actions: {
      scrollTo: function scrollTo(id) {
        id = "#" + id;
        $('html, body').animate({
          scrollTop: $(id).offset().top - 60
        }, 200);
      }
    }

  });
});