define('due-backoffice/components/rating-scale-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    classNames: ['ui dropdown basic button'],

    _ratingScales: computed(function () {
      return this.get('store').findAll('rating-scale');
    }),

    ratingScales: computed('_ratingScales.length', function () {
      return this.get('_ratingScales').filterBy('isNew', false);
    }),

    basicsRatingScales: computed('ratingScales.@each.rating_scale_type', function () {
      var rs = this.get('ratingScales');
      if (rs.get('length')) {
        return rs.filter(function (a) {
          return a.get('rating_scale_type') !== "custom";
        });
      }
    }),

    customRatingScales: computed('ratingScales.@each.rating_scale_type', function () {
      var rs = this.get('ratingScales');
      if (rs.get('length')) {
        var result = {};
        rs.filter(function (a) {
          return a.get('rating_scale_type') === "custom";
        }).map(function (a) {
          var scale = a.get('scale');
          if (!result[scale]) {
            result[scale] = [];
          }
          result[scale].push(a);
        });
        return result;
      }
    }),

    dropdownSetter: Ember.observer('basicsRatingScales', 'customRatingScales', function () {
      var _this = this;

      if (this.get('basicsRatingScales') && this.get('customRatingScales')) {
        this.$().dropdown({
          onChange: function onChange(id) {
            _this.set('target', _this.get('ratingScales').findBy('id', id));
          },
          action: 'hide'
        });
      }
    })

  });
});