define('due-backoffice/components/custom-stat/item-accordion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    is_active: false,
    indexActive: { 0: 'active' },

    willDestroyElement: function willDestroyElement() {
      $('#delete-custom-stat-' + this.get('model.id')).remove();
    },


    is_active_class: Ember.computed('is_active', function () {
      return this.get('is_active') ? 'active' : '';
    }),

    segments: Ember.computed('model.segments', function () {
      var segments = (this.get('model.segments') || []).join(' > ');
      return segments == "" ? "`empty`" : segments;
    }),

    surveys: Ember.computed('model.surveys.@each.name', function () {
      var surveys = (this.get('model.surveys').mapBy('name') || []).join(', ');
      return surveys == "" ? "`empty`" : surveys;
    }),

    eachLanguages: Ember.on('init', Ember.observer('model.custom_stat_categories.@each.language', function () {
      var _this = this;

      Ember.RSVP.all(this.get('model.custom_stat_categories').mapBy('language')).then(function (languages) {
        if (_this.get('model.custom_stat_categories.firstObject.language.tag')) {
          _this.set('languages', _this.get('model.custom_stat_categories').mapBy('language.tag').uniq());
        }
      });
    })),

    languagesJoin: Ember.computed('languages', function () {
      return (this.get('languages') || []).join('|');
    }),

    actions: {
      toogleActive: function toogleActive() {
        this.toggleProperty('is_active');
      },
      editCustomStat: function editCustomStat(custom_stat_id) {
        this.get('router').transitionTo('custom-stats.edit', custom_stat_id);
      },
      deleteCustomStat: function deleteCustomStat() {
        var _this2 = this;

        $('#delete-custom-stat-' + this.get('model.id')).modal({
          onApprove: function onApprove() {
            return _this2.get('model').destroyRecord();
          }
        }).modal('show');
      }
    }
  });
});