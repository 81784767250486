define('due-backoffice/components/rating-scale', ['exports', 'due-backoffice/constants/rating_scales_scores'], function (exports, _rating_scales_scores) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    classNames: ['rating-scale'],

    rating_scale_add_one: Ember.computed('model.scale', 'model.is_nps', function () {
      return this.get('model.scale') + (this.get('model.is_nps') ? 0 : 1);
    }),

    rawSrcs: Ember.computed('model.scale', 'model.ratings.isPending', 'model.ratings.@each.picture_raw_data', 'model.ratings.@each.picture', 'model.isNew', 'model.is_nps', function () {
      var result = {};
      var ratings = this.get('model.ratings');
      var i = this.get('model.is_nps') ? 0 : 1;
      for (i; i <= this.get('model.scale'); i++) {
        if (!this.get('model.isNew') && ratings.get('isPending')) {
          result[i] = '/assets/images/spinner.gif';
        } else {
          var rating = ratings.findBy('position', i);
          result[i] = rating ? rating.get('picture') || rating.get('picture_raw_data') || '/assets/images/no-rating.png' : '/assets/images/no-rating.png';
        }
      }
      return result;
    }),

    actions: {
      onClickAction: function onClickAction(index) {
        var act = this.get('onClickAction');
        if (act) {
          act(index);
        }
      }
    },

    rating_scale_scores: Ember.computed('model.is_nps', function () {
      return this.get('model.is_nps') ? _rating_scales_scores.default.nps_scores : _rating_scales_scores.default.normal_scores;
    })

  });
});