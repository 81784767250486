define('due-backoffice/components/companies/segments/surveys-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),

    title: Ember.computed('surveys.length', function () {
      return 'Enqu\xEAtes (' + this.get('surveys.length') + ')';
    }),

    surveys: Ember.computed.alias('model.surveys')

  });
});