define('due-backoffice/controllers/self-surveys/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    emails: [],

    users: Ember.computed('model.users', 'emails', {
      get: function get() {
        if (!this.get('emails') || !this.get('emails').length) {
          this.set('emails', this.get('model.users'));
        }
        return this.get('emails');
      },
      set: function set(_, value) {
        this.set('emails', value);
        return value;
      }
    }),

    start_date: Ember.computed('model.start_date', {
      get: function get() {
        var tempDate = new Date(this.get('model.start_date')),
            day = ("0" + tempDate.getDate()).slice(-2),
            month = ("0" + (tempDate.getMonth() + 1)).slice(-2);
        return tempDate.getFullYear() + "-" + month + "-" + day;
      },
      set: function set(_, value) {
        this.set('model.start_date', moment(value).startOf('day').toDate());
        return value;
      }
    }),

    end_date: Ember.computed('model.end_date', {
      get: function get() {
        var tempDate = new Date(this.get('model.end_date')),
            day = ("0" + tempDate.getDate()).slice(-2),
            month = ("0" + (tempDate.getMonth() + 1)).slice(-2);
        return tempDate.getFullYear() + "-" + month + "-" + day;
      },
      set: function set(_, value) {
        this.set('model.end_date', moment(value).endOf('day').toDate());
        return value;
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('emails', this.get('model.users'));
    },
    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },


    actions: {
      saveSelfSurvey: function saveSelfSurvey() {
        var _this = this;

        this.set('model.users', this.get('emails'));
        this.get('model').save().then(function (res) {
          _this.set('emails', res.get('users'));
          _this.transitionToRoute('self-surveys');
        });
      },
      checkEmail: function checkEmail(email) {
        if (!this.validateEmail(email)) {
          return "invalid_format";
        }
        return null;
      }
    }
  });
});