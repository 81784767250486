define('due-backoffice/components/companies/segments/data-replicate-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),
    title: 'Data replicate',
    childClassNames: "ui large list",
    replicateStrConfirmation: '',
    replicateStrToMatch: '',
    canReplicateData: false,
    replicateCataclysmStr: ['Fin du monde', 'Chernobyl', 'Armageddon', 'Apocalypse', 'Je suis coupable', 'Bob leponge'],

    replicate_to_china: Ember.computed('model.replicate_to_china', {
      get: function get() {
        return this.get('model.replicate_to_china');
      },
      set: function set(_, value) {
        if (!value) {
          this.set('model.replicate_to_china', value);
          this.get('model').save();
          return value;
        } else {
          this.enableDataReplicate(value);
        }
      }
    }),

    enableDataReplicate: function enableDataReplicate(value) {
      var _this = this;

      var possibility = this.get('replicateCataclysmStr'),
          index = Math.floor(Math.random() * Math.floor(possibility.length));
      this.set('replicateStrConfirmation', '');
      this.set('replicateStrToMatch', possibility[index]);
      this.set('canReplicateData', false);
      $('#enable-data-replicate-' + this.get('model.id')).modal({
        onApprove: function onApprove() {
          _this.set('model.replicate_to_china', value);
          _this.get('model').save();
          return value;
        }
      }).modal('show');
    },


    actions: {
      confirmationCheck: function confirmationCheck() {
        this.set('canReplicateData', this.get('replicateStrToMatch') == this.get('replicateStrConfirmation'));
      }
    }
  });
});