define('due-backoffice/components/companies/segments/dashboard-languages-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _companySegment.default.extend({
    store: Ember.inject.service(),
    title: 'Dashboard languages',
    languageSelectionDisabled: false,

    childClassNames: "ui large list",
    verbatim_translation_threshold: computed.alias('model.verbatim_translation_threshold'),

    verbatim_translation: computed('model.verbatim_translation', {
      get: function get() {
        return this.get('model.verbatim_translation');
      },
      set: function set(_, value) {
        if (!value) {
          this.set('model.verbatim_translation', value);
          this.set('model.verbatim_auto_translation', value);
          this.get('model').save();
          return value;
        } else {
          this.enableVerbatimTranslation(value);
        }
      }
    }),

    verbatim_translated: computed('model.verbatim_translated', function () {
      return this.get('model.verbatim_translated');
    }),

    selectedLanguages: computed('model.dashboard_languages', function () {
      var _this = this;

      if (this.get('model.dashboard_languages').toArray().length > 0) {
        return this.get('store').query('language', { filter: { dashboard_lang_ids: this.get('model.dashboard_languages').map(function (dl) {
              return dl.get('id');
            }).filter(function (dl) {
              return dl != null;
            }) } }).then(function (languages) {
          _this.set('languageSelectionDisabled', false);
          return languages;
        });
      } else {
        this.set('languageSelectionDisabled', false);
        return [];
      }
    }),

    languages: computed('model.dashboard_languages', function () {
      var _this2 = this;

      return this.get('store').query('language', { filter: { is_available_company_side: true } }).then(function (languages) {
        _this2.set('languageSelectionDisabled', false);
        return languages;
      });
    }),

    addDashboardLanguageToCompany: function addDashboardLanguageToCompany(languages) {
      var _this3 = this;

      var existing_dshb_lang_ids = this.get('model.dashboard_languages').map(function (dl) {
        return dl.get('id');
      });
      if (this.get('model.dashboard_languages').toArray().length > 0) {
        this.get('store').query('language', { filter: { dashboard_lang_ids: existing_dshb_lang_ids } }).then(function (dshb_langs) {
          var diff = languages.filter(function (language) {
            return !dshb_langs.toArray().includes(language);
          });
          _this3.createDashboardLanguage(diff.shift());
        });
      } else {
        this.createDashboardLanguage(languages.shift());
      }
    },
    removeDashboardLanguage: function removeDashboardLanguage(languages) {
      var _this4 = this;

      this.get('store').query('language', { filter: { dashboard_lang_ids: this.get('model.dashboard_languages').map(function (dl) {
            return dl.get('id');
          }) } }).then(function (dshb_langs) {
        var diff = dshb_langs.filter(function (language) {
          return !languages.toArray().includes(language);
        });
        _this4.destroyDashboardLanguage(diff.shift());
      });
    },
    createDashboardLanguage: function createDashboardLanguage(language) {
      var _this5 = this;

      var dashboard_language = this.get('store').createRecord('dashboard-language', {
        language: language,
        company: this.get('model')
      });
      dashboard_language.save().then(function (dbl) {
        var current_dshb_langs = (_this5.get('model.dashboard_languages') || []).toArray();
        current_dshb_langs.push(dbl);
        _this5.set('model.dashboard_languages', current_dshb_langs);
        _this5.get('model').save();
      });
    },
    destroyDashboardLanguage: function destroyDashboardLanguage(language) {
      var _this6 = this;

      this.get('store').query('dashboard-language', { filter: { language_id: language.get('id'), company_id: this.get('model.id') } }).then(function (dshb_lang) {
        dshb_lang = dshb_lang.get('firstObject');
        var current_dshb_langs = _this6.get('model.dashboard_languages');
        dshb_lang.destroyRecord();
        _this6.set('model.dashboard_languages', current_dshb_langs.reject(function (a) {
          return a.get('id') === dshb_lang.get('id');
        }));
        _this6.get('model').save();
      });
    },
    enableVerbatimTranslation: function enableVerbatimTranslation(value) {
      var _this7 = this;

      $('#enable-verbatim-translation-' + this.get('model.id')).modal({
        onApprove: function onApprove() {
          _this7.set('model.verbatim_translation', value);
          _this7.get('model').save();
          return value;
        }
      }).modal('show');
    },
    addDashboardLanguageConfirmation: function addDashboardLanguageConfirmation(languages) {
      var _this8 = this;

      $('#add-language-company-' + this.get('model.id')).modal({
        onApprove: function onApprove() {
          return _this8.addDashboardLanguageToCompany(languages);
        },
        onDeny: function onDeny() {
          _this8.set('languageSelectionDisabled', false);
        }
      }).modal('show');
    },
    updateThreshold: function updateThreshold() {
      this.set('model.verbatim_translation_threshold', this.get('verbatim_translation_threshold'));
      this.get('model').save();
    },


    actions: {
      debouncedUpdateThreshold: function debouncedUpdateThreshold() {
        Ember.run.debounce(this, this.updateThreshold, 500);
      },
      changeLanguage: function changeLanguage(languages) {
        this.set('languageSelectionDisabled', true);
        if (languages.length > this.get('model.dashboard_languages').toArray().length) {
          if (this.get('model.verbatim_translation')) {
            this.addDashboardLanguageConfirmation(languages);
          } else {
            this.addDashboardLanguageToCompany(languages);
          }
        } else {
          if (this.get('model.dashboard_languages').toArray().length == 1) {
            alert("dashboard languages cannot be empty");
            this.set('languageSelectionDisabled', false);
          } else {
            this.removeDashboardLanguage(languages);
          }
        }
      }
    }
  });
});