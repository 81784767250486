define("due-backoffice/templates/components/tag-transfert", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "due-backoffice/templates/components/tag-transfert.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui labeled input");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "field ten wide due-segments required");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "segment-label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui labeled input");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "field ten wide due-segments required segment-margin-left");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "segment-label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("br");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "field ten wide due-segments required segment-margin-left button-padding-top");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "checkmark icon");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      Sauvegarder\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element2, 5, 5);
        morphs[4] = dom.createAttrMorph(element3, 'class');
        morphs[5] = dom.createElementMorph(element3);
        return morphs;
      },
      statements: [["content", "labelSource", ["loc", [null, [3, 33], [3, 48]]]], ["inline", "due-tags-dropdown", [], ["multiple", false, "company", ["subexpr", "@mut", [["get", "company", ["loc", [null, [4, 47], [4, 54]]]]], [], []], "selection", ["subexpr", "@mut", [["get", "tag", ["loc", [null, [4, 65], [4, 68]]]]], [], []], "kind", ["subexpr", "@mut", [["get", "kind", ["loc", [null, [4, 74], [4, 78]]]]], [], []]], ["loc", [null, [4, 4], [4, 80]]]], ["content", "labelCible", ["loc", [null, [9, 33], [9, 47]]]], ["inline", "due-tags-dropdown", [], ["multiple", false, "company", ["subexpr", "@mut", [["get", "company", ["loc", [null, [11, 47], [11, 54]]]]], [], []], "selection", ["subexpr", "@mut", [["get", "new_tag", ["loc", [null, [11, 65], [11, 72]]]]], [], []], "kind", ["subexpr", "@mut", [["get", "kind", ["loc", [null, [11, 78], [11, 82]]]]], [], []]], ["loc", [null, [11, 4], [11, 84]]]], ["attribute", "class", ["concat", ["ui button ", ["get", "isSaving", ["loc", [null, [14, 53], [14, 61]]]], " ", ["get", "canSaveName", ["loc", [null, [14, 66], [14, 77]]]], " labeled icon green segment-margin-left segment-margin-top"]]], ["element", "action", ["saveName"], [], ["loc", [null, [14, 12], [14, 33]]]]],
      locals: [],
      templates: []
    };
  }());
});