define('due-backoffice/routes/companies/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        company_creation: this.get('store').createRecord('company-creation', {}),
        languages: this.store.findAll('language'),
        timezones: this.store.findAll('timezone')
      });
    }
  });
});