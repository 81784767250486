define('due-backoffice/models/reporting-tagging', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    // ReadOnly attrs

    // Editables attrs
    feedback_total: (0, _attr.default)(),
    tagged_feedback_count: (0, _attr.default)(),
    not_tagged_feedback_count: (0, _attr.default)(),
    not_tagged_feedback_sla_count: (0, _attr.default)(),
    feedback_tag_count: (0, _attr.default)(),
    tagged_from_tool_feedback_count: (0, _attr.default)(),
    precision: (0, _attr.default)(),
    recall: (0, _attr.default)(),
    well_assigned: (0, _attr.default)(),
    wrong_assigned: (0, _attr.default)(),
    missed_assigned: (0, _attr.default)()

    // RelationShips

    // Computeds
  });
});