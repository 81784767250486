define('due-backoffice/components/navigable-select', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get,
      computed = Ember.computed,
      observer = Ember.observer,
      RSVP = Ember.RSVP;
  exports.default = Ember.Component.extend({
    searchEnabled: true,
    initiallyOpened: true,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      document.getElementsByClassName('ember-power-select-search-input').onblur = function (event) {
        var blurEl = this;
        setTimeout(function () {
          blurEl.focus();
        }, 10);
      };
    },


    sortedCurrentOptions: computed('options._id', 'currentOptions.[].title.string', function () {
      if (this.get('options') instanceof RSVP.Promise) {
        return this.get('options');
      }
      return this.get('currentOptions').sort(function (a, b) {
        if (a.parentLevel) {
          return -1;
        }
        if (b.parentLevel) {
          return 1;
        }
        if (a.must_be_first) {
          return -1;
        }
        if (b.must_be_first) {
          return 1;
        }
        if (a.must_be_last) {
          return 1;
        }
        if (b.must_be_last) {
          return -1;
        } else {
          return (a.levelName === undefined ? a.title : a.levelName.title).toString().localeCompare((b.levelName === undefined ? b.title : b.levelName.title).toString());
        }
      });
    }),

    relaodCurrentOptions: observer('reload', function () {
      this.propertyDidChange('transformedOptions');
      var a = this.get('transformedOptions');
      this.set('currentOptions', a);
    }),

    transformedOptions: computed('options.[]', 'options.@each.options', function () {
      var walker = function walker(options) {
        var parentLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

        var results = Ember.A();

        // this is necessary because power-select calls `toArray`, which
        // makes a copy and breaks our ability to compare parentLevel
        // via `===`.
        results.toArray = function () {
          return results;
        };

        var len = get(options, 'length');
        parentLevel = parentLevel || { root: true };
        for (var i = 0; i < len; i++) {
          var opt = get(options, '' + i);
          var groupName = get(opt, 'groupName');
          if (groupName) {
            var level = { levelName: groupName };
            var optionsWithBack = Ember.A([{ parentLevel: parentLevel }]).concat(get(opt, 'options'));
            level.options = walker(optionsWithBack, level);
            if (opt.must_be_first !== undefined) {
              level.must_be_first = opt.must_be_first;
            }
            if (opt.must_be_last !== undefined) {
              level.must_be_last = opt.must_be_last;
            }
            level.true_length = level.options.filterBy('levelName', undefined).length + level.options.filterBy('levelName').mapBy('true_length').reduce(function (pv, cv) {
              return pv === '0' ? -1 + cv : pv + cv;
            }, -1);
            results.push(level);
          } else {
            results.push(opt);
          }
        }
        parentLevel.options = results;
        return results;
      };
      return walker(this.get('options'));
    }),

    currentOptions: computed.oneWay('transformedOptions'),

    // Actions
    actions: {
      onchange: function onchange(levelOrOption, dropdown) {
        if (get(levelOrOption, 'levelName') && get(levelOrOption, 'true_length')) {
          set(this, 'currentOptions', get(levelOrOption, 'options'));
          set(dropdown, 'searchText', "");
        } else if (levelOrOption.parentLevel) {
          set(this, 'currentOptions', levelOrOption.parentLevel.options);
          set(dropdown, 'searchText', "");
        } else {
          this.get('onchange')(levelOrOption, this.get('currentOptions'), this, dropdown);
          if (this.get('_results_length') === 1) {
            set(dropdown, 'searchText', "");
          }
          dropdown.actions.search(dropdown.searchText);
          if (this.get('closeOnSelect') !== false) {
            dropdown.actions.close();
          }
        }
      },
      onclose: function onclose() {
        var act = this.get('onclose');
        var ret = void 0;
        if (act) {
          ret = act();
        }
        if (this.get('backToRootOnClose')) {
          var curr_options = this.get('currentOptions');
          var has_parent_level = curr_options.findBy('parentLevel');
          while (has_parent_level) {
            curr_options = has_parent_level.parentLevel.options;
            has_parent_level = curr_options.findBy('parentLevel');
          }
          this.set('currentOptions', curr_options);
        }
        return ret || true;
      },
      search: function search(searchValue) {
        var tags = this.get('allTags');
        if (tags != undefined) {
          var regExpSearchValue = new RegExp(searchValue.toString().toLowerCase().split('').join('.*').replace(/[+?^${}()<>|[\]\\]/g, '\\$&'));
          var results = tags.filter(function (tag) {
            return tag.get('name').toString().toLowerCase().match(regExpSearchValue) && tag.get('due_exclusive') === true;
          });
          results.fromSearch = true;
          this.set('_results_length', results.length);
          return results;
        } else {
          var normalizedTerm = searchValue.toString().toLowerCase();
          var searchField = this.get('searchField');
          var _results = this.get('currentOptions').filter(function (o) {
            if (o.parentLevel) {
              return normalizedTerm === '';
            } else if (get(o, 'levelName')) {
              return get(o, 'levelName' + (searchField ? "." + searchField : "")).toString().toLowerCase().indexOf(normalizedTerm) > -1;
            } else {
              return (searchField ? get(o, searchField) : o).toString().toLowerCase().indexOf(normalizedTerm) > -1;
            }
          });
          _results.fromSearch = true;
          this.set('_results_length', _results.length);
          return _results;
        }
      },
      inputChanged: function inputChanged(searchValue) {
        this.get('searchUpdated')(searchValue);
      }
    }
  });
});