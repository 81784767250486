define("due-backoffice/constants/rating_scales_scores", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    normal_scores: { 2: { 1: 1, 2: 10 }, 3: { 1: 1, 2: 5, 3: 10 }, 4: { 1: 1, 2: 4, 3: 7, 4: 10 }, 5: { 1: 1, 2: 3, 3: 5, 4: 7, 5: 10 }, 6: { 1: 1, 2: 3, 3: 5, 4: 7, 5: 8, 6: 10 }, 7: { 1: 1, 2: 3, 3: 4, 4: 6, 5: 7, 6: 9, 7: 10 }, 8: { 1: 1, 2: 2, 3: 4, 4: 5, 5: 6, 6: 8, 7: 9, 8: 10 }, 9: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 6, 6: 7, 7: 8, 8: 9, 9: 10 }, 10: { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 }, 11: { 1: 0, 2: 1, 3: 2, 4: 3, 5: 4, 6: 5, 7: 6, 8: 7, 9: 8, 10: 9, 11: 10 } },
    nps_scores: { 2: { 0: 0, 1: 10 }, 3: { 0: 0, 1: 5, 2: 10 }, 4: { 0: 0, 1: 3, 2: 7, 3: 10 }, 5: { 0: 0, 1: 3, 2: 5, 3: 7, 4: 10 }, 6: { 0: 0, 1: 2, 3: 4, 4: 6, 5: 8, 6: 10 }, 7: { 0: 0, 1: 2, 2: 3, 3: 5, 4: 7, 5: 8, 6: 10 }, 8: { 0: 0, 1: 1, 2: 3, 3: 4, 4: 6, 5: 7, 6: 9, 7: 10 }, 9: { 0: 1, 1: 2, 2: 3, 3: 4, 4: 5, 5: 6, 6: 8, 7: 9, 8: 10 }, 10: { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 6, 6: 7, 7: 8, 8: 9, 9: 10 }, 11: { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 } }
  };
});