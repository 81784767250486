define('due-backoffice/components/account/new-account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$computed = Ember.computed,
      alias = _Ember$computed.alias,
      mapBy = _Ember$computed.mapBy,
      empty = _Ember$computed.empty,
      computed = Ember.computed,
      Component = Ember.Component,
      all = Ember.RSVP.all,
      service = Ember.inject.service;
  exports.default = Component.extend({
    store: service(),
    existingEmail: mapBy('model.existings_accounts', 'email'),
    languages: alias('model.languages'),
    emptyEmails: empty('emails'),
    profiles: ['admin', 'manager'],
    selectedProfile: 'admin',
    emails: [],

    selectedLanguage: computed('languages', function () {
      return this.get('languages.firstObject');
    }),

    validateEmail: function validateEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    _save_accounts: function _save_accounts() {
      var _this = this;

      var account_custom_profile_right = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;

      this.set('saveAccountsPromise', all(this.get('emails').map(function (email) {
        return _this.get('store').createRecord('account', {
          email: email,
          company: _this.get('model.company'),
          profile: _this.get('selectedProfile'),
          account_custom_profile_right: account_custom_profile_right,
          language: _this.get('selectedLanguage')
        }).save();
      })).then(function () {
        _this._backToCompany();
      }));
    },
    _backToCompany: function _backToCompany() {
      this.get('router').transitionTo('companies.show', this.get('model.company.id'));
    },


    actions: {
      backToCompany: function backToCompany() {
        this._backToCompany();
      },
      sendInvitation: function sendInvitation() {
        this._save_accounts();
      },
      checkEmail: function checkEmail(email) {
        if (!this.validateEmail(email)) {
          return "invalid_format";
        }
        if (this.get('existingEmail').indexOf(email) !== -1) {
          return "already_taken";
        }
        return null;
      },
      updateAccountsDefaultLanguage: function updateAccountsDefaultLanguage(language) {
        this.set('selectedLanguage', language);
      },
      updateAccountsDefaultProfile: function updateAccountsDefaultProfile(profile) {
        this.set('selectedProfile', profile);
      }
    }
  });
});