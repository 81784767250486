define('due-backoffice/services/translations-fetcher', ['exports', 'moment', 'due-backoffice/translations/en'], function (exports, _moment, _en) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      inject = Ember.inject;
  exports.default = Service.extend({
    i18n: inject.service(),

    fetch: function fetch() {
      return this._addTranslations();
    },
    _addTranslations: function _addTranslations() {
      var locales = {
        //'fr': fr,
        'en': _en.default
        //'es': es,
        //'nl': nl,
        //'th': th,
        //'ko': ko,
        //'ja': ja,
        //'zh': zh,
        //'zht': zht
      };
      var i18n = this.get('i18n');
      Object.keys(locales).forEach(function (locale) {
        var translations = locales[locale];
        translations = translations[locale];
        var moment_translations = _moment.default.localeData(locale);
        Object.keys(moment_translations).forEach(function (tkey) {
          translations[tkey.replace(/^_/, '')] = moment_translations[tkey].toString();
        });
        i18n.addTranslations(locale, translations);
      });
    }
  });
});