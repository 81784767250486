define('due-backoffice/serializers/custom-stat', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONAPISerializer.extend({
    serialize: function serialize() {
      var _this = this;

      var json = this._super.apply(this, arguments);
      var questions = _.flatten(arguments[0].attributes().questions.map(function (q) {
        var result = [];
        q.availableLanguages.forEach(function (lang) {
          if (q[lang.tag]) {
            result.push({
              type: "custom-stat-categories",
              language_id: _this.store.peekAll('language').filterBy('tag', lang.tag.toUpperCase())[0].get('id'),
              question_category_id: q.id,
              position: q.data.position,
              title: q[lang.tag]
            });
          }
        });
        return result;
      }));
      if (questions.length > 0) {
        json.data.relationships["custom-stat-categories"] = { "data": questions };
      }
      delete json.data.attributes.questions;
      return json;
    }
  });
});