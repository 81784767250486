define('due-backoffice/models/feedback', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    comment: (0, _attr.default)('string'),
    created_at: (0, _attr.default)('date'),
    dispatch_from_name: (0, _attr.default)('string'),
    dispatch_from_email: (0, _attr.default)('string'),
    dispatch_subject: (0, _attr.default)('string'),
    dispatch_type: (0, _attr.default)('string'),
    dispatch_scheduled_at: (0, _attr.default)('date'),
    dispatch_sent_at: (0, _attr.default)('date'),
    sparkpost_status: (0, _attr.default)('string'),
    dispatch_opened: (0, _attr.default)('boolean'),
    dispatch_first_open: (0, _attr.default)('date'),
    dispatch_clicks: (0, _attr.default)('number'),
    dispatch_first_click: (0, _attr.default)('date'),
    answer_completed_at: (0, _attr.default)('date'),
    combined_status: (0, _attr.default)('string', { readOnly: true }),
    source: (0, _attr.default)('string'),
    is_answerable: (0, _attr.default)('boolean', { readOnly: true }),
    public_acknowledged: (0, _attr.default)('boolean'),
    is_public: (0, _attr.default)('boolean', { allowNull: true }),
    device_type: (0, _attr.default)('string'),
    resolved: (0, _attr.default)('boolean'),
    is_v2: (0, _attr.default)('boolean', { readOnly: true }),
    nullable_rating: (0, _attr.default)('boolean', { readOnly: true }),
    chat_updated_at: (0, _attr.default)('date'),
    status: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company'),
    survey: (0, _relationships.belongsTo)('survey'),
    client: (0, _relationships.belongsTo)('client'),
    rating: (0, _relationships.belongsTo)('rating'),

    ratings: (0, _relationships.hasMany)('ratings', { readOnly: true }),
    company_segment_values: (0, _relationships.hasMany)('company-segment-value'),

    sDesc: ['created_at:asc'],

    from_diduenjoy: computed.equal('source', 'diduenjoy'),
    from_external: computed.not('from_diduenjoy'),

    is_deleted: computed.equal('status', 'deleted'),
    survey_revision: computed.alias('survey_language.survey_revision'),
    source_zone: (0, _attr.default)('string', { readOnly: true })
  });
});