define('due-backoffice/controllers/data-tool/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      run = Ember.run;
  exports.default = Controller.extend({
    searchFieldValue: null,

    client: false,
    company_subscriptions: false,
    confirmAsk: false,

    _searchFeedbacks: function _searchFeedbacks(term) {
      var _this = this;

      if (term.length == 0) {
        this.set('model', []);
        return;
      } else if (this._validateSearchTerm(term)) {
        this.set('client', false);
        this.set('isLoading', true);
        this.store.query('feedback', { include: 'company,client,rating', filter: { email: term } }).then(function (feedbacks) {
          _this.set('isLoading', false);
          _this.set('model', feedbacks);
          if (feedbacks.get('length') > 0) {
            _this.get('model.firstObject.client').then(function (res) {
              _this.set('client', res);
              _this.get('client.company_subscriptions').then(function (cs) {
                _this.set('company_subscriptions', cs);
              });
            });
          }
        });
      }
    },
    _validateSearchTerm: function _validateSearchTerm(term) {
      var emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      var phoneReg = /^([0-9]{10,14})$/;
      return emailReg.test(term.toLowerCase()) || phoneReg.test(term);
    },


    actions: {
      blacklist: function blacklist() {
        var blacklisted = this.get('client.blacklisted');
        this.set('client.blacklisted', !blacklisted);
        this.get('client').save();
      },
      confirm_ask: function confirm_ask(sub) {
        if (this.get('confirmAsk') != sub.id) {
          this.set('confirmAsk', sub.id);
        } else {
          this.set('confirmAsk', false);
        }
      },
      delete_subscription: function delete_subscription(sub) {
        sub.deleteRecord();
        sub.save();
      },
      searchFeedbacks: function searchFeedbacks() {
        run.debounce(this, this._searchFeedbacks, this.get('searchFieldValue'), 500);
      }
    }
  });
});