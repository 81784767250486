define("due-backoffice/templates/companies/show", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "due-backoffice/templates/companies/show.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "component", [["subexpr", "concat", ["companies/segments/", ["get", "segment", ["loc", [null, [6, 48], [6, 55]]]], "-segment"], [], ["loc", [null, [6, 18], [6, 67]]]]], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [6, 74], [6, 79]]]]], [], []]], ["loc", [null, [6, 6], [6, 81]]]]],
        locals: ["segment"],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "due-backoffice/templates/companies/show.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui margined segment");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 3, 3);
        morphs[3] = dom.createMorphAt(element0, 5, 5);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "companies/show/show-sidebar", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 36], [1, 41]]]]], [], []], "showSegments", ["subexpr", "@mut", [["get", "showSegments", ["loc", [null, [1, 55], [1, 67]]]]], [], []]], ["loc", [null, [1, 0], [1, 69]]]], ["inline", "companies/show/show-header", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 39], [4, 44]]]]], [], []], "company_stat", ["subexpr", "@mut", [["get", "company_stat", ["loc", [null, [4, 58], [4, 70]]]]], [], []]], ["loc", [null, [4, 4], [4, 72]]]], ["block", "each", [["get", "showSegments", ["loc", [null, [5, 12], [5, 24]]]]], [], 0, null, ["loc", [null, [5, 4], [7, 13]]]], ["inline", "component", ["companies/segments/infos-segment"], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 57], [8, 62]]]]], [], []], "company_stat", ["subexpr", "@mut", [["get", "company_stat", ["loc", [null, [8, 76], [8, 88]]]]], [], []]], ["loc", [null, [8, 4], [8, 90]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});