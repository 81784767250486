define('due-backoffice/constants/icons', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    //segments
    infos: 'dashboard',
    diverse: 'options',
    integrations: 'share alternate',
    integration: 'share alternate',
    subscription: 'shopping basket',
    subscriptions: 'shopping basket',
    custom_stats: 'sitemap',
    custom_stat: 'sitemap',
    custom_email: 'at',
    accounts: 'user circle o',
    account: 'user circle o',
    surveys: 'smile',
    survey: 'smile',
    custom_smileys: 'smile',
    custom_smiley: 'smile',
    monitoring: 'chart line',
    keyword_adjustment: 'sort alphabet down',
    data_tool: 'shield alternate',
    tagging_tool: 'tag',
    reporting_tagging: 'question',
    remove_feedback: 'trash',
    modify_segment_values: 'tag',
    modify: 'tag',
    randomize_feedbacks: 'calendar alternate',
    dashboard_languages: 'globe',
    custom_sms_tag: 'comment outline',
    notifications: 'bell outline',
    mapping_table: 'table',
    data_replicate: 'copy outline',

    //company/monitoring
    problem: 'exclamation triangle',
    warning: 'bell',
    excess: 'money bill alternate',

    //labels
    segments: 'tag',
    segment: 'tag',
    question_category: 'ordered list',
    question_categories: 'ordered list',
    company: 'university',
    companies: 'university',
    feedbacks: 'question',
    feedback: 'question',
    completed_feedbacks: 'check circle',
    completed_feedback: 'check circle',
    client: 'address book outline',
    clients: 'address book outline',
    no_feedbacks_warn: 'warning sign',
    no_feedback_warn: 'warning sign',
    favourite_company: 'star',
    zone: 'globe',

    //accounts
    deleted_account: 'trash',
    manager_account: 'student',
    admin_account: 'spy',
    read_only_account: 'eye',
    custom_account: 'settings',

    //surveys
    archived_survey: 'archive',
    deleted_survey: 'trash',
    live_survey: 'checkmark',
    rating_scale: 'star half empty',
    undo: 'undo alternate',

    //diverse
    delete: 'trash',
    restore: 'history',
    active_integration: 'checkmark',
    inactive_integration: 'remove',
    tablet: 'tablet',
    languages: 'translate',
    segment_picker: 'icon toggle down',
    segment_value: 'thumb tack', // or ticket alternate
    plus: 'plus',

    // keyword_adjustment
    ban_keyword: 'ban',
    alias_keyword: 'arrow right',
    delete_alternative: 'trash alternative',

    // data tool
    info_circle: 'info circle',
    pencil: 'pencil',
    eye: 'eye',
    download: 'arrow down'

  };
});