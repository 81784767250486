define('due-backoffice/models/segment-picker', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // ReadOnly attrs

    // Editables attrs
    position: _emberData.default.attr('number'),
    close_ended_question: _emberData.default.attr('boolean'),
    optional_segment: _emberData.default.attr('boolean'),
    macro_position: _emberData.default.attr('string'),
    values: _emberData.default.attr(),

    // RelationShips
    segment: _emberData.default.belongsTo('segment'),
    survey: _emberData.default.belongsTo('survey'),
    segment_picker_legends: _emberData.default.hasMany('segment-picker-legend'),
    segment_picker_options: _emberData.default.hasMany('segment-picker-option')

    // Computeds

  });
});