define('due-backoffice/models/feedback-import', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // ReadOnly attrs
    file: _emberData.default.attr('string', { readOnly: true }),
    error_file: _emberData.default.attr('string', { readOnly: true }),

    // Editables attrs
    headers: _emberData.default.attr(),
    survey_id: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string'),
    uploaded_at: _emberData.default.attr('date'),
    start_import_at: _emberData.default.attr('date'),
    end_import_at: _emberData.default.attr('date'),
    import_stat: _emberData.default.attr(),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),

    // RelationShips
    admin_user: _emberData.default.belongsTo('admin_user'),

    file_raw_data: _emberData.default.attr(),
    file_file_name: _emberData.default.attr(),
    error_file_raw_data: _emberData.default.attr(),
    error_file_file_name: _emberData.default.attr()
  });
});