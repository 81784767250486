define("due-backoffice/components/notifications/new-form", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    languages: [{ tag: "fr", placeholder: "Français" }, { tag: "en", placeholder: "Anglais" }, { tag: "nl", placeholder: "Néerlandais" }, { tag: "ko", placeholder: "Coréen" }, { tag: "ja", placeholder: "Japonais" }, { tag: "th", placeholder: "Thaïlandais" }, { tag: "zh", placeholder: "Chinois simplifié" }, { tag: "zht", placeholder: "Chinois traditionnel" }],
    notificationTypes: [{
      name: "Warning", type: "warning", text: {}
    }, {
      name: "Billing warning", type: "warning", text: {
        fr: "Attention, veuillez procéder au règlement de votre dernière facture sinon votre compte sera bloqué le XX/XX/XX",
        en: "Attention, please proceed to the payment of your last invoice otherwise your account will be blocked on XX/XX/XX",
        nl: "Opgelet, ga verder met de betaling van uw laatste factuur, anders wordt uw account geblokkeerd op XX/XX/XX",
        ko: "주의, 마지막 인보이스 결제를 진행하세요. 그렇지 않으면 계정이 XX/XX/XX에 차단됩니다",
        ja: "注意、最後の請求書の支払いに進んでください。そうしないと、XX/XX/XXにアカウントがブロックされます",
        th: "เรียนโปรดดำเนินการชำระเงินตามใบแจ้งหนี้ครั้งสุดท้ายของคุณมิฉะนั้นบัญชีของคุณจะถูกบล็อกในวันที่ XX/XX/XX",
        zh: "请注意，请继续支付您的最后一张发票，否则您的帐户将在XX/XX/XX被封锁",
        zht: "請注意，請繼續支付您的最後一張發票，否則您的帳戶將在XX/XX/XX被封鎖"
      }
    }],
    selectedNotification: null,
    showToAdminsOnly: true,
    expirationDate: Ember.computed('ExpirationDate', {
      get: function get() {
        return this.get('ExpirationDate');
      },
      set: function set(_, value) {
        var temp = value ? new Date(value) : null;
        this.set('ExpirationDate', temp);
        return value;
      }
    }),
    text: {},
    name: null,

    backToCompany: function backToCompany() {
      this.get('router').transitionTo('companies.show', this.get('model.company.id'));
    },


    actions: {
      backToCompany: function backToCompany() {
        this.backToCompany();
      },
      setDefaultTexts: function setDefaultTexts(notification) {
        this.set('text', notification.text);
        this.set('selectedNotification', notification);
      },
      submitNotification: function submitNotification() {
        var _this = this;

        this.set('model.name', this.get('name'));
        this.set('model.notification_type', this.get('selectedNotification.type'));
        this.set('model.expires_at', this.get('ExpirationDate'));
        this.set('model.not_limited_to_admin', !this.get('showToAdminsOnly'));
        this.set('model.text', this.get('text'));
        this.get('model').save().then(function () {
          _this.backToCompany();
        });
      }
    }
  });
});