define('due-backoffice/components/reporting-tagging/export', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      run = Ember.run,
      RSVP = Ember.RSVP,
      computed = Ember.computed;
  exports.default = Component.extend({
    statOptions: ['from tool', 'missed assigned', 'wrong assigned'],

    actions: {
      selectStat: function selectStat(stat) {
        this.get('selectStat')(stat);
      }
    }
  });
});