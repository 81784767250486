define('due-backoffice/components/button-group', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    classNames: ['button-group'],
    classNameBindings: ['multiple', 'readOnly'],
    multiple: false,
    disabled: computed(function () {
      return [];
    }),
    readOnly: false,

    selectedArray: computed('selected', 'selected.[]', function () {
      return this.get('selected');
    }),

    disabledArray: computed('disabled', 'disabled.[]', function () {
      if (!this.get('disabled.length')) {
        return;
      }
      if (this.get('multiple')) {
        var disabled = this.get('disabled');
        if (typeof disabled === 'string') {
          this.get('selected').removeObject(disabled);
        } else {
          var _get;

          (_get = this.get('selected')).removeObjects.apply(_get, _toConsumableArray(disabled));
        }
      }
      if (!this.get('selected')[0]) {
        this.send('select', _.difference(this.get('options'), this.get('disabled'))[0]);
      }
      return this.get('disabled');
    }),

    actions: {
      select: function select(option) {
        if (this.get('disabled') === option || this.get('disabled').includes(option)) {
          return;
        }
        if (this.get('onSelection')) {
          this.get('onSelection')(option);
        } else {
          if (this.get('multiple')) {
            // create a copy and set selected to it
            // if not : computed with getter/setter not triggered
            var selection = this.get('selected').slice(0);
            if (this.get('selected').indexOf(option) === -1) {
              selection.addObject(option);
            } else {
              selection.removeObject(option);
            }
            this.set('selected', selection);
          } else {
            this.set('selected', option);
          }
        }
      }
    }
  });
});