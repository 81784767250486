define('due-backoffice/components/due-tags-dropdown', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    multiple: false,
    selection: [],

    tags: [],

    init: function init() {
      var _this = this;

      this.get('store').query('tag', { filter: { company_id: this.get('company.id'), kind: this.get('kind') } }).then(function (tags) {
        return _this.set('tags', tags);
      });
      this._super.apply(this, arguments);
    },
    _searchTag: function _searchTag(term, resolve, reject) {
      this.get('store').query('tag', {
        filter: {
          name: term,
          company_id: this.get('company.id'),
          kind: this.get('kind')
        },
        page: {
          number: 1,
          size: 20
        }
      }).then(function (tags) {
        return resolve(tags);
      }, reject);
    },


    actions: {
      searchTags: function searchTags(term) {
        var _this2 = this;

        return new Ember.RSVP.Promise(function (resolve, reject) {
          Ember.run.debounce(_this2, _this2._searchTag, term, resolve, reject, 600);
        });
      },
      onKeyDown: function onKeyDown(_, event) {
        if (event.key === "Enter") {
          var tag_to_save = this.get('tags').findBy('id', null);
          if (tag_to_save) {
            this.set('dropdown_is_loading', true);
            this.createAndSelectSegment(tag_to_save);
          }
        }
        return true;
      }
    }
  });
});