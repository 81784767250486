define("due-backoffice/templates/components/collection-list-display-switch", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 9
          }
        },
        "moduleName": "due-backoffice/templates/components/collection-list-display-switch.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "ui grid layout icon");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "ui list icon");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [1]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createAttrMorph(element1, 'class');
        morphs[3] = dom.createElementMorph(element1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["ui button ", ["subexpr", "unless", [["subexpr", "get", [["get", "currentUser.content", ["loc", [null, [2, 65], [2, 84]]]], ["get", "current_user_attribute", ["loc", [null, [2, 85], [2, 107]]]]], [], ["loc", [null, [2, 60], [2, 108]]]], "brown active"], [], ["loc", [null, [2, 51], [2, 125]]]]]]], ["element", "action", ["setTableList", false], [], ["loc", [null, [2, 2], [2, 33]]]], ["attribute", "class", ["concat", ["ui button ", ["subexpr", "if", [["subexpr", "get", [["get", "currentUser.content", ["loc", [null, [4, 61], [4, 80]]]], ["get", "current_user_attribute", ["loc", [null, [4, 81], [4, 103]]]]], [], ["loc", [null, [4, 56], [4, 104]]]], "brown active"], [], ["loc", [null, [4, 51], [4, 121]]]]]]], ["element", "action", ["setTableList", true], [], ["loc", [null, [4, 2], [4, 33]]]]],
      locals: [],
      templates: []
    };
  }());
});