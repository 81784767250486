define('due-backoffice/initializers/belongs-to-dirty', ['exports', 'ember-data/model'], function (exports, _model) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'modify-model',
    initialize: function initialize() {

      _model.default.reopen({
        notifyBelongsToChanged: function notifyBelongsToChanged(key) {
          if (!this.get('isNew') && !this.get('_internalModel._belongs_to_data.' + key)) {
            if (!this.get('_internalModel._belongs_to_data')) {
              this.set('_internalModel._belongs_to_data', {});
            }
            if (this.belongsTo(arguments[0]).belongsToRelationship.canonicalState) this.set('_internalModel._belongs_to_data.' + key, this.belongsTo(arguments[0]).belongsToRelationship.canonicalState.id);else return this._super.apply(this, arguments);
          }
          var result = this._super.apply(this, arguments);
          this.set('hasDirtyAttributes', Object.keys(this.changedBelongsTo()).length !== 0 || Object.keys(this.changedAttributes()).length !== 0);
          return result;
        },
        changedBelongsTo: function changedBelongsTo() {
          var _this = this;

          var result = {};
          if (this.get('isNew')) {
            return result;
          }
          var data = this.get('_internalModel._belongs_to_data') || {};
          Object.keys(data).forEach(function (key) {
            var id = _this.belongsTo(key).id();
            if (id && data[key] !== id) {
              result[key] = [data.key, id];
            }
          });
          return result;
        },
        save: function save() {
          var _this2 = this;

          var result = this._super.apply(this, arguments);
          result.then(function () {
            _this2.set('_internalModel._belongs_to_data', {});
            _this2.set('hasDirtyAttributes', false);
          });
          return result;
        }
      });
    }
  };
});