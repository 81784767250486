define('due-backoffice/components/admin-user/item-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    globalDisable: Ember.computed('admin.revoked', function () {
      return this.get('admin.revoked');
    }),

    can_access_company: Ember.computed('admin.can_access_company', {
      get: function get() {
        return this.get('admin.can_access_company');
      },
      set: function set(_, value) {
        this.set('admin.can_access_company', value);
        this.get('admin').save();
      }
    }),

    can_access_data_tool: Ember.computed('admin.can_access_data_tool', {
      get: function get() {
        return this.get('admin.can_access_data_tool');
      },
      set: function set(_, value) {
        this.set('admin.can_access_data_tool', value);
        this.get('admin').save();
      }
    }),

    can_access_tagging_tool: Ember.computed('admin.can_access_tagging_tool', {
      get: function get() {
        return this.get('admin.can_access_tagging_tool');
      },
      set: function set(_, value) {
        this.set('admin.can_access_tagging_tool', value);
        this.get('admin').save();
      }
    }),

    can_add_admin_users: Ember.computed('admin.can_add_admin_users', {
      get: function get() {
        return this.get('admin.can_add_admin_users');
      },
      set: function set(_, value) {
        this.set('admin.can_add_admin_users', value);
        this.get('admin').save();
      }
    }),

    actions: {
      toggleAdmin: function toggleAdmin() {
        // UPDATE API KEY TO DISABLE ADMIN ACCOUNT
        if (!this.get('admin.revoked')) {
          var tmpAdmin = this.get('admin');
          this.get('admin').destroyRecord();
          this.set('admin', tmpAdmin);
        } else {
          this.get('admin').save();
        }
      }
    }
  });
});