define("due-backoffice/templates/components/companies/item/other-part", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 2,
              "column": 140
            }
          },
          "moduleName": "due-backoffice/templates/components/companies/item/other-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui label blue");
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [0]);
          var element4 = dom.childAt(element3, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element4, 'class');
          morphs[1] = dom.createMorphAt(element3, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["subexpr", "admin-icon", ["custom_stat"], [], ["loc", [null, [2, 74], [2, 102]]]]], ["content", "company.customStatCount", ["loc", [null, [2, 107], [2, 134]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 173
            }
          },
          "moduleName": "due-backoffice/templates/components/companies/item/other-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui label teal");
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [0]);
          var element2 = dom.childAt(element1, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["subexpr", "admin-icon", ["integrations"], [], ["loc", [null, [3, 90], [3, 119]]]]], ["content", "company.live_company_integrations_count", ["loc", [null, [3, 124], [3, 167]]]]],
        locals: [],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.6",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 0
            },
            "end": {
              "line": 4,
              "column": 112
            }
          },
          "moduleName": "due-backoffice/templates/components/companies/item/other-part.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui label");
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("Chine");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0, 0]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["subexpr", "admin-icon", ["zone"], [], ["loc", [null, [4, 75], [4, 96]]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.6",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "due-backoffice/templates/components/companies/item/other-part.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createElement("i");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0]);
        var element6 = dom.childAt(element5, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createAttrMorph(element6, 'class');
        morphs[2] = dom.createMorphAt(element5, 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["ui label ", ["get", "company.subscriptionStatusColor", ["loc", [null, [1, 23], [1, 54]]]]]]], ["attribute", "class", ["subexpr", "admin-icon", ["subscriptions"], [], ["loc", [null, [1, 67], [1, 97]]]]], ["content", "company.subscription_detail", ["loc", [null, [1, 102], [1, 133]]]], ["block", "if", [["subexpr", "gt", [["get", "company.customStatCount", ["loc", [null, [2, 10], [2, 33]]]], 0], [], ["loc", [null, [2, 6], [2, 36]]]]], [], 0, null, ["loc", [null, [2, 0], [2, 147]]]], ["block", "if", [["subexpr", "gt", [["get", "company.live_company_integrations_count", ["loc", [null, [3, 10], [3, 49]]]], 0], [], ["loc", [null, [3, 6], [3, 52]]]]], [], 1, null, ["loc", [null, [3, 0], [3, 180]]]], ["block", "if", [["subexpr", "eq", [["get", "company.replicate_to_china", ["loc", [null, [4, 10], [4, 36]]]], true], [], ["loc", [null, [4, 6], [4, 42]]]]], [], 2, null, ["loc", [null, [4, 0], [4, 119]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});