define('due-backoffice/components/notifications/item-accordion', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    is_active: false,
    indexActive: { 0: 'active' },

    is_active_class: Ember.computed('is_active', function () {
      return this.get('is_active') ? 'active' : '';
    }),

    not_limited_to_admin: Ember.computed('model.not_limited_to_admin', function () {
      return this.get('model.not_limited_to_admin') ? 'Non' : 'Oui';
    }),

    actions: {
      toogleActive: function toogleActive() {
        this.toggleProperty('is_active');
      },
      deleteNotification: function deleteNotification() {
        this.get('delete')(this.get('model'));
      }
    }
  });
});