define('due-backoffice/models/subscription-plan', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'moment'], function (exports, _model, _attr, _relationships, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = _model.default.extend({
    // ReadOnly attrs

    // Editables attrs
    name: (0, _attr.default)(),
    stripe_id: (0, _attr.default)(),
    duty_free_amount: (0, _attr.default)('number'),
    taxes_rate: (0, _attr.default)('number'),
    amount: (0, _attr.default)('number'), //usefull only on create
    custom: (0, _attr.default)('boolean'),

    // RelationShips

    // Computeds
    decimal_amount: computed('duty_free_amount', function () {
      return (this.get('duty_free_amount') || 0) / 100.0;
    })

  });
});