define('due-backoffice/components/keyword-adjustments/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Component.extend({
    store: service(),
    keywordBan: false,
    keyword: null,
    aliasWord: null,
    keywordExists: false,

    everyInputFulfilled: computed('keyword', 'aliasWord', 'keywordBan', function () {
      if (!this.get('keywordBan') && (!this.get('keyword') || !this.get('aliasWord')) || this.get('keywordBan') && !this.get('keyword')) {
        return 'disabled';
      }
    }),

    actions: {
      saveAdjustment: function saveAdjustment() {
        var _this = this;

        this.set('keywordExists', false);
        var pairExists = false;
        var keyword = this.get('keyword');
        var to_keyword = this.get('aliasWord');
        this.get('store').findAll('keywordAdjustment').then(function (adjustment) {
          adjustment.forEach(function (a) {
            if (a.get('keyword') === keyword) {
              _this.set('keywordExists', true);
              return;
            }
          });

          if (!_this.get('keywordExists')) {
            _this.get('store').createRecord('keyword-adjustment', {
              keyword: keyword,
              to_keyword: to_keyword
            }).save().then(function () {
              return _this.get('router').transitionTo('keyword-adjustments');
            });
          }
        });
      },
      toggleBanState: function toggleBanState() {
        this.toggleProperty('keywordBan');
      },
      backToAdjustmentsList: function backToAdjustmentsList() {
        this.get('router').transitionTo('keyword-adjustments');
      }
    }
  });
});