define('due-backoffice/components/daterange-picker', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      observer = Ember.observer;
  exports.default = Component.extend({
    classNames: ['due-daterange-picker'],
    classNameBindings: ['startDateState'],

    // minDate: null,
    // maxDate: null,

    // internals
    startDateState: true,
    internalStartDate: computed.oneWay('startDate'),
    internalEndDate: computed.oneWay('endDate'),

    pikadayMinDate: computed('minDate', 'startDate', function () {
      return (0, _moment.default)(this.get('minDate')).max(this.get('startDate')).toDate();
    }),

    pikadayMaxDate: computed('maxDate', 'endDate', function () {
      return (0, _moment.default)(this.get('maxDate')).min(this.get('endDate')).toDate();
    }),

    pikaday: null,

    datesChanged: observer('startDate', 'endDate', function () {
      this.set('internalStartDate', this.get('startDate'));
      this.set('internalEndDate', this.get('endDate'));
      this.redrawPikaday();
    }),

    cleanStartDate: computed('internalStartDate', function () {
      return (0, _moment.default)(this.get('internalStartDate')).startOf('day').toDate();
    }),
    cleanEndDate: computed('internalEndDate', function () {
      return (0, _moment.default)(this.get('internalEndDate')).startOf('day').toDate();
    }),

    redrawPikaday: function redrawPikaday() {
      if (this.get('pikaday')) {
        if (this.get('internalStartDate')) {
          this.get('pikaday').setStartRange(this.get('cleanStartDate'));
          if (this.get('internalEndDate')) {
            this.get('pikaday').setEndRange(this.get('cleanEndDate'));
            this.get('pikaday').gotoDate(this.get('cleanEndDate'));
          } else {
            this.get('pikaday').setMinDate(this.get('cleanStartDate'));
            this.get('pikaday').gotoDate(this.get('cleanStartDate'));
          }
        }
      }
    },


    actions: {
      pickStartDate: function pickStartDate(pickedDate) {
        this.set('internalStartDate', pickedDate);
        this.set('internalEndDate', null);
        this.toggleProperty('startDateState');
      },
      pickEndDate: function pickEndDate(pickedDate) {
        this.set('internalEndDate', pickedDate);
        this.toggleProperty('startDateState');
        if (this.get('rangePicked')) {
          this.get('rangePicked')(this.get('internalStartDate'), (0, _moment.default)(this.get('internalEndDate')).endOf('day').toDate());
        }
      },
      registerPikaday: function registerPikaday(pikaday) {
        this.set('pikaday', pikaday);
        this.redrawPikaday();
      }
    }
  });
});