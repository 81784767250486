define('due-backoffice/serializers/fragment', ['exports', 'ember-data/serializers/json'], function (exports, _json) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _json.default.extend({
    serialize: function serialize() /*snapshot, options*/{
      // to do
      return this._super.apply(this, arguments);
    },
    normalize: function normalize(typeClass, hash) {
      _.forEach(hash, function (value, key) {
        hash[key.underscore()] = value;
        if (key.underscore() !== key) {
          delete hash[key];
        }
      });

      return this._super.apply(this, arguments);
    }
  });
});