define('due-backoffice/components/companies/segments/monitoring-segment', ['exports', 'due-backoffice/components/companies/segments/company-segment'], function (exports, _companySegment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _companySegment.default.extend({

    title: 'monitoring',

    childClassNames: "ui large list"

  });
});