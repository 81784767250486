define('due-backoffice/controllers/data-tool/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      computed = Ember.computed;
  exports.default = Controller.extend({

    displayAttributes: {
      company: { dispalyName: 'Societé', canEdit: false, canAnonymise: false },
      survey: { displayName: 'Enquête', canEdit: false, canAnonymise: false },
      client: { displayName: 'Email', canEdit: true, canAnonymise: true },
      rating: { displayName: 'Note', canEdit: true, canAnonymise: false },
      ratings: { displayName: 'Echelle de réponse', canEdit: false, canAnonymise: false },
      comment: { displayName: 'Commentaire', canEdit: false, canAnonymise: false },
      created_at: { displayName: 'Date de création', canEdit: false, canAnonymise: false },
      dispatch_from_name: { displayName: 'Expéditeur', canEdit: false, canAnonymise: false },
      dispatch_from_email: { displayName: 'Email de diffusion', canEdit: false, canAnonymise: false },
      dispatch_subject: { displayName: "Object d'envoi", canEdit: false, canAnonymise: false },
      dispatch_type: { displayName: 'Type de diffusion', canEdit: false, canAnonymise: false },
      dispatch_scheduled_at: { displayName: "Date d'envoi programé", canEdit: false, canAnonymise: false },
      dispatch_sent_at: { displayName: "Date d'envoi", canEdit: false, canAnonymise: false },
      sparkpost_status: { displayName: 'Statut sparkpost', canEdit: false, canAnonymise: false },
      dispatch_opened: { displayName: 'Envoi ouvert', canEdit: false, canAnonymise: false },
      dispatch_first_open: { displayName: 'Date de la première ouverture', canEdit: false, canAnonymise: false },
      dispatch_clicks: { displayName: 'Nombre de clics', canEdit: false, canAnonymise: false },
      dispatch_first_click: { displayName: "Date du premier clic", canEdit: false, canAnonymise: false },
      answer_completed_at: { displayName: 'Date de retour', canEdit: false, canAnonymise: false },
      chat_updated_at: { displayName: 'Date de dernière modification', canEdit: false, canAnonymise: false },
      status: { displayName: 'Statut du retour', canEdit: false, canAnonymise: false },
      combined_status: { displayName: 'Statut', canEdit: false, canAnonymise: false },
      source: { displayName: 'Source', canEdit: false, canAnonymise: false },
      device_type: { displayName: 'Media', canEdit: false, canAnonymise: false },
      is_answerable: { displayName: 'Peut le contacter', canEdit: false, canAnonymise: false },
      public_acknowledged: { displayName: 'Accepeté les termes public', canEdit: false, canAnonymise: false },
      is_public: { displayName: 'Retour public', canEdit: false, canAnonymise: false },
      resolved: { displayName: 'Resolu', canEdit: false, canAnonymise: false },
      company_segment_values: { displayName: 'Segments', canEdit: false, canAnonymise: false }
    },

    feedbackAttributes: computed('model', 'displayAttributes', 'model.client', function () {
      var _this = this;

      var feedback = this.get('model'),
          visibleAttr = this.get('displayAttributes'),
          attributes = [];
      this.get('store').query('company-segment-value', { filter: { feedback_id: feedback.id } }).then(function (csv) {
        return _this.set('feedbackSegmentsRequest', csv);
      });
      feedback.eachRelationship(function (name, meta) {
        if (meta.kind == 'belongsTo') {
          if (name) {
            var attrObj = visibleAttr[name];
            attributes.push({ name: name, displayName: attrObj.displayName, canEdit: attrObj.canEdit, canAnonymise: attrObj.canAnonymise, type: meta.kind, value: feedback.get(name) });
          }
        } else if (meta.kind == 'hasMany') {
          if (name == 'ratings') {
            var _attrObj = visibleAttr[name];
            attributes.push({ name: name, displayName: _attrObj.displayName, canEdit: _attrObj.canEdit, canAnonymise: _attrObj.canAnonymise, type: meta.kind, value: feedback.get(name) });
          }
        }
      });
      feedback.eachAttribute(function (name, meta) {
        if (name in visibleAttr) {
          var attrObj = visibleAttr[name];
          attributes.push({ name: name, displayName: attrObj.displayName, canEdit: attrObj.canEdit, canAnonymise: attrObj.canAnonymise, type: meta.type, value: feedback.get(name) });
        }
      });
      return attributes;
    }),

    feedbackSegmentsNotHidden: computed.filterBy('feedbackSegmentsRequest', 'isHidden', false),
    feedbackSegmentSorting: ['position'],
    feedbackSegments: computed.sort('feedbackSegmentsNotHidden', 'feedbackSegmentSorting'),

    actions: {
      copySegments: function copySegments() {
        var segment_list = '';
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = this.get('feedbackSegments')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var feedbackSegment = _step.value;

            segment_list += feedbackSegment.get('segment.name') + '\n';
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        navigator.clipboard.writeText(segment_list);
      }
    }
  });
});